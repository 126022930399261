import React from 'react';
import styled from 'styled-components';
import { Text } from '@/components/Typography';

export const TextWrapper = styled(Text)`
	font-size: 22px;
`;

interface SubtitleProps {
	children: string;
}

export function Subtitle({ children }: SubtitleProps) {
	return <TextWrapper>{children}</TextWrapper>;
}
