import React from 'react';
import { CUSTOM_REPORTS } from './Icons';
import * as S from '../styles';

interface CustomReportProps {
	name: string;
	id: string;
}

export function CustomReport({ id, name }: Readonly<CustomReportProps>) {
	const report = CUSTOM_REPORTS.find((item) => item.name === name);

	if (!report) {
		return null;
	}

	return (
		<S.StyledRadioButton value={id}>
			<S.ReportContent>
				{report.icon && <S.IconWrapper>{report.icon}</S.IconWrapper>}
				<S.NameWrapper>{report.formatted_name}</S.NameWrapper>
			</S.ReportContent>
		</S.StyledRadioButton>
	);
}
