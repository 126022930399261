import * as React from 'react';

export const FilterIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 35 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect y={25.5977} width={34.6} height={3.19996} rx={1} />
			<rect y={3.19922} width={34.6} height={3.19996} rx={1} />
			<rect y={14.3984} width={34.6} height={3.19996} rx={1} />
			<path d="M21.56 22.4a1 1 0 011 1v8a1 1 0 01-1 1h-4a1 1 0 01-1-1v-8a1 1 0 011-1h4z" />
			<path
				d="M18.55 24.5a.1.1 0 01.1-.1h1.8a.1.1 0 01.1.1v5.8a.1.1 0 01-.1.1h-1.8a.1.1 0 01-.1-.1v-5.8z"
				fill="currentColor"
			/>
			<path d="M11 11a1 1 0 011 1v8a1 1 0 01-1 1H7a1 1 0 01-1-1v-8a1 1 0 011-1h4z" />
			<path
				d="M7.99 13.1a.1.1 0 01.1-.1h1.8a.1.1 0 01.1.1v5.8a.1.1 0 01-.1.1h-1.8a.1.1 0 01-.1-.1v-5.8z"
				fill="currentColor"
			/>
			<path d="M27.6 0a1 1 0 011 1v8a1 1 0 01-1 1h-4a1 1 0 01-1-1V1a1 1 0 011-1h4z" />
			<path
				d="M24.59 2.1a.1.1 0 01.1-.1h1.8a.1.1 0 01.1.1v5.8a.1.1 0 01-.1.1h-1.8a.1.1 0 01-.1-.1V2.1z"
				fill="currentColor"
			/>
		</svg>
	);
};
