import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Radio, Row } from 'antd';
import { StyledRadioGroup } from '../styles';

interface GranularityProps {
	formName: string[];
}

export function Granularity({ formName }: Readonly<GranularityProps>) {
	return (
		<Row>
			<Col span={24}>
				<Form.Item
					labelCol={{ span: 24 }}
					name={[...formName, 'granularity']}
					label={I18n.get('Granularity of information')}
				>
					<StyledRadioGroup>
						<Radio.Button value="day">Day</Radio.Button>
						<Radio.Button value="week">Week</Radio.Button>
						<Radio.Button value="month">Month</Radio.Button>
						<Radio.Button value="quarter">3 Months</Radio.Button>
						<Radio.Button value="semester">6 Months</Radio.Button>
						<Radio.Button value="year">Year</Radio.Button>
					</StyledRadioGroup>
				</Form.Item>
			</Col>
		</Row>
	);
}
