import React from 'react';
import { Col, Form, Row } from 'antd';

import { Footer } from './Footer';
import { EndDate } from './Fields/EndDate';
import { StartDate } from './Fields/StartDate';
import { TimeFrame } from './Fields/TimeFrame';
import { Title } from '@/components/Typography';
import { FiscalYear } from './Fields/FiscalYear';
import { PredeterminedTime } from './Fields/PredeterminedTime';

const { useWatch } = Form;

interface CompoundProps {
	fieldName: string[];
	handleFormSubmit(): void;
	handleClear(fieldNames: string[]): void;
}

export function Compound({ fieldName, handleFormSubmit, handleClear }: Readonly<CompoundProps>) {
	const formFirstPeriod = [...fieldName, 'first_period'];
	const formSecondPeriod = [...fieldName, 'second_period'];

	const firstPeriod = useWatch([...formFirstPeriod, 'predetermined_time_value']);
	const secondPeriod = useWatch([...formSecondPeriod, 'predetermined_time_value']);

	return (
		<Row gutter={[0, 20]}>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Title level={3}>Period 1</Title>
					</Col>
					<Col span={24}>
						<Row gutter={[0, 20]}>
							<Col span={12}>
								<StartDate disabled={firstPeriod} formName={formFirstPeriod} />
							</Col>
							<Col span={12}>
								<EndDate disabled={firstPeriod} formName={formFirstPeriod} />
							</Col>
							<Col span={24}>
								<PredeterminedTime formName={formFirstPeriod} />
							</Col>
							{firstPeriod && (
								<Col span={24}>
									<Row gutter={[20, 0]}>
										<Col span={12}>
											<TimeFrame formName={formFirstPeriod} />
										</Col>
										<Col>
											<FiscalYear formName={formFirstPeriod} />
										</Col>
									</Row>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 5]}>
					<Col span={24}>
						<Title level={3}>Period 2</Title>
					</Col>
					<Col span={24}>
						<Row gutter={[0, 20]}>
							<Col span={12}>
								<StartDate disabled={secondPeriod} formName={formSecondPeriod} />
							</Col>
							<Col span={12}>
								<EndDate disabled={secondPeriod} formName={formSecondPeriod} />
							</Col>
							<Col span={24}>
								<PredeterminedTime formName={formSecondPeriod} />
							</Col>
							{secondPeriod && (
								<Col span={24}>
									<Row gutter={[20, 0]}>
										<Col span={12}>
											<TimeFrame formName={formSecondPeriod} />
										</Col>
										<Col>
											<FiscalYear formName={formSecondPeriod} />
										</Col>
									</Row>
								</Col>
							)}
							<Col span={24}>
								<Footer onSubmit={handleFormSubmit} onClear={() => handleClear(fieldName)} />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
