import moment, { Moment } from 'moment';
import LZString from 'lz-string';

interface Mapping {
	[key: string]: string;
}

interface DesminimizedObject {
	[key: string]: any;
}

interface MinimizedObject {
	[key: string]: any;
}

const mapping: Mapping = {
	unique_period: 'up',
	start_date: 'sd',
	end_date: 'ed',
	predetermined_time_value: 'ptv',
	granularity: 'g',
	time_frame: 'tf',
	fiscal_year: 'fy',
	compound_period: 'cp',
	first_period: 'fp',
	second_period: 'sp'
};

const reverseMapping: Mapping = {
	up: 'unique_period',
	sd: 'start_date',
	ed: 'end_date',
	ptv: 'predetermined_time_value',
	g: 'granularity',
	tf: 'time_frame',
	fy: 'fiscal_year',
	cp: 'compound_period',
	fp: 'first_period',
	sp: 'second_period'
};

export function minimizeObject(obj: DesminimizedObject): MinimizedObject | string {
	return minimizeUrlObject(obj);
}

function minimizeUrlObject(value: any): MinimizedObject | string {
	if (typeof value === 'object' && value !== null && !moment.isMoment(value)) {
		return Object.keys(value).reduce((acc: MinimizedObject, key: string) => {
			const minimizedKey = mapping[key] || key;
			acc[minimizedKey] = minimizeUrlObject(value[key]);
			return acc;
		}, {} as MinimizedObject);
	} else if (moment.isMoment(value)) {
		return value.format('YYYY-MM-DD');
	}
	return value;
}

export function desminimizeObject(minimizedObj: MinimizedObject): DesminimizedObject | Moment | string {
	return desminimizeUrlObject(minimizedObj);
}

function desminimizeUrlObject(value: any): DesminimizedObject | Moment | string {
	if (typeof value === 'object' && value !== null) {
		return Object.keys(value).reduce((acc: DesminimizedObject, key: string) => {
			const desminimizedKey = reverseMapping[key] || key;
			acc[desminimizedKey] = desminimizeUrlObject(value[key]);
			return acc;
		}, {} as DesminimizedObject);
	} else if (typeof value === 'string' && moment(value, 'YYYY-MM-DD', true).isValid()) {
		return moment(value, 'YYYY-MM-DD');
	}
	return value;
}

export function unShortenUrl(compressed: string | null): DesminimizedObject | string {
	if (!compressed) {
		return '';
	}

	const decompressed = LZString.decompressFromEncodedURIComponent(compressed);
	if (!decompressed) {
		return '';
	}

	const jsonParsedObject = JSON.parse(decompressed);
	const desminifiedObject = desminimizeObject(jsonParsedObject);
	return desminifiedObject;
}

export function shortenUrl(params: DesminimizedObject): string {
	const minimizedFilterParams = minimizeObject(params);
	const stringifiedParams = JSON.stringify(minimizedFilterParams);
	const compressedURL = LZString.compressToEncodedURIComponent(stringifiedParams);
	return compressedURL;
}

export function formatLocationSearch(location: string, query: URLSearchParams): any {
	return unShortenUrl(
		location
			.substring(1)
			.replace(`&tab=${query.get('tab')}`, '')
			.replace(`tab=${query.get('tab')}`, '')
	);
}
