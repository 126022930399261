import styled, { keyframes } from 'styled-components';
import { Row, Divider, Popover } from 'antd';

const BORDER_COLOR = '#26262666';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

export const Container = styled(Row)`
	width: 100%;
	height: 100%;
	padding: 0.8rem;
	min-height: 12rem;
	border-radius: 18px;
	background-color: #ffffff;
	box-shadow: 2px 4px 10px 0px #00000026;
`;

export const GradientSpinner = styled.div`
	width: 5rem;
	height: 5rem;
	display: flex;
	position: relative;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	animation: ${rotate} 2s linear infinite;
	background: conic-gradient(
		from 180deg at 50% 50%,
		rgba(255, 255, 255, 0.5) 0deg,
		#2f54eb 296.17deg,
		#2f54eb 359.96deg,
		#2f54eb 360deg
	);

	&::before {
		content: '';
		width: 70%;
		height: 70%;
		border-radius: 50%;
		position: absolute;
		background-color: white;
	}
`;

export const DividerWrapper = styled(Divider)`
	margin: 6px 0 !important;
	border-top: 1px solid ${BORDER_COLOR} !important;
`;

export const IconWrapper = styled.button`
	font-size: 1rem;
	padding: 3px 8px;
	border-radius: 6px;
	background-color: #f8f8f8;
	border: 1px solid ${BORDER_COLOR};

	&:hover {
		cursor: pointer;
	}
`;

export const CustomPopover = styled(Popover)`
	border-radius: 6px;
`;
