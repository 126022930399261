import styled from 'styled-components';
import { Modal, Button, DatePicker, Radio } from 'antd';

const { Group } = Radio;

export const ModalWrapper = styled(Modal)`
	.ant-modal-body {
		padding: 2rem 3rem;
	}

	.ant-modal-content {
		border-radius: 30px;
		background-color: #ffffff;
	}

	.ant-modal-close-x {
		margin: 0.7rem 0.7rem 0 0;
		font-size: 0.9rem;
	}
`;

export const StyledDatePicker = styled(DatePicker)`
	border-radius: 6px;
`;

export const StyledRadioGroup = styled(Group)`
	width: 100%;
	height: 30px;

	.ant-radio-button-wrapper {
		width: 18%;
		height: 40px;
		padding: 2px;
		text-align: center;

		span:last-child {
			font-size: 13px;
			margin-top: 1rem;
		}
	}

	.ant-radio-button-wrapper:first-child {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		width: 14% !important;
	}

	.ant-radio-button-wrapper:last-child {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		width: 14% !important;
	}

	.ant-radio-button-wrapper:focus-within {
		box-shadow: none;
		background-color: #2f54eb;
		color: #fff;
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		box-shadow: none;
		background-color: #2f54eb;
		color: #fff;
	}
`;

export const PrimaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	background-color: #2f54eb;
	border: 1px solid #2f54eb;
	box-shadow: 0px 2px 0px 0px #0000000b;
`;

export const SecondaryButton = styled(Button)`
	font-size: 1rem;
	min-width: 7rem;
	min-height: 2.5rem;
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 0px 0px #0000000b;
`;
