import React from 'react';
import { Row, Col, Form } from 'antd';

import { Footer } from './Footer';
import { EndDate } from './Fields/EndDate';
import { StartDate } from './Fields/StartDate';
import { TimeFrame } from './Fields/TimeFrame';
import { FiscalYear } from './Fields/FiscalYear';
import { Granularity } from './Fields/Granularity';
import { PredeterminedTime } from './Fields/PredeterminedTime';

const { useWatch } = Form;

interface SingleProps {
	fieldName: string[];
	hasGranularity: boolean;
	handleFormSubmit(): void;
	handleClear(fieldNames: string[]): void;
}

export function Single({ fieldName, handleFormSubmit, hasGranularity, handleClear }: SingleProps) {
	const predeterminedTimeUnique = useWatch([...fieldName, 'predetermined_time_value']);

	return (
		<Row gutter={[0, 20]}>
			<Col span={12}>
				<StartDate disabled={predeterminedTimeUnique} formName={fieldName} />
			</Col>
			<Col span={12}>
				<EndDate disabled={predeterminedTimeUnique} formName={fieldName} />
			</Col>
			<Col span={24}>
				<PredeterminedTime formName={fieldName} />
			</Col>
			{predeterminedTimeUnique && (
				<Col span={24}>
					<Row gutter={[20, 0]}>
						<Col span={12}>
							<TimeFrame formName={fieldName} />
						</Col>
						<Col>
							<FiscalYear formName={fieldName} />
						</Col>
					</Row>
				</Col>
			)}
			{hasGranularity && (
				<Col span={24}>
					<Granularity formName={fieldName} />
				</Col>
			)}
			<Col span={24}>
				<Footer onSubmit={handleFormSubmit} onClear={() => handleClear(fieldName)} />
			</Col>
		</Row>
	);
}
