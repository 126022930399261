import { BaseService } from './base-service';
import { Mosaic } from '@/core/domain/mosaic';
import { CustomReport } from '@/core/domain/custom-report';
import { MosaicFindAllMapper } from '@/core/mapper/mosaic-find-all';
import { MosaicListDTO, GetMosaicRequestDTO } from '@/core/dto/mosaic';
import { CustomReportDTO, GetCustomReportListDTO } from '@/core/dto/custom-report';
import { CustomReportFindAllMapper } from '@/core/mapper/custom-report-find-all';

class CustomReportService extends BaseService<CustomReportDTO> {
	constructor(public readonly basePath: string = '/custom-report') {
		super();
	}

	async list(params: GetCustomReportListDTO): Promise<CustomReport[]> {
		const url = this.basePath + '/list';
		const { data } = await this.getInstance().get<CustomReportDTO>(url, { params });
		return new CustomReportFindAllMapper(data).toDomain();
	}

	async getMosaic(params: GetMosaicRequestDTO): Promise<Mosaic> {
		const url = this.basePath + '/mosaic';
		const { data } = await this.getInstance().get<MosaicListDTO>(url, { params });
		return new MosaicFindAllMapper(data).toDomain();
	}
}

const Service = Object.freeze(new CustomReportService());
export { Service };
