import React from 'react';
import * as S from './styles';
import { Refetch } from '@/components/ui/Refetch';

interface ContainerProps {
	isLoading?: boolean;
	children: JSX.Element | JSX.Element[];
	isError?: boolean;
	onRefetch?(): void;
}

export function Container({ isLoading = false, children, isError = false, onRefetch }: Readonly<ContainerProps>) {
	if (isLoading) {
		return (
			<S.Container justify="center" align="middle">
				<S.GradientSpinner />
			</S.Container>
		);
	}

	if (isError) {
		return (
			<S.Container align="middle">
				<Refetch onClick={onRefetch} />
			</S.Container>
		);
	}

	return <S.Container>{children}</S.Container>;
}
