import styled from 'styled-components';
import { Button, Col } from 'antd';

const BLUE_COLOR = '#2f54eb';

interface SectionButtonProps {
	$isSelected?: boolean;
}

export const FilterButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	padding: 1.2rem 0.6rem;
	font-size: 1.1rem;
	color: #ffffff;
	border: 2px solid ${BLUE_COLOR};
	box-shadow: 2px 4px 10px 0px #00000026;
	background-color: ${BLUE_COLOR} !important;

	&:hover,
	&:focus {
		color: #ffffff;
	}

	span {
		margin-left: 0.8rem;
	}
`;

export const SectionButton = styled(Button)<SectionButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9px;
	padding: 1.2rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#262626')};
	border: 2px solid ${({ $isSelected }) => ($isSelected ? BLUE_COLOR : '#E6E6E6')};
	background-color: ${({ $isSelected }) => ($isSelected ? '#EAEEFD' : '#ffffff')};
`;

export const StyledDivPopOver = styled.div`
	max-width: 31rem;
`;

export const SectionsContainer = styled(Col)`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 20px;
`;
