export const riskColors: { [key: string]: string } = {
	0: '#2F54EB',
	1: '#2CC852',
	2: '#FFDE31',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

export const riskLevels: { [key: number]: string } = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export function calculateResultForScore(score: number): number {
	if (score > 400) {
		return 5;
	}

	if (score > 199) {
		return 4;
	}

	if (score > 69) {
		return 3;
	}

	if (score > 19) {
		return 2;
	}

	return 1;
}
