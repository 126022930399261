import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';

import * as S from './styles';
import { Title } from './Title';
import { Subtitle } from './Subtitle';

interface HeaderProps {
	title: string;
	subtitle?: string;
	isTitleBold?: boolean;
	hasIcon?: boolean;
	icon?: ReactNode;
	popoverComponent: JSX.Element;
	onClick?(): void;
}

export function Header({
	title,
	subtitle = '',
	isTitleBold = false,
	hasIcon = false,
	icon,
	popoverComponent,
	onClick
}: Readonly<HeaderProps>) {
	return (
		<Col span={24}>
			<Row>
				<Col span={24}>
					<Row justify="space-between" align="middle" style={{ padding: ' 0 6px' }}>
						<Col span={22}>
							<Title bold={isTitleBold}>{title}</Title>
							<Subtitle>{subtitle}</Subtitle>
						</Col>
						<Col>
							{hasIcon && (
								<S.CustomPopover placement="bottomLeft" trigger="click" content={popoverComponent}>
									<S.IconWrapper onClick={onClick}>{icon}</S.IconWrapper>
								</S.CustomPopover>
							)}
						</Col>
					</Row>
				</Col>
				<Col span={24}>
					<S.DividerWrapper />
				</Col>
			</Row>
		</Col>
	);
}
