import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CalendarOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';

import { Card } from './components/Card';
import { Mosaic } from './components/Mosaic';
import { Title } from '@/components/Typography';
import { Single } from './components/Filters/Single';
import { FilterIcon } from '@/assets/icons/filter-icon';
import { Compound } from './components/Filters/Compound';
import { GeneralFilter } from './components/Filters/General';
import { formatLocationSearch, shortenUrl, unShortenUrl } from './utils';
import * as S from './styles';

const { useForm } = Form;

function useQuery(): URLSearchParams {
	return new URLSearchParams(useLocation().search);
}

enum TABS {
	ERGNOMIC_RISK = 'ergonomic_risk',
	ERGNOMIC_TOOLS = 'ergonomic_tools',
	JOHN_DEERE_ERGNOMIC_TOOLS = 'jd_ergonomic_tools'
}

const sections = [
	{
		id: 1,
		name: TABS.ERGNOMIC_RISK,
		description: 'Ergonomic Risk Management'
	},
	{
		id: 2,
		name: TABS.ERGNOMIC_TOOLS,
		description: 'Ergonomics Assessments Tools Status'
	},
	{
		id: 3,
		name: TABS.JOHN_DEERE_ERGNOMIC_TOOLS,
		description: 'John Deere Enterprise Ergonomics Assessments Tools Status'
	}
];

export function Dashboard() {
	const [form] = useForm();
	const query = useQuery();
	const history = useHistory();
	const location = useLocation();

	const [isOpenModal, setModal] = useState<boolean>(false);

	const current_tab = query.get('tab') || 'ergonomic_risk';

	function getNonCompressedParams(params: URLSearchParams): string {
		const keys = [
			'tab',
			'custom_report_id',
			'company',
			'company_name',
			'sector',
			'sector_name',
			'line',
			'line_name',
			'workstation',
			'workstation_name',
			'file',
			'file_name'
		];

		return keys
			.map((key) => (params.get(key) ? `${key}=${params.get(key)}` : ''))
			.filter(Boolean)
			.join('&');
	}

	function handleOnSubmit(): void {
		const values = form.getFieldsValue(true);
		const shortened_url = shortenUrl(values);

		const nonCompressedParams = getNonCompressedParams(new URLSearchParams(location.search));
		const newUrl = `${location.pathname}?${shortened_url}${nonCompressedParams ? `&${nonCompressedParams}` : ''}`;
		history.replace(newUrl);
	}

	function handleChangeTab(name: string): void {
		const search_params = new URLSearchParams(location.search);
		search_params.set('tab', name);

		const newUrl = `${location.pathname}?${search_params.toString()}`;
		history.replace(newUrl);
	}

	function isTabSelected(tabName: string): boolean {
		return current_tab === tabName;
	}

	function handleOnOpenModal(): void {
		setModal(!isOpenModal);
	}

	function handleOnClearDynamic(fieldNames: string[]): void {
		form.resetFields(fieldNames);
		const currentParams = unShortenUrl(location.search.substring(1));

		if (typeof currentParams === 'object') {
			fieldNames.forEach((fieldName) => {
				delete currentParams[fieldName];
			});

			const shortened_url = shortenUrl(currentParams);
			const nonCompressedParams = getNonCompressedParams(new URLSearchParams(location.search));
			const newUrl = `${location.pathname}?${shortened_url}${
				nonCompressedParams ? `&${nonCompressedParams}` : ''
			}`;
			history.replace(newUrl);
		}
	}

	const initialValues = formatLocationSearch(location.search, query);

	return (
		<Row gutter={[0, 15]}>
			<Col span={24}>
				<Title level={3}>General overview</Title>
			</Col>
			<Col span={24}>
				<Row gutter={[0, 30]}>
					<Col span={24}>
						<Mosaic />
					</Col>
					<Col span={24}>
						<Row wrap={false} gutter={[40, 0]}>
							<Col>
								<S.FilterButton onClick={() => handleOnOpenModal()} icon={<FilterIcon width={20} />}>
									Filters
								</S.FilterButton>
							</Col>
							<S.SectionsContainer span={20}>
								{sections.map(({ id, name, description }) => (
									<S.SectionButton
										key={id}
										$isSelected={isTabSelected(name)}
										onClick={() => handleChangeTab(name)}
									>
										{I18n.get(description)}
									</S.SectionButton>
								))}
							</S.SectionsContainer>
						</Row>
					</Col>
					<Col span={24}>
						<Form form={form} initialValues={initialValues} onFinish={handleOnSubmit}>
							<Row gutter={[20, 0]}>
								<Col span={12}>
									<Card.Container>
										<Card.Header
											hasIcon
											title="One"
											icon={<CalendarOutlined />}
											popoverComponent={
												<S.StyledDivPopOver>
													<Single
														hasGranularity
														fieldName={['card_test']}
														handleClear={handleOnClearDynamic}
														handleFormSubmit={handleOnSubmit}
													/>
												</S.StyledDivPopOver>
											}
										/>
										<Card.Content>
											<span>teste</span>
										</Card.Content>
									</Card.Container>
								</Col>
								<Col span={12}>
									<Card.Container>
										<Card.Header
											hasIcon
											title="One"
											icon={<CalendarOutlined />}
											popoverComponent={
												<S.StyledDivPopOver>
													<Compound
														fieldName={['card_test']}
														handleClear={handleOnClearDynamic}
														handleFormSubmit={handleOnSubmit}
													/>
												</S.StyledDivPopOver>
											}
										/>
										<Card.Content>
											<span>teste</span>
										</Card.Content>
									</Card.Container>
								</Col>
							</Row>
							<GeneralFilter
								isOpen={isOpenModal}
								onClose={handleOnOpenModal}
								onClear={handleOnClearDynamic}
							/>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
