import React from 'react';
import { Row, Col } from 'antd';
import * as S from './styles';

interface FooterProps {
	onClear(): void;
	onSubmit(): void;
}

export function Footer({ onClear, onSubmit }: Readonly<FooterProps>) {
	return (
		<Row justify="center" gutter={[20, 0]}>
			<Col>
				<S.SecondaryButton onClick={onClear}>Clear</S.SecondaryButton>
			</Col>
			<Col>
				<S.PrimaryButton type="primary" onClick={onSubmit}>
					Apply
				</S.PrimaryButton>
			</Col>
		</Row>
	);
}
