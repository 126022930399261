import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Service } from '@/infra/services/custom-report';

export interface CustomReport {
	id: string;
	name: string;
	description: string;
	acronym: string;
}

interface GetCustomReportListParams {
	organization_id: string;
	company_id?: string;
}

export const useGetCustomReports = (params: GetCustomReportListParams) => {
	return useQuery([QUERY_KEYS.GET_CUSTOM_REPORTS], () => Service.list(params), {
		enabled: !!params.organization_id && !!params.company_id
	});
};
