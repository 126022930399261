const dictionary = {
	'Customer information not found': 'Datos del cliente no encontrados',
	'Account subscription expired, renew to continue.': 'La suscripción a la cuenta expiró, renuévela para continuar',
	'Account subscription cancelled, renew to continue.': 'Suscripción de cuenta cancelada, renovar para continuar.',

	Warning: 'Aviso',
	Yes: 'Sí',
	No: 'No',
	Home: 'Inicio',

	Companies: 'Empresas',
	'Corrupted file': 'Archivo corrompido',
	'Extracted data': 'Datos extraídos',
	'Not processing': 'Sin procesar',
	Processing: 'Procesando',
	Processed: 'Procesada',
	'In queue': 'En fila',

	// Upload
	'Send video': 'Subir video',
	'Add files or drag': 'Agregar archivos o arrastrar',
	'Unsupported file': 'Archivo no soportado',
	'Drop files here': 'Suelte archivos aquí',
	'Unsupported video format': 'Formato de video no compatible',
	and: 'y',
	'is larger than': 'Es mas grande que',
	'Max size': 'Tamaño máximo',
	Blur: 'Desenfocar',
	'Are you sure you want to exit? You have an uploads in process.':
		'¿Estás seguro de que quieres salir? Tienes una carga en proceso.',
	' or many files': ' o muchos archivos',
	'Files number exceeded': 'Número de archivos excedidos',
	'Max files': 'Máximo de archivos',
	'User created with success': 'Usuario creado con éxito',
	'File duplicated': 'Archivo duplicado',

	// Select project
	'Select the desired company below': 'Seleccione la empresa deseada',
	'Select the desired industrial site below': 'Seleccione el centro de trabajo deseado',

	'Create a new company': 'Crear una nueva empresa',
	'Create a new industrial site': 'Crear un nuevo centro de trabajo',

	'Enter a name for the company': 'Introduzca un nombre de empresa',
	'Enter a name for the industrial site': 'Introduzca un nombre para el centro de trabajo',

	'Company created successfully': 'Empresa creada con éxito',
	'Industrial site created successfully': 'Centro de trabajo creada con éxito',

	'There are no registered companies': 'No hay empresas registradas',
	'There are no registered industrial sites': 'No hay centro de trabajo registrado',

	'Failed to fetch information': 'Error al obtener información',

	'Congratulations! Now create an industrial site that belongs to this company.':
		'¡Felicidades! Ahora cree el centro de trabajo que pertenece a esta empresa y comience a usar la plataforma',
	'Create industrial site': 'Crear centro de trabajo',

	'Welcome to Kinebot, create your first company': 'Bienvenido a Kinebot, crea tu primera empresa',
	'Create company': 'Crear empresa',

	'Ergonomic tools': 'Herramientas ergonómicas',
	'Select the most suitable tool for your analysis': 'Seleccione la herramienta más adecuada para su análisis',
	'Biomechanical analysis': 'Análisis biomecánico',
	'Load analysis': 'Análisis de carga',
	'Repeatability analysis': 'Análisis de repetibilidad',

	'Go back': 'Regresar',
	Back: 'Regresar',
	Create: 'Crear',

	// Report/UploadList
	Workstation: 'Puesto de trabajo',
	Description: 'Descripción',
	Size: 'Tamaño',
	'Upload date': 'Fecha de carga',
	Status: 'Provincia',
	Sector: 'Sector',
	Actions: 'Acciones',
	'Not assigned': 'No asignado',
	'Heads up!': '¡Aviso!',
	'Heads up! Want to delete this file?': '¡Aviso! ¿Quieres eliminar este archivo?',
	'Heads up! Want to delete this report?': '¡Aviso! ¿Quieres eliminar este reporte?',
	'Heads up! Want to delete this action plan?': '¡Aviso! ¿Quieres eliminar este plan de acción?',
	'Heads up! Want to delete this workstation?': '¡Aviso! ¿Quieres eliminar este puesto de trabajo?',
	'Heads up! Want to delete this line? All workstations related to it will be lost.':
		'¡Aviso! ¿Quieres eliminar esta línea? Se perderán todos los puestos de trabajo relacionados con él.',
	'Heads up! Want to delete this sector? All lines and workstations related to it will be lost.':
		'¡Aviso! ¿Quieres eliminar este sector? Se perderán todas las líneas y puestos de trabajo relacionados con él.',
	'Heads up! Want to delete this industrial site? All sectors, lines and workstations related to it will be lost.':
		'¡Aviso! ¿Quiere eliminar este centro de trabajo? Se perderán todos los sectores, líneas y puestos de trabajo relacionados con él.',
	'Force/Charge movement data': 'Datos de movimiento con fuerza/carga',
	Rating: 'Clasificación',
	Repetitions: 'Repeticiones',
	Legs: 'Piernas',
	Diagnostic: 'Diagnóstico',
	'Analysis results': 'Resultados de analisis',

	'Not found': 'No encontrado',
	'Failed to fetch data': 'Error al obtener los datos',
	//'Risk level of the job': 'Grado de riesgo del trabajo',
	'Average exposure of the workplace': 'Exposición media del lugar de trabajo',
	'Unable to show results.': 'No se pueden mostrar los resultados',

	'Load less than 2 kg (intermittent)': 'Carga menos de 2kg (intermitente)',
	'Load from 2 kg to 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg to 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estática o repetitiva)',
	'Bigger load 10 kg of repetitive load or blows': 'Mayor carga 10kg de carga repetitiva o golpe',
	'Legs and feet supported and with equal load distribution':
		'Piernas y pies soportados y con igual distribución de carga',
	'Legs without support': 'Piernas sin apoyo',

	Reports: 'Reportes',
	'Select the data visualization model': 'Seleccione el modelo de visualización de datos',
	Angle: 'Ángulo',
	'Angle in degrees (°)': 'Ángulo en grados (°)',
	'Time in seconds (s)': 'Tiempo en segundos (s)',
	'Movement by score': 'Movimiento por puntuación',
	Parameters: 'Parámetros',

	'Push and pull': 'Empuje y arraste',
	'KIM - Push and pull': 'KIM - Empuje y arraste',

	'KIM - PP Report Results': 'KIM - EA Informe de Resultados',
	meters: 'metros',
	'Temporal distribution': 'Distribución del tiempo',
	Distance: 'Distancia',
	'Vehicle properties': 'Propiedades del vehículo',
	Vehicle: 'Vehículo',
	'Distance or duration': 'Distancia o duración',

	'Select one of the options below': 'Seleccione una de las siguientes opciones',
	'Short distances': 'Distancias cortas',
	'Long distances': 'Distancias largas',
	Time: 'Tiempo',
	Mass: 'Masa',
	Precision: 'Precision',
	'Worker gender': 'Género del trabajador',
	'Pushing / Pulling in pairs': 'Empujando / Arrastando em parejas',
	Male: 'Masculino',
	Female: 'Femenino',
	'By distance': 'Por la distancia',
	'By duration': 'Por la duración',
	'Distance per work day (in meters)': 'Distancia por lo día de trabajo (en metros)',
	'Duration per work day (in minutes)': 'Duración por día de trabajo (en minutos)',
	'≤ 40': '≤ 40',
	'≤ 200': '≤ 200',
	'≤ 400': '≤ 400',
	'≤ 800': '≤ 800',
	'≤ 1,200': '≤ 1.200',
	'≤ 1,800': '≤ 1.800',
	'≤ 2,500': '≤ 2.500',
	'≤ 4,200': '≤ 4.200',
	'≤ 6,300': '≤ 6.300',
	'≤ 8,400': '≤ 8.400',
	'≤ 11,000': '≤ 11.000',
	'≤ 15,000': '≤ 15.000',
	'≤ 20,000': '≤ 20.000',
	'≤ 1': '≤ 1',
	'≤ 5': '≤ 5',
	'≤ 10': '≤ 10',
	'≤ 20': '≤ 20',
	'≤ 30': '≤ 30',
	'≤ 45': '≤ 45',
	'≤ 60': '≤ 60',
	'≤ 100': '≤ 100',
	'≤ 150': '≤ 150',
	'≤ 210': '≤ 210',
	'≤ 270': '≤ 270',
	'≤ 360': '≤ 360',
	'≤ 480': '≤ 480',
	'Select one of the vehicles': 'Seleccione uno de los vehículos',
	Barrows: 'Carretillas',
	Carriages: 'Carruaje',
	'Overhead conveyors': 'Transportadores',
	'Overhead cranes': 'Gruas',
	'Choose an option': 'Elige una opción',
	'Reference image: only swivel castors': 'Imagen de referencia: ruedas móviles solamente',
	'Reference image: with fixed castors or lockable swivel castors':
		'Imagen de referencia: con ruedas fijas o bloqueables',
	'Reference image: pedestrian-controlled': 'Imagen de referencia: controlado por peatones',
	'Driveway condition': 'Estado de la acera',
	'Inclination or stairs': 'Pendiente o escaleras',
	'Select the mass to be transported (in kg)': 'Seleccione la masa a transportar (en kg)',
	'Driveway completely level, smooth, solid, dry, without inclinations':
		'Pavimento completamente nivelado, liso, sólido, seco, sin pendientes',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'Acera casi lisa y nivelada, con pequeños daños en algunos lugares, sin pendientes',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'Mezcla de adoquines, hormigón, asfalto, pendientes ligeras, bordillo bajo',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'Mezcla áspera de adoquines, arena dura, pendientes ligeras y bordes/alféizares pequeños',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'Mezcla áspera de tierra o adoquines, baches, tierra pesada, pendientes pequeñas, descansos, umbrales',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': 'Pendientes de 2 ≤ 4° (4 ≤ 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': 'Pendientes de 5 ≤ 10° (9 ≤ 18%)',
	'Stairs, inclinations > 10° (18%)': 'Escaleras, pendientes > 10° (18%)',
	'No significant inclination': 'Sin pendiente relevante',
	'≤ 50': '≤ 50',
	'≤ 300': '≤ 300',
	'≤ 600': '≤ 600',
	'≤ 1,000': '≤ 1.000',
	'≤ 1,300': '≤ 1.300',
	'> 1,300': '> 1.300',
	'Select the appropriate options': 'Seleccione la opción apropiada',
	'Unfavorable working conditions - more than one can be selected':
		'Condiciones laborales desfavorables - se puede seleccionar más de una opción',
	'Unfavorable vehicle properties - more than one can be selected':
		'Propiedades desfavorables del vehículo - se puede seleccionar más de una opción',
	'Unfavorable working conditions': 'Condiciones laborales desfavorables',
	'Unfavorable vehicle properties': 'Propiedades desfavorables del vehículo',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'Regularmente se requiere una fuerza considerable para iniciar el movimiento, debido a que el vehículo se hunde en el suelo o se atasca',
	'Frequent stops with breaking': 'Paradas frecuentes con frenado',
	'Frequent stops without breaking': 'Paradas frecuentes sin frenado',
	'Many changes of direction or curves, frequent manouvering':
		'Muchos cambios de dirección o giros, maniobras frecuentes',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'La carga debe colocarse y asegurarse con precisión, adhiriéndose a un lugar exacto en la acera',
	'Increased movement speed (approx. 1.0 ≤ 1.3 m/s)': 'Alta velocidad de movimiento (aprox. 1.0 ≤ 1.3 m/s)',
	'None: there are no unfavorable working conditions': 'Ninguna: no existen condiciones laborales desfavorables',
	'No suitable handles or construction parts for applying force': 'No hay manijas o partes aptas para aplicar fuerza',
	'No brake when driving on inclinations > 2° (> 3%)': 'Sin frenos al manipular o inclinarse > 2° (> 3%)',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'Ruedas mal ajustadas (ej.: demasiado pequeño o no coincide con el suelo)',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)':
		'Ruedas defectuosas (desgastadas, rozando, atascadas, presión de aire demasiado baja)',
	'None: there are no unfavorable properties of the transport devices':
		'Ninguna: no hay propiedades desfavorables del vehículo',

	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'Torso erguido o ligeramente inclinado hacia adelante, sin torsión / Fuerza de aplicación a la altura libremente seleccionada / Sin molestias para las piernas',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / No or only slight hindrance for the legs / Predominantly pulling':
		'Cuerpo inclinado en la dirección del movimiento o ligeramente torcido tirando de la carga hacia los lados / Fuerza aplicada a una altura fija de 0,9 - 1,2 m / Sin complicaciones en las piernas o menores / Predominantemente tirando',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'Posturas extrañas del cuerpo causadas por fuerza a una altura fija de < 0,9 o > 1,2 m, aplicación de fuerza lateral a un lado o con una visión significativamente obstruida / Complicación considerable para las piernas / Torsión constante y/o flexión lateral del tronco',

	'Trunk upright or slightly inclined forward, no twisting':
		'Torso erguido o ligeramente inclinado hacia adelante, sin torsión',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'Cuerpo inclinado en la dirección del movimiento o ligeramente torcido tirando de la carga hacia los lados',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'Posturas extrañas del cuerpo causadas por fuerza a una altura fija de < 0,9 o > 1,2 m, aplicación de fuerza lateral a un lado o con una visión significativamente obstruida',

	'Force application height can be selected freely': 'Fuerza de aplicación a la altura libremente seleccionada',
	'Fixed force application height ranging from 0.9 – 1.2 m': 'Fuerza aplicada a una altura fija de 0,9 - 1,2 m',
	'Significant hindrance for the legs': 'Complicación considerable para las piernas',

	'No hindrance for the legs': 'Sin molestias para las piernas',
	'No or only slight hindrance for the legs': 'Sin complicaciones en las piernas o menores',
	'Constant twisting and/or lateral inclination of the trunk': 'Torsión constante y/o flexión lateral del tronco',
	'Predominantly pulling': 'Predominantemente tirando',

	Postures: 'Posturas',
	'Direction of force': 'Dirección de la fuerza',
	Unfavorable: 'Desfavorable',
	'Score and risk assessment': 'Score y definición de riesgo',
	'Intensity of load: ': 'Intensidad de carga',
	'Probability of physical overload / Possible health consequences: ':
		'Probabilidad de sobrecarga física / Posibles consecuencias para la salud',
	'Probability of physical overload': 'Probabilidad de sobrecarga física',
	'Physical overload is unlikely / No health risk is to be expected.':
		'Sobrecarga física improbable / No se espera riesgo para la salud.',
	'Physical overload is unlikely': 'Sobrecarga física improbable',
	'No health risk is to be expected': 'No se espera riesgo para la salud',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'La sobrecarga física es posible para personas menos resistentes / Fatiga, problemas menores de adaptación que se pueden compensar con tiempo de descanso',
	'Physical overload is possible for less resilient persons':
		'La sobrecarga física es posible para personas menos resistentes',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'Fatiga, problemas menores de adaptación que se pueden compensar con tiempo de descanso',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'La sobrecarga física es posible incluso para personas con resistencia normal / Trastornos (dolor), posibilidad de disfunción, reversible en la mayoría de los casos, sin manifestación morfológica',
	'Physical overload is also possible for normally resilient persons':
		'La sobrecarga física es posible incluso para personas con resistencia normal',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'Trastornos (dolor), posibilidad de disfunción, reversible en la mayoría de los casos, sin manifestación morfológica',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Es probable una sobrecarga física / Marcadas alteraciones y/o disfunciones, daño estructural con significado patológico.',
	'Physical overload is likely': 'Es probable una sobrecarga física',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'Marcadas alteraciones y/o disfunciones, daño estructural con significado patológico',
	'Measures: ': 'Mediciones',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		' Para las personas menos resilientes, cambiar el lugar de trabajo y otras medidas preventivas pueden ser útiles.',
	' Workplace redesign and other prevention measures should be considered.':
		' Se debe considerar cambiar el lugar de trabajo y otras medidas preventivas.',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Es necesario cambiar el lugar de trabajo. También se deben considerar otras medidas preventivas.',
	None: 'Ninguna',

	'Manual lifting': 'Levantamiento de cargas',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH / ISO 11228-1 - Análisis de levantamiento de cargas',

	Information: 'Información',
	'Body parts': 'Partes del cuerpo',
	Charts: 'Gráficos',

	'Industrial Site': 'Centro de trabajo',
	'Collection date': 'Fecha de colección',
	'Risk range': 'Rango de riesgo',
	'Risk Range': 'Rango de riesgo',
	'Failed to fetch sectors': 'Error al obtener sectores',
	'Failed to create a new sector': 'No se pudo crear un nuevo sector',
	'Failed to fetch risk bands': 'Error al buscar bandas de riesgo',
	'Sector created successfully': 'Sector creado con éxito',
	'Line created successfully': 'Línea creada con éxito',
	'Workstation created successfully': 'Puesto de trabajo creado con éxito',
	'TLID created successfully': 'TLID creada con éxito',
	'Evaluator created successfully': 'Evaluador creado con éxito',
	'Cycle created successfully': 'Ciclo creado con éxito',

	'Select a sector': 'Seleccione un sector',
	'Insert a workstation': 'Insertar un trabajo',
	'Insert the date of collection': 'Introduzca la fecha de recogida',
	'Select a risk band': 'Seleccione un rango de riesgo',
	'Graphs of selected body parts': 'Gráfico de partes del cuerpo seleccionadas',

	Summary: 'Resumen',
	'Range of Risk': 'Rango de riesgo',
	'Job information': 'Informacion del trabajo',
	'File data': 'Datos del archivo',

	File: 'Archivo',
	Duration: 'Duración',
	'Relevant points': 'Puntos relevantes',
	'Most critical move': 'Movimiento más crítico',
	'Include conclusion': 'Incluir conclusión',
	'Include signature': 'Incluya Firma',
	'Insert conclusion': 'Incluir conclusión',
	'Risk track created successfully!': '¡Pista de riesgo creada con éxito!',
	'Create custom risk bands based on your analysis': 'Cree bandas de riesgo personalizadas basadas en su análisis',

	//Dashboard
	'Industrial site status': 'Estado del centro de trabajo',
	'Harmful postures': 'Posturas perjudiciales',
	'Inadequate postures': 'Posturas inapropiadas',
	'Workstation with good postures': 'Mensajes con buenas posturas',
	'Workers in real danger': 'Trabajadores en riesgo real',
	'Total of videos': 'Vídeos totales',
	'Processed minutes': 'Minutos procesados',
	'Critical sector': 'Sector crítico',
	'Registered sectors': 'Sectores registrados',
	'Exported reports': 'Reportes extraídos',
	'Sectors in alert': 'Sectores de alerta',
	'Main statistics': 'Estadísticas principales',
	'No. of videos by risk level': 'Total de videos por nivel de riesgo',
	Month: 'Mes',
	Year: 'Año',
	Day: 'Dia',
	'Assessments completed': 'Evaluaciones completas',
	'Videos by sector': 'Vídeos por sectores',
	'Percentage of videos by risk': 'Porcentaje de videos en riesgo',
	'Ergonomics Management': 'Gestión de la ergonomía',
	'Risk Quotation': 'Cotización de riesgo',
	'Total workstations': 'Estaciones de trabajo totales',
	'Total pending evaluations': 'Total de evaluaciones pendientes',
	'Total evaluations consolidated': 'Total evaluaciones consolidadas',
	Evaluations: 'Evaluaciones',
	'Not analyzed': 'No analizado',
	'There are no registered action plans': 'No hay planes de acción registrados',
	'Not applicable': 'No aplicable',
	'Acceptable Risk': 'Riesgo aceptable',
	'Acceptable risk': 'Riesgo aceptable',
	'Moderate Risk': 'Riesgo moderado',
	'Moderate risk': 'Riesgo moderado',
	'High Risk': 'Alto riesgo',
	'High risk': 'Alto riesgo',
	'Very High Risk': 'Riesgo muy alto',
	'Very high risk': 'Riesgo muy alto',
	'Extreme and Imminent Risk': 'Riesgo extremo e inminente',
	'Serious and imminent risk': 'Riesgo extremo e inminente',
	'Serious and Imminent Risk': 'Riesgo extremo e inminente',
	'Serious risk': 'Riesgo extremo',
	'Low Risk': 'Riesgo leve',
	'Medium Risk': 'Riesgo moderado',
	'Unit overview': 'Descripción general de la unidad',
	'There is nothing to access.': 'No hay nada a lo que acceder.',

	// Dashboard Filter
	Lines: 'Líneas',
	'Select the line': 'Seleccione la línea',
	'Select the Workstation': 'Seleccione la estación de trabajo',
	'Working Population': 'Población trabajadora',
	Men: 'Hombres',
	Women: 'Mujer',
	Others: 'Otros',
	'Stress level': 'Nivel de estrés',
	'Risk Level': 'Nivel de riesgo',

	// Dashboard Tools Results
	Repetitiveness: 'Repetitividad',
	'Cargo Handling': 'Manipulación de la carga',
	'Work Organization': 'Organización del trabajo',
	'Score by workstation': 'Puntuación por estación de trabajo',
	'Total evaluations': 'Evaluaciones totales',
	'Stress Level': 'Nivel de estrés',
	Low: 'Bajo',
	Average: 'Promedio',
	High: 'Alto',

	// Dashboard Movement by score
	'Risk per Workstation': 'Riesgo por estación de trabajo',

	// Dashboard Workstations
	Workstations: 'Estaciones de trabajo',
	'Higher workstation risk score': 'Puntuación de riesgo de estación de trabajo más alta',
	'Workstation score': 'Puntuación de la estación de trabajo',

	// Dashboard Action Plans
	'Planned Actions': 'Acciones planeadas',
	Completed: 'Hecho',
	'To do': 'Hacer',
	Concluded: 'Concluido',
	'Order by deadline': 'Ordenar por prazo',
	'Order by worst score': 'Ordenar por pior pontuação',
	Ascending: 'Ascendente',
	Descending: 'Descendente',
	'Deadline : ': 'Prazo: ',

	// Buttons
	Next: 'Siguiente',
	Previous: 'Regresar',
	delete: 'eliminar',
	Delete: 'Eliminar',

	'Do you really want to delete these tracks?': '¿Realmente desea eliminar estas pistas?',
	'Heads up! the deletion is permanent': '¡Aviso! La eliminación es permanente',
	'Risk ranges deleted successfully': 'Rangos de riesgo eliminados con éxito',

	'Description (optional)': 'Descripción (opcional)',

	// Body parts
	Neck: 'Cuello',
	Trunk: 'Tronco',
	UpperArm: 'Hombro',
	LowerArm: 'Codo',

	'Customize bands of risk': 'Personalice los rangos de riesgo',
	'Create custom risk strips according to your needs.': 'Crea tiras de riesgo personalizadas según tus necesidades',

	Name: 'Nombre',
	'Industrial site name': 'Ingresa un nombre',
	'Enter a name': 'Introduzca un nombre válido',
	'Please enter a valid name': 'Introduzca un número de teléfono válido',
	'Please enter a valid phone number': 'Por favor ingrese un número de teléfono válido',
	'Invalid phone number': 'Numero de telefono invalido',
	'Insert short description': 'Introduzca una breve descripción',
	'Add new band': 'Añadir niebla de banda',
	'Create new band': 'Crear nueva banda',
	'Minimum angle': 'Ángulo mínimo',
	'Maximum angle': 'Ángulo máximo',
	Coloring: 'Colorante',
	Remove: 'Para eliminar',

	//	Breadcrumb
	'Manage users': 'Administrar usuarios',
	Preferences: 'Preferencias',
	'My informations': 'Mis informaciones',
	'My plan': 'Mi plan',

	individual: 'Individual',
	team: 'Equipo',
	business: 'Negocio',
	pilot: 'Piloto',

	yearly: 'anual',
	monthly: 'mensal',
	Yearly: 'Anual',
	Monthly: 'Mensal',

	User: 'Usuario',
	Supervisor: 'Supervisor',
	Master: 'Maestro',
	Admin: 'Administrador',

	'Choose a plan different from your current plan': 'Elija un plan diferente a su plan actual',
	'Plan successfully canceled': 'Plan cancelado con éxito',
	'We received your order, we are processing your payment': 'Hemos recibido tu pedido, estamos procesando tu pago',

	'Password changed successfully': 'Contraseña alterada con éxito',

	'Customer plan not found': 'Plan de cliente no encontrado',
	'Customer data not found': 'Datos del cliente no encontrados',
	'User access not found': 'Permiso de usuario no encontrado',
	'File deleted successfully': 'Archivo eliminado con éxito',

	'Check the parameters entered': 'Verifique los datos ingresados',
	'Number of document characters are invalid': 'El número de caracteres del documento no es válido',
	'Invalid inserted CPF': 'CPF ingresado no válido',
	'Invalid inserted CNPJ': 'CNPJ ingresado no válido',

	'It is necessary to spend a day after contracting the service to change the plan':
		'Es necesario pasar un día después de contratar el servicio para cambiar de plan',

	'Fake communication failure': 'Fallo de comunicación',

	'Remember me': 'Acordarse de mi',
	'Forgot password': '¿Olvidaste tu contraseña?',
	'Log in': 'Acceso',
	Password: 'Clave',
	'Please input your password!': '¡Por favor ingrese su contraseña!',
	'Invalid email or password': 'Correo electrónico o contraseña no válidos',
	'The input is not a valid e-mail!': 'Cuenta inactiva, actívela',
	'Please input your e-mail': 'Por favor ingrese su correo electrónico',
	'Inactive account, please activate': 'Cuenta inactiva, actívala',
	'Enter your email': 'Introduce tu correo electrónico',
	Send: 'Enviar',
	'User not found': 'Usuario no encontrado',
	'Success, check your email box': 'Éxito, revisa tu bandeja de entrada',
	'Password recovery': 'Restablecimiento de contraseña',
	'Token invalid': 'Solicitud no válida',
	'Token expired, generated a new one': 'La sesión expiró, por favor inténtalo de nuevo',
	'Please confirm your password!': 'Por favor, confirme su contraseña',
	'Confirm Password': 'Confirme su contraseña',
	'The two passwords that you entered do not match!': 'Las dos contraseñas no coinciden, compruebe',

	Confirm: 'Confirmar',
	Cancel: 'Cancelar',
	flexion: 'Flexión',
	turned: 'Volcado',
	extension: 'Extensión',
	sideways: 'Lateralmente',
	crouched: 'En cuclillas',
	downed: 'Bajado',
	arm_raised: 'Brazo levantado',
	behind: 'Atrás',
	raised: 'Levantado',
	none: 'Ninguna',

	// Check-in Customer
	'Registered successfully!': '¡Registro completado con éxito!',
	'Just a little longer, check your e-mail inbox':
		'Ahora es el momento de revisar su bandeja de entrada de correo electrónico',
	Access: 'Acceso',
	'Kinebot - Terms of use': 'Kinebot-Términos de uso',
	'terms of use': 'términos de uso',
	"Welcome! Let's start": '¡Bienvenido, empecemos!',
	'Enter your phone number': 'Introduzca su teléfono',
	'Select your contry': 'Selecciona tu pais',
	State: 'Estado',
	'State:': 'Estado:',
	'Select your state': 'Seleccione su estado',
	'Select your city': 'Seleccione su ciudad',
	'Zip code': 'CEP',
	'Enter your zipcode': 'Introduzca su código postal',
	'Enter your address': 'Ingrese su dirección',
	'Enter your street number': 'Ingrese el número de la calle',
	'Coupon (optional)': 'Cupón (opcional)',
	Coupon: 'Cupón',

	// User Activation
	'There are problems on your operation.': 'Hay algunos problemas con su operación',
	Leave: 'Salir',
	Data: 'Datos',
	'Final steps': 'Finalización',
	'Card number': 'Numero de tarjeta',
	'Enter your credit card number': 'Ingrese el número de tarjeta',
	'Invalid credit card': 'Tarjeta no valida',
	'Card holder name': 'Nombre del titular de la tarjeta',
	'Enter the card holder name': 'Nombre del titular de la tarjeta',
	'Due date': 'Validez',
	"Enter the card's due date": 'Ingrese la validez de la tarjeta',
	'Invalid month': 'Mes inválido',
	'Invalid card date': 'Fecha de tarjeta inválida',
	'Enter the security code': 'Introduzca el código de seguridad',
	'Invalid code': 'Codigo invalido',
	'Payment information': 'Datos de pago',

	// Maintenance
	"We are going through maintenance, we'll be back shortly.": 'Estamos en mantenimiento, pronto estaremos de vuelta',

	// Finalization
	'Personal data': 'Datos personales',
	'Document no.': 'CPF',
	'Payment details': 'Detalles del pago',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'El monto del cargo solo se realizará después del período gratuito y se puede cancelar en cualquier momento',
	'Payment method': 'Método de pago',
	'Payment options': 'Tipo de pago',
	'Full payment': 'A la vista',
	Discounts: 'Descuentos',
	'I have read and agree with the': 'He leído y estoy de acuerdo con el',
	Close: 'Cerrar',

	// User Activation - Information
	'Validating coupon': 'Cupón de validación',
	"Couldn't find the coupon": 'No se puede obtener el cupón',
	Individual: 'Individual',
	Team: 'Equipo',
	Business: 'Negocio',
	'Enter your document no.': 'Introduzca el CPF',
	'Enter a valid document': 'Introduzca un CPF válido',
	'Enter a phone number': 'Introduzca un teléfono',
	'Enter the zip code': 'Ingrese el código postal',
	'Enter the city': 'Entra en la ciudad',
	'Enter the state': 'Introduzca el estado',
	'Street number': 'Número de calle',
	'Street number:': 'Número de calle:',

	// Credit Card
	'Under review': 'Tarjeta en revisión',
	'Insufficient funds': 'Fondos insuficientes',
	'Not accepted': 'Transacción inválida',
	'Customer already registered': 'Usuario ya registrado',
	'Failed to perform transaction': 'No se pudo realizar la transacción',

	'email must be unique': 'Correo electrónico ya registrado',

	'Error loading video': 'Error al cargar el video',
	'Failed to fetch your plan information.': 'No se pudo obtener la información de su plan',
	'Failed to fetch total customer videos.': 'No se pudo obtener el total de videos del cliente',
	'Maximum uploads reached.': 'Cargas máximas alcanzadas',
	'Maximum minutes reached.': 'Minutos máximos alcanzados',
	'Corrupted video.': 'Vídeo corrupto',

	// Painel control
	'Maximum users reached': 'Máximo de usuarios registrados',

	"The 'name' field is required.": 'El campo de nombre es obligatorio',
	"The 'email' field is required.": 'El campo de correo electrónico es obligatorio',
	"The 'country' field is required.": 'El campo del país es obligatorio',
	"The 'state' field is required.": 'El campo de estado es obligatorio',
	"The 'city' field is required.": 'El campo de la ciudad es obligatorio',
	"The 'address' field is required.": 'El campo de dirección es obligatorio',
	"The 'street_number' field is required.": 'El campo de número es obligatorio',
	"The 'zipcode' field is required.": 'El campo del código postal es obligatorio',

	// ********************************** //
	'Personal information': 'Informaciones personales',
	Localization: 'Ubicación',

	'Full name': 'Nombre completo',
	'Full name:': 'Nombre completo:',
	'Enter your full name': 'Ingrese su nombre completo',
	'Enter your name': 'Ingrese su nombre',
	'Insert a document': 'Insertar un documento',
	'Insert a valid document': 'Insertar un documento válido',
	Address: 'Dirección',
	'Address:': 'Dirección:',
	'Enter an address': 'Introduce una direccion',
	Number: 'Número',
	'Enter the number': 'Ingrese un numero',
	Zipcode: 'Código postal',
	'Zipcode:': 'Código postal:',
	'Enter zip code': 'Introduzca el código postal',
	Country: 'País',
	'Country:': 'País:',
	'Select your country': 'Selecciona tu pais',
	'Select state': 'Seleccione el estado',
	City: 'Ciudad',
	'City:': 'Ciudad:',
	'Select city': 'Seleccione la ciudad',

	'Update performed': 'Operación realizada',
	'Customer information updated successfully': 'Información actualizada con éxito',
	'Fill in your personal information': 'Complete su información personal',

	// Forgot password
	'Forgot your password?': '¿Olvidaste tu contraseña?',
	'We will send you an email to retrieve your password, rest assured :)':
		'Te enviaremos un email para recuperar tu contraseña, no te preocupes :)',
	'Back to login': 'Atrás para iniciar sesión',
	'Please enter a valid e-mail address': 'Introduzca un correo electrónico válido',
	'Enter an e-mail': 'Introduzca un correo electrónico',

	'Oops... Something went wrong!': 'Ops.. Algo salió mal',
	'Operation performed successfully': 'Operación realizada con éxito',
	'Check your email inbox': 'Revisa tu bandeja de entrada de correo electrónico',
	'Create new password': 'Crear una nueva contraseña',
	'Your new password must be different from previous used passwords':
		'Su nueva contraseña debe ser diferente de las contraseñas que utilizó anteriormente',
	'Enter your new password': 'Introduzca su nueva contraseña',
	'Confirm new password': 'Confirme la nueva contraseña',
	'The two passwords that you entered do not match': 'Las dos contraseñas que ingresaste no coinciden',
	Save: 'Guardar',
	'Click here to continue': 'Clic aquí para continuar',
	'Invalid Token': 'Simbolo no valido',
	'Expired token': 'Contador caducado',

	trunk: 'Tronco',
	neck: 'Cuello',
	left_lower_arm: 'Codo izquierdo',
	right_lower_arm: 'Codo derecho',
	left_upper_arm: 'Hombro izquierdo',
	right_upper_arm: 'Hombro derecho',
	left_knee: 'Rodilla izquierda',
	right_knee: 'Rodilla derecha',
	left_ankle: 'Tobillo izquierdo',
	right_ankle: 'Tobillo derecho',
	hip: 'Cadera',
	left_hand: 'Puño izquierdo',
	right_hand: 'Puño derecho',

	Knee: 'Rodilla',
	Ankle: 'Tobillo',
	Hip: 'Cadera',
	Hand: 'Muñecas',
	Qualification: 'Calificación',
	'Select the body parts to display': 'Seleccione las partes del cuerpo que se mostrarán',

	safe: 'Aceptable',
	info: 'Ligero',
	warning: 'Alto',
	danger: 'Extremo',

	'Light risk': 'Riesgo ligero',
	'Average risk': 'Riesgo medio',

	Level: 'Nivel',
	'Limit of bands reached': 'Límites de rango de banda alcanzados',

	'Industrial site': 'Centro de trabajo',
	Period: 'Período',
	Search: 'Buscar',
	Clear: 'Borrar',

	'Select company': 'Seleccione empresa',

	//User management
	'Create new user': 'Crear nuevo usuario',
	Email: 'Correo electrónico',
	Permissions: 'Permisos',
	'Companies - Industrial sites': 'Empresas - Centro de trabajo',

	'Change password': 'Cambiar contraseña',
	'Current password': 'Contraseña actual',
	'New password': 'Nueva contraseña',
	'Confirm password': 'Confirmar seña',
	'Enter your current password': 'Introduce tu contraseña actual',
	'Enter a new password': 'Introduzca una nueva contraseña',
	'Password must be at least 6 characters long': 'La contraseña debe tener al menos 6 caracteres',
	'Repeat your new password': 'Repita su nueva contraseña',
	"The two passwords don't match, check": 'Las dos contraseñas no coinciden, verifique',
	'Sorry time exceeded, try again.': 'Lo sentimos, se excedió el tiempo, inténtelo de nuevo',
	'Invalid current password': 'Contraseña actual inválida',
	'Update information': 'Actualice la información',

	'Phone number': 'Teléfono',
	'Account type': 'Tipo de cuenta',
	'Created in': 'Creado en',
	'Postal code': 'Código postal',
	'Sorry, something went wrong :(': 'Perdón, algo salió mal :(',
	Retry: 'Intentar nuevamente',

	//
	// Layout - Sidebar
	Settings: 'Ajustes',
	'My account': 'Mi cuenta',
	Plan: 'Plan',
	'Subscription type': 'Tipo de suscripción',
	'Subscription type:': 'Tipo de suscripción:',
	Recurrence: 'Reaparición',
	'Recurrence:': 'Reaparición:',
	'Expiration date': 'Fecha de expiración',
	'Plan expiration date:': 'Fecha de vencimiento del plan:',
	'Active users': 'Usuarios activos',
	'Processed videos': 'Vídeos procesados',
	'Processed videos:': 'Vídeos processados:',
	'Processing time': 'Tiempo de procesamiento',
	'Processing time:': 'Tiempo de procesamiento:',
	'Additional minutes': 'Minutos adicionales',
	'Add credits': 'Agregar créditos',
	'Renew subscription': 'Renovar la suscripción',
	'Change subscription': 'Cambiar firma',
	'Cancel subscription': 'Cancelar suscripción',
	of: 'en',
	minutes: 'minutos',

	Test: 'Prueba',
	Minutes: 'Minutos',
	Purchase: 'Compra',
	Payments: 'Pagos',
	Payment: 'Pago',
	'Buy additional credits': 'Comprar créditos adicionales',
	'Enter the amount of additional credits (minutes) below to continue using the platform':
		'Ingrese la cantidad de créditos adicionales (minutos) a continuación para continuar usando la plataforma',
	'Consolidate data': 'Consolidar datos',
	'Export report': 'Reporte de exportacion',
	'There is no post with screwdriver / screwdriver': 'No hay poste con destornillador/destornillador',
	'Data not found': 'Datos no encontrados',
	'Requires actions': 'Requiere acciones',
	'No actions required': 'No requiere acciones',
	'High Risk and Requires Action': 'Alto riesgo y requiere acciones',
	'Moderate risk and no action required': 'Riesgo moderado y no requiere acción',
	'Low Risk and No Action Required': 'Bajo riesgo y no requiere acción',
	'Intensity of work': 'Intensidad del trabajo',
	'Work organization': 'Organización del trabajo',
	'Is spontaneous breaks allowed during the workday?':
		'¿Están permitidos los descansos espontáneos durante la jornada laboral?',
	'Is there a diversification of tasks in the performance of your functions?':
		'¿Existe diversificación de tareas en el desempeño de sus funciones?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'¿Los materiales están siempre disponibles en el puesto de trabajo, evitando prisas, retrasos o sobrecargas?',
	Complexity: 'Complejidad',
	'Movements do not require high dexterity': 'Los movimientos no requieren altas habilidades',
	'Low level of attention requirement for long periods':
		'Bajo nivel de exigencia de atención durante largos períodos',
	'Workstation is complex and high cognitive demand': 'El rango es complejo y cognitivamente exigente',
	'Workstation is simple and of low cognitive requirement': 'Rank es simple y de baja demanda cognitiva',
	'Displacement ': 'Desplazamiento ',
	'Squat ': 'Ponerse en cuclillas ',
	Cervical: 'Cervical',
	//
	'Left Shoulder/Arm': 'Hombro/brazo izquierdo',
	'Right Shoulder/Arm': 'Hombro/brazo derecho',
	'Left wrist': 'Puño izquierdo',
	'Right wrist': 'Puño derecho',
	'No repeatability': 'Sin repetibilidad',
	'With repetition': 'Con repetibilidad',
	' x per hour': ' x por hora',
	'Without risk': 'Sin riesgo',
	'Action Level': 'Nivel de acción',
	'Complementary resources': 'Factores complementarios',
	'Percussion tools ': 'Herramientas de percusión',
	'Hit against ': 'Golpes en contra',
	'Present Risk': 'Riesgo presente',
	'Low risk': 'Riesgo bajo',
	'Global Ranking': 'Clasificación mundial',
	'Operator report': 'Reportes del operador',
	'Enter operator report below': 'Ingrese el informe del operador a continuación',
	'Easy to understand information': 'Información fácil de entender',

	'≤ 10 steps per minute': '≤ 10 pasos por minuto',
	'15 to 25 steps per minute': 'De 15 a 25 pasos por minuto',
	'Above 25 steps per minute': 'Por encima de 25 pasos por minuto',

	'Inform below the displacement of the operator during the workday':
		'Informar a continuación el desplazamiento del operador durante la jornada laboral',
	'Regulation notes': 'Notas reglamentarias',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'Se permiten descansos espontáneos durante la jornada laboral (por ejemplo, beber agua, beber café, ir al baño)',
	'In the performance of their functions, there is diversification of tasks':
		'En el desempeño de sus funciones, existe diversificación de tareas',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Los materiales están siempre disponibles en el puesto de trabajo, evitando prisas, demoras o sobrecargas',
	'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)':
		'Intensidad del trabajo: puntúe del 1 al 7 (siendo 1 un poco de trabajo y 7 un trabajo muy intenso)',

	Wrist: 'Muñecas',
	'Hit against': 'Golpes',
	'Enter job information below': 'Ingrese la información del trabajo a continuación',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'Durante la operación, el operador da golpes con las manos para ajustar piezas o componentes',

	'Add a companion video for wrist analysis': 'Agregue un video complementario para el análisis de la muñeca',
	'Do not include in the analysis': 'No incluir en el análisis',
	'Include external video': 'Incluir video externo',
	'Enter video name': 'Introduce el nombre del vídeo',

	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'El mazo y/o los mazos son golpes de golpes duros (excepto ajustes de golpe)',
	'Light - Quiet': 'Ligero - Silencioso',
	'Medium - Some effort is perceived (defined)': 'Medio - Se percibe algún esfuerzo (definido)',
	'Heavy - Clear effort: no change in facial expression':
		'Pesado - Esfuerzo claro: sin cambios en la expresión facial',
	'Very heavy - Sharp effort: changes facial expression': 'Muy pesado Esfuerzo claro: cambia la expresión facial',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'Próximo. máximo - Uso del tronco y las extremidades para generar mayor fuerza',

	// ------- //
	'Workstation Information': 'Informacion del trabajo',

	'Enter operator difficulty control below': 'Ingrese el control de dificultad del operador a continuación',
	'Control Difficulty': 'Dificultad de control',
	'Select a cost center': 'Seleccione el centro de costo',

	'Enter data about vibration in employee activity below':
		'Ingrese a continuación los datos sobre la vibración en la actividad del empleado, si no los tiene, no es necesario llenarlos',

	"Enter below the data on the employee's reaction in the activity":
		'Ingrese a continuación los datos sobre la reacción del empleado a la actividad',
	'Insert a new reaction': 'Introduzca una nueva reacción',
	'Insert a new vibration': 'Entra en una nueva vibración',

	"Enter below the data of the employee's effort in the activity":
		'Ingrese a continuación los datos sobre el esfuerzo del empleado en la actividad',

	'Insert a new effort': 'Introduzca un nuevo esfuerzo',
	'Cost center': 'Introduzca el trabajo',
	'Enter a workstation': 'Introduzca el trabajo',

	'Enter the data related to the selected video below':
		'Ingrese los datos relacionados con el video seleccionado a continuación',

	Staff: 'Staff de colaboradores',
	'Enter the Company Activities': 'Entra en el Codigo Fiscal',
	'Insert a production line': 'Ingrese a la línea de producción',
	'Add new range': 'Agregar nueva banda',
	'industrial site': 'centro de trabajo',
	'Select a industrial site': 'Seleccione el centro de trabajo',
	'Select an industrial site': 'Seleccione el centro de trabajo',
	'Production line': 'Línea de producción',
	'Select a production line': 'Seleccione la línea de producción',

	Role: 'Puesto de trabajo',
	Code: 'Código',

	//
	'Tax Code': 'RUT',
	'Enter a Tax Number': 'Ingresa el RUT',
	'Insert a valid Tax Number': 'Introduzca un RUT válido',

	'Select a role': 'Seleccione un tipo',
	'User type': 'Tipo de usuario',
	'User type:': 'Tipo de usuario:',
	Document: 'Documento',
	'Document:': 'Documento:',
	'Current plan': 'Plan actual',
	'Not informed': 'No informado',

	'Risk ranges': 'Bandas de riesgo',
	'Local application': 'Sitio de aplicación',
	'Units to be analyzed': 'Unidades a analizar',
	'Select the units that will be included in the review': 'Seleccione las unidades que se incluirán en la revisión',
	Factory: 'Fábrica',
	'Select the industrial sites to be analyzed': 'Seleccionar las plantas industriales a analizar',
	'Production Volume': 'Volumen de producción',
	Investments: 'Inversiones',
	'Enter production volumes below': 'Ingrese los volúmenes de producción a continuación',
	'Inform below the investments in ergonomics': 'Informar a continuación las inversiones en ergonomía',

	Date: 'Fecha',
	'Date of reference': 'Fecha de referencia',
	'Date of the report': 'Fecha del informe',

	'Ergonomic analysis of work': 'Análisis ergonómico del trabajo',
	Presentation: 'Presentación',
	'Plant Information': 'Información de la planta',
	'Insert a company name': 'Introduzca el nombre de la empresa',
	'Risk degree': 'Grado de riesgo',
	Activity: 'Actividad',
	District: 'Barrio',
	Administrative: 'Administrativo',
	'Work Shift': 'Turno de trabajo',
	Operational: 'Operacional',
	'Direct hourly worker': 'Directo por hora',
	'Indirect hourly worker': 'Indirecto por hora',
	'Monthly worker': 'Pagador mensual',
	'Normal working hours': 'Horas normales de trabajo',

	// AET
	'Include checklists related to this analysis below':
		'Incluya listas de verificación relacionadas con este análisis a continuación',
	'Non-standard scale': 'Escala no estándar',

	// Bodyparts
	'Lower members': 'Miembros inferiores',
	'Left elbow': 'Codo izquierdo',
	'Right elbow': 'Codo derecho',
	'Left shoulder': 'Hombro izquierdo',
	'Right shoulder': 'Hombro derecho',
	'Left knee': 'Rodilla izquierda',
	'Right knee': 'Rodilla derecha',
	'Left ankle': 'Tobillo izquierdo',
	'Right ankle': 'Tobillo derecho',

	/* Nomenclature v2 */
	'Left hand': 'Mano izquierda',
	'Right hand': 'Mano derecha',
	'Video description': 'Descripción del video',
	Below: 'Abajo de',
	Above: 'Encima de',

	Checklist: 'Lista de verificación',
	'Frequency and duration': 'Frecuencia y duración',

	'Date of collection': 'Fecha de colección',

	'Load from 2 kg ≤ 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg ≤ 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estática o repetitiva)',
	'Load more than 10 kg of repetitive load or blows': 'Carga superior 10 kg de carga repetitiva o golpes',

	Medium: 'Medio',
	Invalid: 'Inválido',

	Safe: 'Aceptable',
	Info: 'Ligero',
	Danger: 'Extremo',

	'Corrective action not required': 'No se requiere acción correctiva',
	'Corrective action as soon as possible': 'Acción correctiva tan pronto como sea posible',
	'High priority corrective action': 'Acción correctiva de alta prioridad',
	'Immediate corrective action': 'Acción correctiva inmediata',
	'Unable to show results': 'No se pueden mostrar los resultados',

	/* Nomenclature */
	Line: 'Línea',
	Effort: 'Esfuerzo',
	Reaction: 'Reacción',
	Vibration: 'Vibración',
	'Beats against': 'Golpes en contra',
	Percussion: 'Percusión',
	Hands: 'Manos',
	'Shoulders/Arms': 'Hombros/brazos',
	Left: 'Izquierda',
	Right: 'Derecha',
	Exit: 'Salir',
	Filters: 'Filtros',

	/* Login - Page */
	// Placeholder's
	'E-mail': 'Correo electrónico',
	'E-mail:': 'Correo electrónico:',

	// Validation's errors inputs
	'Enter a valid e-mail': 'Introduzca un correo electrónico válido',
	'Enter your password': 'Ingresa tu contraseña',
	Login: 'Acceso',

	// SideMenu
	Dashboard: 'Tablero',
	Upload: 'Subir',
	EAW: 'AET',

	// Dashboard
	'Comparison over the last 6 months': 'Comparación en los últimos 6 meses',
	/*************/

	'Are you sure?': '¿Está seguro?',
	'You are being redirected': 'Usted está siendo redirigido',
	'Are you sure you want to quit? You have an upload in process.':
		'¿Seguro que quieres salir? Tienes una carga en proceso',

	// Upload filter
	'Select a company': 'Seleccione una empresa',
	'Select a line': 'Seleccione la línea',
	'Select the workstation': 'Seleccione el puesto de trabajo',
	'Select a workstation': 'Seleccione el puesto de trabajo',

	// Chart
	'No data available': 'No hay datos',
	'There is no registered data.': 'No hay datos registrados',

	// Layout
	Logout: 'Salir',

	// Report
	'Add item': 'Añadir artículo',
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'Valores aproximados, en función de la técnica de filmación adoptada. Si no tiene datos, el algoritmo no ha identificado los puntos para determinar el ángulo, observe las técnicas de filmación',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'Los resultados obtenidos de cada parte del cuerpo pueden verse afectados por el método y la posición de filmación, la identificación de la persona en el análisis puede sufrir interferencias de los objetos presentes. Los datos de ángulo que se muestran son valores aproximados.',
	Reference: 'Referencia',
	'Investigate and change immediately': 'Investigue y cambie inmediatamente',
	'Analysis of the movement by angle': 'Análisis de movimiento por ángulo',
	'Analysis of the movement by score': 'Análisis de movimiento con puntuación',
	'Analysis of the movement & Body parts': 'Análisis de movimiento y partes del cuerpo',
	'Report types': 'Tipos de Reportes',
	'Generate reports': 'Generar Reportes',
	Finish: 'Finalizar',
	'Description already exists!': '¡La descripción ya existe!',
	'Enter a new sector': 'Entrar en un nuevo sector',
	'Do you wish to delete this sector?': '¿Quieres eliminar este sector?',
	'This sector is used in other reports, do you really wish to delete it?':
		'Este sector se está utilizando en otros Reportes, ¿está seguro de que desea eliminarlo?',
	'Sector created successfully!': '¡Sector creado con éxito!',
	'Line created successfully!': 'Línea creada con éxito!',
	'Workstation created successfully!': 'Puesto de trabajo creado con éxito!',
	"This operation couldn't be completed": 'Esta acción no se pudo realizar',
	Sectors: 'Sectores',
	'Fill in the essential information below.': 'Complete la información esencial a continuación',
	'Select the sector from the above analysis': 'Seleccione el sector del análisis anterior',
	'Select the sector for the analysis': 'Seleccione el sector de análisis',
	Add: 'Agregar',
	'Enter the workstation': 'Introduzca el trabajo',
	'Enter the date the collection was made': 'Ingrese la fecha en que se realizó el cobro',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'Seleccione la herramienta que desea aplicar para el análisis y el tipo de informe que desea generar',
	Tool: 'Herramienta',
	'Pick the tool above': 'Seleccione la herramienta de arriba',
	'Select the type of tool': 'Seleccione el tipo de herramienta',
	Report: 'Reporte',
	'Select the type of report above': 'Seleccione el tipo de reporte anterior',
	'Select the type of report': 'Seleccione el tipo de reporte',

	// BriefBest
	'Shoulder movement': 'Movimientos de hombros',
	'Flexion greater than 20° degrees': 'Flexión superior a 20º grados',
	'Executed with support': 'Ejecución asistida',
	Rotation: 'Rotación',
	'Force greater or equal to 11.3kg': 'Fuerza mayor o igual a 11.3kg',
	'Forearm movement': 'Movimientos del antebrazo',
	'Greater or equal to 40°': 'Mayor o igual a 40º',
	'Full extension': 'Extensión completa',
	'Force greater or equal to 4.5kg': 'Fuerza mayor o igual a 4.5kg',
	'Movement of hands and wrist': 'Movimientos de manos y muñecas',
	'Ulnar deviation': 'Desviación cubital',
	'Radial deviation': 'Desviación radial',
	'[Force]: Pinch or pressure with the fingers greater or equal to 0.9kg, or palm pressure greater or equal to 4.5kg.':
		'[Fuerza] Pellizco o presión con los dedos mayor o igual a 0,9 kg, o presión con la palma mayor o igual a 4,5 kg',
	'[Duration]: Greater or equal to 10 seconds.': '[Duración]: Mayor o igual a 10 segundos',
	'[Frequency]: Greater or equal to 30 minutes.': '[Frecuencia]: Mayor o igual a 30 minutos',
	'Leg movement': 'Movimientos de piernas',
	Squatting: 'En cuclillas',
	'With support': 'Con soporte',
	'Foot pedal greater or equal to 4.5kg': 'Pedal mayor o igual a 4,5 kg',
	'Neck movement': 'Movimientos del cuello',
	'Flexion greater than 30°': 'Flexión superior a 30° grados',
	'Side movement': 'Movimiento lateral',
	Extended: 'Extendido',
	'Rotation greater than 20°': 'Rotación superior a 20° grados',
	'Force greater or equal to 0.9kg': 'Fuerza mayor o igual a 0,9 kg',
	'Arm behind the body': 'Brazo detrás del cuerpo',
	'Shoulders tucked in': 'Hombros encogidos',
	Elbows: 'Codos',
	Ombros: 'Espalda',
	'Back ': 'Atrás', // Coloquei um espaço depois de "Back" para diferenciar de "Voltar"
	"Data couldn't be loaded.": 'No puede cargar',
	'Body parts - Critical:': 'Parte del cuerpo - opiniones:',
	Indicators: 'Indicadores',
	'Glove usage': 'Uso de guantes',
	'Impact exposure': 'Exposición e impacto',
	'Vibration exposure': 'Exposición a vibraciones',
	'Low temperature exposure': 'Exposición a bajas temperaturas',
	'Mechanical compression of muscular tissue': 'Compresión mecánica del tejido muscular',
	'More than 40 hours': 'Más de 40 horas',
	'20 - 40 hours': '20 - 40 horas',
	'4 - 19 hours': '4 - 19 horas',
	'Less than 4 hours': 'Menor que 4 horas',
	Video: 'Video',
	'Exposure time': 'Tiempo de exposición',
	'Tool used': 'Herramienta utilizada',
	'Physical stress factors': 'Factores de estrés físico ',
	"Data couldn't be loaded": 'No se pudieron obtener los datos',
	'Very high': 'Muy alto',
	'Final score:': 'Puntuación final:',
	'(Task risk x Exposure time factor)': '(Riesgo de la tarea x factor de tiempo de exposición)',
	'Priority:': 'Prioridad:',
	'Subtitle:': 'Subtítulos:',
	Graphs: 'Gráficos',
	'Not identified': 'No identificado',
	Comments: 'Comentarios',
	'Comments:': 'Comentarios:',
	'Enter the analysis conclusion': 'Ingrese la conclusión del análisis aquí',
	'Complementary factors': 'Factores complementarios',
	'Physical stress': 'Estrés físico:',
	'Inadequate use of gloves': 'Uso inadecuado de guantes',
	'Weekly exposure time:': 'Tiempo de exposición semanal:',
	'Physical factors': 'Factores físicos',
	Filter: 'Filtrar',
	Company: 'Empresa',
	'Select the company': 'Seleccione la empresa',
	'Select the industrial site': 'Seleccione el centro de trabajo',
	Function: 'Ocupación',
	'Enter the function': 'Introduzca la función',
	'Customized reports': 'Reportes personalizados',

	// Settings
	"We weren't able to change your password": 'No se puede cambiar su contraseña',
	'Warning!': '¡Aviso!',
	'Failed to fetch requested information': 'No se pudo obtener la información solicitada',
	'Do you really wish to cancel your plan?': '¿De verdad quieres cancelar tu plan?',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'Al cancelar tu plan, después de la fecha de vencimiento actual no tendrás acceso a la plataforma',
	"We weren't able to cancel your plan": 'No se puede cancelar el plan',
	"Select a plan that's different from your current one": 'Seleccione un plan diferente a su plan actual',
	"We couldn't process the operation": 'No se puede realizar la operación',
	'Plan cancelled on:': 'Plan cancelado en:',
	'Active users:': 'Usuarios activos:',
	'Plan switch': 'Cambio de planes',
	'You have recently changed your plan': 'Recientemente cambió su suscripción',
	'Plan type:': 'Tipo de suscripción:',
	'New plan start date:': 'Fecha de inicio del nuevo plan:',
	'User limit:': 'Límite de usuarios:',
	'Upload limit:': 'Límite de carga:',
	'Processing time limit:': 'Límite de minutos de procesamiento:',
	'Cancel plan': 'Cancelar plan',
	'Error when trying to verify industrial sites from company.':
		'Error al verificar plantas industriales dentro de la empresa',
	'Error when trying to delete the company': 'Error al eliminar empresa',
	'Enter a permission': 'Introduzca un permiso',
	'Select a permission': 'Seleccione un permiso',
	'Edit user': 'Editar usuario',
	'User changed successfully': 'Usuario cambiado con éxito',
	Permission: 'Permiso',
	'Do you wish to delete this user?': '¿Quieres eliminar este usuario?',
	Active: 'Activo',
	Inactive: 'Inactivo',
	All: 'Todos',
	Disabled: 'Desactivado',

	// Manage companies
	'Manage companies': 'Administrar empresas',
	'Manage industrial sites': 'Administrar plantas industriales',
	'Heads up! This company is not empty': '¡Aviso! Esta empresa no está vacía',
	'To continue, first remove industrial sites belonging to this company.':
		'Para continuar, eliminar las plantas industriales pertenecientes a esta empresa',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Se eliminarán todas las empresas, junto con sus videos e Reportes. ¿Estas seguro que deseas continuar?',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Se eliminarán todas las plantas industriales de esta empresa, junto con sus cargas e Reportes. ¿Estas seguro que deseas continuar?',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'Se eliminarán todas las cargas e Reportes de este centro de trabajo. Esta acción no se puede deshacer. ¿Estas seguro que deseas continuar?',
	'Failed to update information': 'No se pudo actualizar la información',
	'Edit company': 'Editar empresa',
	Loading: 'Cargando',
	'Company name': 'Nombre de la empresa',
	'Industrial sites': 'Centros de trabajo',
	'Total Industrial Sites': 'Total de centros de trabajo',
	'Manage industrial site': 'Administrar centro de trabajo',
	'Update company name': 'Actualizar nombre de la empresa',
	'Delete company': 'Eliminar empresa',
	'Organization created successfully': 'Empresa creado con éxito.',
	'Organization deleted successfully': 'Empresa eliminada con éxito.',
	'Updated information': 'Información actualizada',
	'Error while changing the name of the industrial site': 'Error al editar el nombre del centro de trabajo',

	// ui/Reports
	'1 - Acceptable posture if not repeated or kept for long periods':
		'1 - Postura aceptable si no se repite o mantiene durante largos periodos',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'2 - Investigar posibilidad de solicitar cambios es conveniente introducir cambios',
	'3 - Investigate; make changes quickly': '3 - Investigar hacer cambios rápidamente',
	'4 - Immediate change': '4 - Cambios inmediatos',
	'Acceptable posture if not repeated or kept for long periods':
		'Postura aceptable si no se repite o se mantiene durante períodos prolongados',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'Investigar posibilidad de solicitar cambios es conveniente introducir cambios',
	'Investigate; make changes quickly': 'Investigar hacer cambios rápidamente',
	'Immediate change': 'Cambios inmediatos',
	Ergonomist: 'Ergonomista',
	'Left lower arm': 'Antebrazo izquierdo',
	'Right lower arm': 'Antebrazo derecho',
	'Left upper arm': 'Brazo izquierdo',
	'Right upper arm': 'Brazo derecho',
	Type: 'Escribe',

	// views/Report/Niosh
	'Enter the job characteristics below.': 'Ingrese las características del trabajo.',
	'Gender of the worker': 'Género del trabajador',
	'Select the gender': 'Seleccione el género',
	'Select the age': 'Seleccione la edad',
	Age: 'Años',
	'How many workers are lifting the object?': '¿Cuántos trabajadores están levantando el objeto?',
	'Which hands are used?': '¿Qué manos se utilizan?',
	One: 'Uno',
	Two: 'Dos',
	Three: 'Tres',
	'One hand': 'Una mano',
	'Both hands': 'Ambas manos',
	'Manual Lifting Report': 'Informe - Levantamiento Manual',
	Conclusions: 'Conclusiones',
	'The weight to be lifted': 'El peso a levantar',
	'is greater than the recommended weight limit (RWL)': 'es mayor que el límite de masa recomendado (RML)',
	'is less than the recommended weight limit (RWL)': 'es inferior que el límite de masa recomendado (RML)',
	' The lifting index (LI)': ' El índice de levantamiento (IL)',
	'is more than 3.': 'es más de 3.',
	'is more than 2 and less than 3.': 'esta entre 2 y 3.',
	'is more than 1,5 and less than 2.': 'esta entre 1,5 y 2.',
	'is more than 1 and less than 1,5.': 'esta entre 1 y 1,5.',
	'is less than 1.': 'es menor que 1.',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Hay un riesgo muy alto. Estos valores indican que este levantamiento sería peligroso para la mayoría de los trabajadores industriales sanos.',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		' Hay un alto riesgo. Estos valores indican que este levantamiento sería peligroso para la mayoría de los trabajadores industriales sanos.',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		' Hay un riesgo moderado. Estos valores indican que este trabajo es ligeramente estresante.',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		' Hay un riesgo bajo. Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales, pero algunos pueden tener problemas.',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		' Hay un riesgo muy bajo. Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales.',
	'These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		'Estos valores indican que este levantamiento sería peligroso para la mayoría de los trabajadores industriales sanos.',
	'These values indicate that this job is somewhat stressful.':
		'Estos valores indican que este trabajo es ligeramente estresante.',
	'These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		'Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales, pero algunos pueden tener problemas.',
	'These values indicate that this job is adequate for the majority of industrial workers.':
		'Estos valores indican que este trabajo es adecuado para la mayoría de los trabajadores industriales.',
	Man: 'Hombre',
	Woman: 'Mujer',
	'20 to 45 years old': '20 a 45 años',
	'< 20 years old': '< 20 años',
	'> 45 years old': '> 45 años',
	'Download PDF': 'Descargar PDF',
	'The most critical variables are listed below with suggestions:':
		'Las variables más críticas se enumeran a continuación con sugerencias:',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'Acerque la carga al trabajador eliminando cualquier barrera horizontal o reduciendo el tamaño del objeto. Deben evitarse levantamientos cerca del suelo.',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'Eleve el origen o baje el objetivo de elevación. Evite levantamientos cerca del piso o por encima de los hombros.',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'Reducir la distancia vertical entre el origen y el destino del levatamiento.',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'Acerque el origen y el destino para reducir el ángulo de giro, o sepárelos más para obligar al trabajador a girar los pies y dar un paso, en lugar de torcer el cuerpo.',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'Reduzca la tasa de frecuencia de levantamiento, reduzca la duración del levantamiento o proporcione períodos de recuperación más prolongados (período de trabajo liviano).',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'Mejore el acoplamiento mano-objeto proporcionando recipientes óptimos con asas o recortes para asideros, o mejore los asideros para objetos irregulares.',
	'Consider using both hands when lifting the weight.': 'Considere usar ambas manos al levantar el peso.',
	'One handed': 'Con una sola mano',
	'Distance H': 'Distancia H',
	'Distance Vc': 'Distancia Vc',
	'Distance Dc': 'Distancia Dc',
	'Angle A': 'Ángulo A',
	'Frequency/Duration': 'Frecuencia/Duración',
	Coupling: 'Agarre',
	Frequency: 'Frecuencia',
	Conclusion: 'Conclusión',
	'Coupling factor': 'Calidad de agarre',
	'Frequency factor': 'Factor de frecuencia',
	Poor: 'Malo',
	Fair: 'Regular',
	Good: 'Bueno',
	'1 hour': '1 hora',
	'1 - 2 hours': '1 a 2 horas',
	'2 - 8 hours': '2 a 8 horas',
	'Weight of the object': 'Peso del objeto',
	'Distance the object is moved vertically': 'Distancia a la que se mueve el objeto verticalmente',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'Distancia vertical del objeto al inicio de la manipulación, con respecto al suelo',
	'Horizontal distance of the object relative to the body': 'Distancia horizontal del objeto con respecto al cuerpo.',
	'Rotation angle': 'Ángulo de rotación',
	'Recommended Weight Limit (RWL)': 'Límite de masa recomendado (LPR)',
	'Lifting Index (LI)': 'Índice de levantamiento (IL)',
	'lifts / min': 'levant. / min',
	'Report date': 'Fecha del informe',
	'Enter the required values': 'Ingrese los valores requeridos',
	'Comment:': 'Comentario:',
	Details: 'Detalles',
	Risk: 'Riesgo',
	'Very low risk': 'Riesgo muy bajo',
	Measurements: 'Mediciones',
	'Lifted by': 'Levantado por',
	'Lifted by ': 'Levantado por ',
	'1 worker': '1 trabajador',
	'2 workers': '2 trabajadores',
	'3 workers': '3 trabajadores',
	Using: 'Usando',
	'only one hand': 'una sola mano',
	'one hand': 'una mano',
	'both hands': 'ambas manos',
	'Reference weight': 'Peso de referencia',
	'Select the coupling quality': 'Seleccione la calidad de agarre',
	'Select the lifting frequency': 'Seleccione la frecuencia de levantamiento',
	'Select the lifting duration': 'Seleccione la duración de levantamiento',
	'Lifting frequency (lifts per minute)': 'Frecuencia de levantamiento (levant./min)',
	'Enter the task variables below.': 'Introduzca las variables de la tarea a continuación.',
	'Please enter a value': 'Introduzca un valor',
	'Coupling factor: ': 'Factor de agarre: ',
	'Frequency factor: ': 'Factor frecuencia: ',
	Characteristics: 'Características',
	Variables: 'Variables',
	Result: 'Resultado',
	'Report created successfully': 'Informe creado con éxito',
	'Comment updated successfully': 'Comentario actualizado con éxito',
	'Document created successfully': 'Documento creado con éxito',

	// STRAIN INDEX REPORT
	'Report Results - Revised Strain Index': 'Informe de resultados - Strain Index Revisado',
	'Intensity of exertion': 'Intensidad de esfuerzo',
	'Efforts per minute': 'Esfuerzos por minuto',
	'Number of exertions': 'Número de esfuerzos',
	'Observation time': 'Tiempo de observación',
	'Duration per exertion': 'Duración por esfuerzo',
	'Hand/wrist posture': 'Postura de mano/muñeca',
	'Type of wrist posture': 'Tipo de postura de la muñeca',
	'Hand/wrist angle': 'Ángulo de mano/muñeca',
	'Duration of task per day': 'Duración de la tarea por día',
	'Revised Strain Index (RSI)': 'Strain Index Revisado (RSI)',
	'Risk factor': 'Factor de riesgo',
	Input: 'Entrada',
	Score: 'Score',
	'Job is probably safe': 'El trabajo es probablemente seguro',
	'Job is probably hazardous': 'El trabajo es probablemente peligroso',
	Degrees: 'Grados',
	'Duration/day': 'Duración/día',
	'Intensity of exertion (Borg Scale - BS)': 'Intensidad de esfuerzo (Borg Scale - BS)',
	Light: 'Ligero',
	'Somewhat Hard': 'Poco Difícil',
	Hard: 'Difícil',
	'Very Hard': 'Muy Difícil',
	'Near Maximal': 'Casi Maxima',
	'Risk factors 1': 'Factores de riesgo 1',
	'Risk factors 2': 'Factores de riesgo 2',
	'Barely noticeable or relaxed effort (BS: 0-2)': 'Esfuerzo casi imperceptible o relajado (BS: 0-2)',
	'Noticeable or definite effort (BS: 3)': 'Esfuerzo perceptible o definido (BS: 3)',
	'Obvious effort; Unchanged facial expression (BS: 4-5)': 'Esfuerzo obvio; Expresión facial sin cambios (BS: 4-5)',
	'Substantial effort; Changes expression (BS: 6-7)': 'Esfuerzo sustancial; Cambio de expresión (BS: 6-7)',
	'Uses shoulder or trunk for force (BS: 8-10)': 'Usa el hombro o el tronco para la fuerza (BS: 8-10)',
	'Number of exertions observed': 'Número de esfuerzos observados',
	'Total observation time': 'Tiempo total de observación',
	'Average single exertion time': 'Tiempo medio de esfuerzo',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'(esfuerzos totales * tiempo de esfuerzo) debe ser inferior o igual al tiempo total de observación',
	seconds: 'segundos',
	hours: 'horas',
	Extension: 'Extensión',
	Flexion: 'Flexión',
	'Revised Strain Index (Moore and Garg)': 'Strain Index Revisado (Moore y Garg)',
	'Report Results': 'Informe de resultados',
	'Revised Strain Index': 'Strain Index Revisado',
	'(Moore and Garg)': '(Moore y Garg)',

	// REBA
	'REBA Report Results': 'Informe de resultados REBA',
	'Puntaje REBA del lugar de trabajo': 'Pontuação REBA do posto de trabalho',
	'Left leg': 'Pierna izquierda',
	'Right leg': 'Pierna derecha',
	'Load less than 5kg (intermittent)': 'Carga inferior a 5 kg (intermitente)',
	'Load from 5kg to 20kg (intermittent)': 'Carga de 5kg a 20kg (intermitente)',
	'Load from 5kg to 20kg (static or repetitive)': 'Carga de 5 kg a 20 kg (estática o repetitiva)',
	'Bigger load 20kg of repetitive load or blows': 'Carga superior a 20 kg de repeticiones o golpes',
	Unacceptable: 'Inaceptable',
	'Repetitions per minute': 'Repeticiones por minuto',
	Force: 'Fuerza',
	Load: 'Carga',
	'Select the load': 'Seleccione la carga',
	'Select the number of repetitions': 'Seleccione el número de repeticiones',
	'Score left': 'Score izquierda',
	'Score right': 'Score derecha',
	Acceptable: 'Aceptable',
	Investigate: 'Investigar',
	'Investigate and change soon': 'Investiga y cambia pronto',
	'Investigate and change now': 'Investigue y cambie inmediatamente',
	'REBA Score': 'Score REBA',
	'The 4 most critical body parts': 'Las 4 partes del cuerpo más críticas',
	'Viewing the selected body part': 'Visualización de la parte seleccionada',
	minute: 'minuto',
	'Requested video data is old, please upload to continue':
		'Los datos de video solicitados son antiguos, súbalos para continuar',
	'View score': 'Ver puntuación',
	'Unable to view': 'No se puede ver',
	'Worst grade found in time': 'Peor score encontrado en tiempo',

	// Angle time
	'Angle by time': 'Ángulo en el Tiempo',
	'Create risk range': 'Crear rango de riesgo',
	'Report Results - Angle by Time': 'Informe de resultados - Ángulo en el Tiempo',
	'Please fill all required fields': 'Por favor llene todos los campos requeridos',

	// ACTION PLANS
	'Action plans': 'Plan de acción',
	'Do you want to delete this action plan?': '¿Desea eliminar este plan de acción?',
	'Action plan created successfully': 'Plan de acción creado con éxito',
	'Create action plan': 'Crear plan de acción',
	'Please enter a valid description': 'Por favor ingrese una descripción válida',
	'Action plan description': 'Descripción del plan de acción',
	'Please enter a valid action plan description': 'Ingrese una descripción válida del plan de acción',
	'Person responsible': 'Responsable',
	Responsible: 'Responsable',
	'Who is responsible for this action plan?': '¿Quién es el responsable de este plan de acción?',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'Describa la situación encontrada y las medidas preventivas ya implementadas (si existen)',
	Deadline: 'Prazo',
	'Finish editing your other task first': 'Primero termine de editar la otra tarea',
	'Add a new task': 'Agregar una nueva tarea',
	History: 'Histórico',
	'Finish by: ': 'Terminar por: ',
	'On time': 'En el plazo',
	Overdue: 'Tarde',
	'Report type': 'Tipo de informe',
	'Select a report type': 'Seleccione o tipo de informe',
	'Finishing in a week': 'Terminando en una semana',
	'Person responsible updated': 'Responsable del plan de acción actualizado',
	'Deadline updated': 'Fecha límite del plan de acción actualizado',
	Tasks: 'Tareas',
	'Task created': 'Tarea creada',
	'Task deleted': 'Tarea eliminada',
	'Task renamed': 'Tarea renombrada',
	'Task type changed': 'Tipo de tarea cambiado',
	'Task and type changed': 'Tarea y tipo cambiados',
	'Task checked': 'Tarea terminada',
	'Task unchecked': 'Tarea deshecha',
	'Action plan created': 'Plan de acción creado',
	'Action plan renamed': 'Plan de acción renombrado',
	'Status changed': 'Estado cambiado',
	'Deadline changed': 'Término cambiado',
	'by ': 'por ',
	'Successfully updated action plan data': 'Datos del plan de acción actualizados con éxito',
	'Responsible user changed': 'Cambio de responsable',
	'Action plan title': 'Título del plan de acción',
	Title: 'Título',
	'Select a file': 'Seleccione un archivo',
	'Please enter a valid title': 'Por favor ingrese un título válido',
	'Please enter a responsible': 'Por favor ingrese un responsable',
	'Please enter a valid date': 'Por favor introduzca una fecha valida',
	'Search company': 'Buscar empresa',
	'Search industrial site': 'Buscar centro de trabajo',
	'Enter a valid email': 'Introduzca un correo electrónico válido',
	'Select a condition': 'Selecione uma condição',
	Eliminate: 'Eliminar',
	Control: 'Controlar',
	Compensate: 'Compensar',
	ELIMINATE: 'ELIMINAR',
	CONTROL: 'CONTROLAR',
	COMPENSATE: 'COMPENSAR',

	// KIM - MHO
	'Error, cannot get "': 'Error, no se puede obtener "',
	'" from "': '" de "',
	'Total duration of this sub-activity per working day': 'Duración total de la actividad por día trabajado',
	'Type of force exertion in the finger/hand area within a “standard minute”':
		'Fuerza ejercida sobre el área de la mano/dedo en un "minuto estándar"',
	'Force transfer / gripping conditions': 'Transferencia de fuerza/condiciones de agarre',
	'Hand/arm position and movement': 'Posición y movimiento de manos/brazos',
	'Unfavourable working conditions': 'Condiciones laborales desfavorables',
	'Work organization / temporal distribution': 'Organización del trabajo / distribución del tiempo',
	'Body posture/movement': 'Postura y movimiento del cuerpo',
	'Very low': 'Muy baja',
	Moderate: 'Moderada',
	'Peak forces': 'Fuerzas máximas',
	'Powerful hitting': 'Golpes poderosos',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'Hasta 15% Fmáx. Accionamiento de botones, desplazamiento, clasificación, orientación de materiales, inserción de piezas pequeñas.',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'Hasta 30% Fmáx. Agarrar o unir piezas pequeñas a mano o con herramientas pequeñas.',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'Hasta 50% Fmáx. Torneado, laminado, empaquetado, agarre, sujeción o unión de piezas, prensado, corte, trabajo con pequeñas herramientas manuales motorizadas.',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'Hasta 80% Fmáx. Cortar que involucre un gran elemento de fuerza, trabajar con pistolas grapadoras pequeñas, mover o sujetar piezas o herramientas.',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'Más del 80% Fmáx. Apretar, aflojar tornillos, separar, prensar.',
	'Hitting with the ball of the thumb, palm of the hand or fist.':
		'Golpear con la punta del pulgar, la palma o el puño.',
	'Optimum force transfer/application': 'Transferencia/aplicación de fuerza óptima',
	'Restricted force transfer/application': 'Transferencia/aplicación de fuerza restringida',
	'Force transfer/application considerably hindered':
		'Transferencia/aplicación de fuerza considerablemente deteriorada',
	'Optimum force application': 'Aplicación de fuerza óptima',
	'Restricted force application': 'Aplicación de fuerza restringida',
	'Force application considerably hindered': 'Aplicación de fuerza considerablemente deteriorada',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'Los objetos de trabajo son fáciles de agarrar (p. ej., en forma de barra, ranuras de agarre) / buen diseño de agarre ergonómico (asas, botones, herramientas).',
	'Greater holding forces required / no shaped grips.':
		'Se requieren mayores fuerzas de agarre / empuñaduras sin forma ergonómica.',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'Objetos de trabajo difíciles de agarrar (bordes resbaladizos, blandos, afilados) / empuñaduras inadecuadas solamente o ninguna.',
	Restricted: 'Restringido',
	Unfavourable: 'Desfavorable',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'Posición articular o movimientos en rango medio (relajado), solo desviaciones raras / no es posible una postura estática continua del brazo / apoyo mano-brazo según sea necesario.',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'Posiciones o movimientos articulares ocasionales en el límite de los rangos de movimiento, ocasionalmente postura estática, larga y continua del brazo.',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'Posiciones o movimientos articulares a menudo en el límite de los rangos de movimiento, postura del brazo a menudo estática, larga y continua.',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'Posiciones o movimientos articulares constantemente en el límite de los rangos de movimiento, postura del brazo constantemente estática, prolongada y continua.',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variación frecuente en la situación de la carga de trabajo física debido a otras actividades (incluidos otros tipos de carga de trabajo física) / sin una secuencia estricta de cargas de trabajo físicas más altas dentro de un tipo de carga de trabajo física durante un solo día de trabajo.',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'Variación rara de la situación de la carga de trabajo físico debido a otras actividades (incluidos otros tipos de carga de trabajo físico) / secuencia restringida ocasional de cargas de trabajo físico más altas dentro de un tipo de carga de trabajo físico durante un solo día de trabajo.',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'Ninguna/casi ninguna variación de la situación de la carga de trabajo físico debido a otras actividades (incluidos otros tipos de carga de trabajo físico)/secuencia estrecha frecuente de cargas de trabajo físicas más altas dentro de un tipo de carga de trabajo físico durante un solo día de trabajo con altos picos de carga simultáneos.',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'Sin condiciones de trabajo desfavorables, es decir, reconocimiento de detalles confiable / sin deslumbramiento / buenas condiciones climáticas.',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'Reconocimiento de detalles ocasionalmente deteriorado debido al deslumbramiento o detalles excesivamente pequeños en condiciones difíciles como corrientes de aire, frío, humedad y/o concentración perturbada debido al ruido.',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'El reconocimiento de detalles a menudo se ve afectado por el deslumbramiento o por detalles demasiado pequeños, a menudo en condiciones difíciles como corrientes de aire, frío, humedad y/o concentración perturbada debido al ruido.',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'Cambiar entre estar sentado y de pie, cambiar entre estar de pie y caminar, es posible sentarse de forma dinámica',
	'Trunk inclined forward only very slightly': 'Tronco ligeramente inclinado hacia delante',
	'No twisting and/or lateral inclination of the trunk identifiable':
		'Sin torsión del tronco identificable y/o flexión lateral',
	'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving':
		'Postura de la cabeza: Variable, cabeza no inclinada hacia atrás y/o muy inclinada hacia adelante o en constante movimiento',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'Sin agarre por encima de la altura del hombro/Sin agarre a la distancia del cuerpo',
	'Predominantly sitting or standing with occasional walking':
		'Predominantemente sentado o de pie con caminata ocasional',
	'Trunk with slight inclination of the body towards the work area':
		'Tronco con ligera inclinación del cuerpo hacia la zona de trabajo',
	'Occasional twisting and/or lateral inclination of the trunk identifiable':
		'Torsión ocasional y/o flexión del tronco identificable lateralmente',
	'Occasional deviations from good “neutral” head posture/movement':
		'Desviaciones ocasionales de una buena postura/movimiento neutral de la cabeza',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'Convulsiones ocasionales por encima de la altura del hombro / convulsiones ocasionales a distancia del cuerpo',
	'Exclusively standing or sitting without walking': 'Exclusivamente de pie o sentado sin caminar',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'Tronco claramente inclinado hacia adelante y/o torsión frecuente y/o inclinación lateral del tronco identificable',
	'Frequent deviations from good “neutral” head posture/movement':
		'Desviaciones frecuentes de una buena postura/movimiento neutral de la cabeza',
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'Postura de la cabeza inclinada hacia adelante para reconocimiento de detalles/libertad de movimiento restringida',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'Agarre frecuente por encima de la altura del hombro/agarre frecuente lejos del cuerpo',
	'Trunk severely inclined forward / frequent or long-lasting bending':
		'Tronco muy inclinado hacia adelante/flexión frecuente o prolongada',
	'Work being carried out in a kneeling, squatting, lying position':
		'Trabajo realizado en posición de rodillas, agachado y acostado',
	'Constant twisting and/or lateral inclination of the trunk identifiable':
		'Torsión constante y/o inclinación lateral del tronco identificable',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'Postura corporal estrictamente fija/verificación visual de la acción a través de lupas o microscopios',
	'Constant deviations from good “neutral” head posture/movement':
		'Desviaciones constantes de una buena postura/movimiento neutral de la cabeza',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'Agarre constante por encima de la altura del hombro/Agarre constante a distancia del cuerpo',
	'Manual Handling': 'Manejo Manual',
	'Manual Handling Operations': 'Operaciones de Manejo Manual',
	'KIM - Manual Handling Operations': 'KIM - Operaciones de Manejo Manual',
	'Total duration per working day': 'Duración total por día trabajado',
	'Type of force exertion in the finger/hand': 'Fuerza ejercida en el área de la mano/dedo',

	'Possible health consequences': 'Posibles consecuencias para la salud',
	Measures: 'Medidas',
	'Physical overload is unlikely.': 'La sobrecarga física no es probable.',
	'No health risk is to be expected.': 'No se esperan riesgos para la salud.',
	'None.': 'Ninguno.',
	'Physical overload is possible for less resilient persons.':
		'La sobrecarga física es posible para las personas menos resilientes.',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'Fatiga, problemas de ajuste de bajo grado que se pueden compensar durante el tiempo libre.',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'Para las personas menos resilientes, los cambios en el lugar de trabajo y otras medidas preventivas pueden ayudar.',
	'Physical overload is possible for normally resilient persons.':
		'La sobrecarga física es posible para las personas normalmente resilientes.',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'Trastornos (dolor), posiblemente incluyendo disfunciones, reversibles en la mayoría de los casos, sin manifestación morfológica.',
	'Workplace redesign and other prevention measures should be considered.':
		'Se deben considerar cambios en el lugar de trabajo y otras medidas preventivas.',
	'Physical overload is likely.': 'La sobrecarga física es probable.',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'Trastornos y/o disfunciones más pronunciados, daño estructural con significado patológico.',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'Los cambios en el lugar de trabajo son necesarios, se deben considerar otras medidas preventivas.',
	'Slightly increased': 'Ligeramente aumentada',
	'Substantially increased': 'Incrementado sustancialmente',
	'Type of force exertion in the finger/hand area': 'Tipo de esfuerzo realizado en el área de la mano/dedo',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'Fuerza ejercida sobre el área de la mano/Intensidad de la fuerza ejercida sobre el área de la mano/dedos en un "minuto estándar"',
	Holding: 'Sosteniendo',
	Moving: 'Moviendo',
	'average (seconds holding / minute)': 'promedio (segundos sosteniendo / min.)',
	'average (movements / minute)': 'promedio (movimientos / min.)',
	'Left arm': 'Brazo izquierdo',
	'Right arm': 'Brazo derecho',

	'Time and force': 'Tiempo y fuerza',
	Conditions: 'Condiciones',
	Posture: 'Postura',

	'Select the body parts you are interested in': 'Selecciona las partes del cuerpo que te interesan',
	'Select all': 'Seleccionar todo',

	'Shoulder L': 'Hombro E',
	'Shoulder R': 'Hombro D',
	'Elbow L': 'Codo E',
	'Elbow R': 'Codo D',
	'Leg L': 'Pierna E',
	'Leg R': 'Pierna D',

	Cycle: 'Ciclo',
	'Select the cycle': 'Seleccione el ciclo',
	'Select one of the vehicles or a wheel type:': 'Seleccione uno de los vehículos o un tipo de rueda',
	Conveyors: 'Transportadores',
	Cranes: 'Grúas',
	'Driveway conditions:': 'Condiciones de la calzada:',
	'Select the mass to be transported (in kg):': 'Seleccione la masa a transportar (en kg):',
	'Inclination or stairs:': 'Pendiente o escaleras:',
	'Conditions:': 'Condiciones:',

	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': 'Mayor velocidad de movimiento (aprox. 1,0 hasta 1,3 m/s)s',

	'Cannot change a consolidated analysis': 'No se puede cambiar un análisis consolidado',
	'Comments successfully saved': 'Comentarios guardados correctamente',

	'Action plan deleted successfully': 'Plan de acción eliminado con éxito',
	'Task created successfully': 'Tarea creada con éxito',
	'Task deleted successfully': 'Tarea eliminada con éxito',
	'Task updated successfully': 'Tarea actualizada con éxito',

	'Ops... something happened!': 'Ups... ¡algo pasó!',
	'It was not possible to save your information': 'No se puede guardar su información',
	'No task available': 'No hay tareas disponibles',
	day: 'día',
	days: 'días',

	// Super PEA File History
	'Selected Files': 'Archivos seleccionados',
	'Worst score': 'Peor puntuación',
	'Open File List': 'Abrir lista de archivos',
	'EWA deleted successfully': 'AEP eliminado con éxito.',

	//Others words
	Likelihood: 'Probabilidad',
	Exposure: 'Exposición',
	'Possible Injuries/Harms': 'Posibles lesiones/daños',
	Job: 'Trabajo',
	'Assignment note': 'Nota de tarea',
	'Hours worked in the day': 'Horas trabajadas en el día',
	'Weight Limit recommended (WLR)': 'Límite de peso recomendado (LPR)',
	'Lifting index (LI)': 'índice de elevación (IL)',
	Comment: 'Comentario',
	Particularities: 'Particularidades',
	Production: 'Producción',
	'Working regime': 'Régimen de trabajo',
	EWA: 'AEP',
	Action: 'Acción',
	'New document': 'Nuevo documento',
	'File history': 'Historial del archivo',
	'Items per page': 'Artículos por página',
	'New Report': 'Novo Informe',
	Analysis: 'Análisis',

	//Action plans cards
	'Fill in the details above': 'Complete los detalles arriba',

	'Risk Acceptable': 'Riesgo Aceptable',

	'Risk Moderate': 'Riesgo Moderado',
	'Improvement action': 'Acción de mejora',

	'Risk High': 'Riesgo Alto',
	'Immediate action for improvement and reassess': 'Acción inmediata de mejora y reevaluación',

	'Risk Very High': 'Riesgo Muy Alto',
	'Perform Ergonomic Work Analysis': 'Realizar análisis de trabajo ergonómico',

	'Risk Serious and Imminent': 'Riesgo Grave e Inminente',
	'Stop the activity and carry out an Ergonomic Work Analysis':
		'Detener la actividad y realizar un Análisis Ergonómico del Trabajo',

	//Possible injuries
	'Possible injuries': 'Posibles lesiones',
	'Fatigue complaints': 'Quejas de fatiga',
	Absenteeism: 'Absentismo',
	'Musculoskeletal complaints': 'Quejas musculoesqueléticas',
	'Complaints of mental overload': 'Quejas de sobrecarga mental',
	'Complaints of irritability': 'Quejas de irritabilidad',
	'Nonconforming products': 'Productos no conformes',
	'Low back pain complaints': 'Quejas de lumbalgia',
	'Elbow pain': 'Dolor de codo',
	'Shoulder pain': 'Dolor de hombro',
	Headache: 'Dolor de cabeza',
	'Unexcused absences': 'Ausencias injustificadas',
	'Neck pain': 'Dolor de cuello',
	'Wrist pain': 'Dolor en la muñeca',

	//Consequence
	Consequence: 'Consecuencia',
	'Severe with fatalities and serious injuries': 'Severo con muertos y heridos graves',
	'Temporary disability': 'Incapacidad temporal',
	'Injuries with leave': 'Lesiones con baja',
	'Severe with possibility of death or disability': 'Grave con posibilidad de muerte o invalidez',
	'Superficial injuries': 'Lesiones / daños superficiales',
	'Catastrophic with numerous deaths': 'Catastrófico con numerosas muertes',

	//Probability
	Probability: 'Probabilidad',
	'Most likely and expected outcome': 'Resultado más probable y esperado',
	'Extremely remote possibility': 'Posibilidad extremadamente remota',
	'Practically impossible, never happened': 'Prácticamente imposible, nunca sucedió',
	'Unlikely, but it has happened': 'Improbable, pero ha pasado',
	'Possible outcome': 'Resultado posible',
	'Rare but possible': 'Raro pero posible',

	//Exhibition
	'Remotely (never occurred)': 'Remotamente (nunca ocurrió)',
	'Rarely (occurs in short bursts without definition)': 'Raramente (ocurre en ráfagas cortas sin definición)',
	'Irregular (once a month to once a year)': 'Irregular (una vez al mes a una vez al año)',
	'Occasionally (once a week to once a month)': 'Ocasionalmente (una vez a la semana a una vez al mes)',
	'Frequent (at least once a day)': 'Frecuente (al menos una vez al día)',
	'Continues (several times a day)': 'Continúa (varias veces al día)',
	'Remotely (>90% green)': 'Remotamente (>90% verde)',
	'Rarely (80 to 89% green)': 'Raramente (80 a 89% verde)',
	'Irregular (50 to 79% green)': 'Irregular (50 a 79% verde)',
	'Occasionally (30 to 49% green)': 'Ocasionalmente (30 a 49% verde)',
	'Frequent (10 to 29% green)': 'Frecuente (10 a 29% verde)',
	'Continues (<9% green)': 'Continúa (<9% verde)',

	//Steps erros
	'Select the injuries': 'Seleccione las lesiones',
	'Select the consequence': 'Seleccione la consecuencia',
	'Select the probability': 'Seleccione la probabilidad',
	'Select the exhibition': 'Seleccione la exposición',
	"Some required steps wasn't filled": 'No se completaron algunos pasos obligatorios',

	//Step 1
	Informations: 'Informaciones',
	'Select the sector': 'Seleccione el sector',
	Analyst: 'Analista',
	'Insert the analyst': 'Inserte el analista',
	Responsibility: 'Responsabilidad',
	'Insert the responsibility': 'Insertar la responsabilidad',
	'Insert the cell': 'Introduce la célula',
	'Insert the activity': 'Insertar la actividad',
	'Insert the workstation': 'Insertar la estación de trabajo',
	'Enter the collection date': 'Introduce la fecha de recogida',

	//Step 2
	'Work Conditions': 'Condiciones de trabajo',
	'Select the constraints perceived in the evaluated work condition':
		'Seleccione las restricciones percibidas en la condición de trabajo evaluada',
	'Work schedule': 'Horario de trabajo',
	'Summary description of the place': 'Descripción resumida del lugar',
	'Simplified description of the expected task': 'Descripción simplificada de la tarea esperada',
	'Simplified description of the task performed': 'Descripción simplificada de la tarea realizada',

	//Step 3
	'Working population': 'Población activa',
	"Worker's verbalization": 'Verbalización de los trabajadores',
	'Self-evaluation': 'Autoevaluación',
	'What is the level of stress perceived by the worker when performing the task?':
		'¿Cuál es el nivel de estrés que percibe el trabajador al realizar la tarea?',
	'Nothing stressful': 'Nada estresante',
	'Little stressful': 'Poco estresante',
	'Working population must be greater than 0': 'La población de los trabajadores debe ser mayor que 0',
	workers: 'trabajadores',
	Stressful: 'Estresante',
	'Very stressful': 'Muy estresante',
	'Extremely stressful': 'Extremadamente estresante',

	//Step 4
	'Workday and concentration at work': 'Jornada y concentración en el trabajo',
	'Working hours of more than 8 hours a day': 'Jornada de trabajo de más de 8 horas diarias',
	'Existence of long and frequent overtime hours of work':
		'Existencia de largas y frecuentes horas extraordinarias de trabajo',
	'Stays long time continuous operation': 'Permanece mucho tiempo en funcionamiento continuo',
	'Insufficient rest interval': 'Intervalo de descanso insuficiente',
	'Insufficient rest break': 'Pausa de descanso insuficiente',
	'Insufficient rest days': 'Días de descanso insuficientes',
	'Unbalanced concentrations of work in a day, week, month or year':
		'Concentraciones desequilibradas de trabajo en un día, semana, mes o año',
	'Unbalanced concentrations of work among workers': 'Concentraciones desequilibradas de trabajo entre trabajadores',
	'Insufficient rest between shifts (less than 11 hours)': 'Descanso insuficiente entre turnos (menos de 11 horas)',
	'Remains long time continuous operation': 'Permanece mucho tiempo en funcionamiento continuo',

	//Step 5
	'Kind of work': 'Clase de trabajo',
	'Lift and/or carry heavy objects': 'Levantar y/o transportar objetos pesados',
	'Apply the tool': 'Aplicar la herramienta',
	'Load surveys': 'Cargar encuestas',
	'Lifts and/or carries objects farther than 60 cm': 'Levanta y/o transporta objetos a más de 60 cm',
	'Performs tasks that require great strength': 'Realiza tareas que requieren una gran fuerza',
	'Performs tasks that require pronounced pushing and pulling forces':
		'Realiza tareas que requieren fuerzas pronunciadas de empuje y tracción',
	'Performs repetitive work': 'Realiza trabajo repetitivo',
	'Performs work that requires frequent finger, hand, or arm movements':
		'Realiza trabajos que requieren movimientos frecuentes de dedos, manos o brazos',
	'Do intensive work with a keyboard or other input devices':
		'Haz un trabajo intensivo con un teclado u otros dispositivos de entrada',
	'Perform precision work': 'Realizar trabajo de precisión',
	'Performs activities with high visual requirements': 'Realiza actividades con altos requerimientos visuales',

	//Step 6
	'Postures and movements': 'Posturas y movimientos',
	'Stays in uncomfortable postures and movements': 'Permanece en posturas y movimientos incómodos',
	'Performs continuous and/or highly frequent change in joint positions':
		'Realiza cambios continuos y/o muy frecuentes en posiciones conjuntas',
	'Stays in constrained posture for long duration': 'Permanece en una postura restringida durante mucho tiempo',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'Caminata de larga duración y/o larga distancia (tanto en una superficie horizontal como en una superficie inclinada)',
	'Performs frequent stair climbing': 'Realiza frecuentes subidas de escaleras',
	'Remains in a sedentary/standing position for a long time':
		'Permanece en una posición sedentaria/de pie durante mucho tiempo',
	'Postures requirements (Kinebot)': 'Requisitos de posturas (Kinebot)',

	//Step 7
	'Influence of workspace and task factors': 'Influencia del espacio de trabajo y los factores de la tarea',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'El espacio de trabajo es inadecuado y obliga a una postura incómoda o movimiento ilimitado',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'La disposición de los puestos de trabajo requiere movimientos excesivos o posturas incómodas',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'Las alturas, distancias y dimensiones de los mandos o superficies de trabajo son inadecuadas',
	'Anthropometry Table x Furniture (cm)': 'Mesa de Antropometría x Mobiliario (cm)',
	'Add information': 'Añadir información',
	'Found (cm)': 'Encontrado (cm)',
	'Foreseen (cm)': 'Previsto (cm)',
	'Enter a location': 'Ingrese una ubicación',
	'Enter what was found': 'Ingrese lo encontrado',
	'Insert predicted': 'Insertar predicho',
	'Performs handling of work objects above the shoulder or below the knee':
		'Realiza la manipulación de objetos de trabajo por encima del hombro o por debajo de la rodilla',
	'The workspace forces the worker to maintain the same working posture':
		'El espacio de trabajo obliga al trabajador a mantener la misma postura de trabajo',
	'Handles work objects that are difficult to handle and slippery':
		'Maneja objetos de trabajo que son difíciles de manejar y resbaladizos',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'La actividad requiere contacto de alto voltaje o presión local que actúa sobre el cuerpo',

	//Step 8
	'Influence of psychosocial factors': 'Influencia de los factores psicosociales',
	'Activity with mental overload or underload': 'Actividad con sobrecarga o subcarga mental',
	'Work pace with time pressure and high demands': 'Ritmo de trabajo con presión de tiempo y altas exigencias',
	'Presence of work-related stress': 'Presencia de estrés relacionado con el trabajo',
	'Reports of low job satisfaction': 'Informes de baja satisfacción laboral',
	'Activity with lack of autonomy (low influence, low control)':
		'Actividad con falta de autonomía (baja influencia, bajo control)',
	'Low social support': 'Poco apoyo social',

	//Step 9
	'Influence of environmental factors': 'Influencia de los factores ambientales',
	'Place with slippery and/or uneven floor': 'Lugar con suelo resbaladizo y/o irregular',
	'There is exposure to whole body vibration (see ISO 2631)':
		'Hay exposición a vibraciones de todo el cuerpo (ver ISO 2631)',
	'Makes use of hand-arm vibration tools during work; (see ISO 5349)':
		'Hace uso de herramientas de vibración mano-brazo durante el trabajo; (ver ISO 5349)',
	'Performs work using vehicles that transmit vibration throughout the body':
		'Realiza trabajos utilizando vehículos que transmiten vibraciones por todo el cuerpo',
	'Hot or cold working environment': 'Ambiente de trabajo caliente o frío',
	'Location with background noise': 'Ubicación con ruido de fondo',

	//Step 10
	'Organizational influences': 'Influencias organizacionales',
	'Production standards are not clear or understood by workers':
		'Los estándares de producción no son claros o no son entendidos por los trabajadores',
	'The operative mode descriptions are insufficient or wrong':
		'Las descripciones de los modos operativos son insuficientes o incorrectas',
	'The time to carry out the activities is inadequate': 'El tiempo para realizar las actividades es inadecuado',
	'The pace of work is accelerated': 'Se acelera el ritmo de trabajo',
	'The content of the tasks is different from the technical means available':
		'El contenido de las tareas es diferente de los medios técnicos disponibles',
	'Task with high cognitive demand': 'Tarea con alta demanda cognitiva',

	// Results
	'Anthropometry Table': 'Mesa de antropometría',
	'Consolidate analysis': 'Consolidar análisis',
	Deconsolidate: 'Desconsolidar',
	'Successfully consolidated analysis': 'Análisis consolidado con éxito',
	'Successfully deconsolidated analysis': 'Análisis no consolidado con éxito',
	'Failed to generate the report': 'Error al generar el informe',
	'Failed to generate PDF': 'Error al generar el PDF',

	Hazardous: 'Peligroso',
	'Movement by score (Kinebot/REBA)': 'Movimiento por puntuación (Kinebot/REBA)',
	'Cargo Handling (NIOSH)': 'Manejo de carga (NIOSH)',
	'Manual Handling (KIM)': 'Manipulación manual (KIM)',
	'Push and Pull (KIM)': 'Empujar y tirar (KIM)',
	'No more action plans available': 'No hay más planes de acción disponibles',
	'No more workstations available': 'No hay más estaciones de trabajo disponibles',

	//Super Preliminary Ergonomic Analysis

	//Error
	'length must be less than or equal to 50 characters long': 'la longitud debe ser menor o igual a 50 caracteres',

	'Preliminary Ergonomic Analysis': 'Análisis Ergonómico Preliminar',

	'New Document': 'Nuevo documento',
	'File History': 'Historial de archivos',

	'Basic Information': 'Información básica',
	'Select PEA': 'Seleccionar PEA',

	// Basic Informartion Step
	'Report Information': 'Informe de información',
	'Report name': 'Reportar nombre',
	'Enter report name': 'Ingrese el nombre del informe',
	'Select a Company': 'Seleccione una empresa',
	'Select the reference date': 'Seleccione la fecha de referencia',
	'Select the report date': 'Seleccione la fecha del informe',

	// Select PEA Step
	'File Name': 'Nombre del archivo',
	'File name': 'Nombre del archivo',
	'Created At': 'Creado en',
	'Uploaded date': 'Fecha de carga',
	'Date of creation': 'Fecha de creación',
	'Date of update': 'Fecha de actualización',
	'Analyst Name': 'Nombre del analista',
	Submit: 'Entregar',

	//Signatures Step
	Signatures: 'Firmas',
	'Enter with a role': 'Entrar con un puesto de trabajo',
	'Enter with a name of responsible': 'Ingresar con nombre de responsable',
	'Add a new signature': 'Añadir una nueva firma',
	'Responsible name': 'Nombre responsable',

	// Conclusion Step
	'Check selected Files': 'Comprobar archivos seleccionados',
	'Files List': 'Lista de archivos',
	'Selected EWA': 'EWA seleccionado',
	'EWA created successfully': 'EWA creado con éxito',
	'Failed to upload PEA report.': 'Error al cargar el informe PEA.',

	// Super PEA File History
	'Preliminary Ergonomic Analyzes': 'Análisis Ergonómicos Preliminares',

	Location: 'Ubicación',
	Found: 'Encontró',
	Foreseen: 'Visto el futuro',
	Condition: 'Condición',

	'NOT OK': 'NO OK',
	"Score's sum": 'Resumen',

	// Do not change due to AEP key - Influences the translation of action plans created by AEP
	'Trunk posture from Kinebot': 'Postura del tronco de Kinebot',
	'Trunk posture from kinebot': 'Postura del tronco de kinebot',
	'Neck posture from Kinebot': 'Postura del cuello de Kinebot',
	'Neck posture from kinebot': 'Postura del cuello de kinebot',
	'Left elbow posture from Kinebot': 'Postura del codo izquierdo de Kinebot',
	'Left elbow posture from kinebot': 'Postura del codo izquierdo de kinebot',
	'Right elbow posture from Kinebot': 'Postura del codo derecho de Kinebot',
	'Right elbow posture from kinebot': 'Postura del codo derecho de kinebot',
	'Left shoulder posture from Kinebot': 'Postura del hombro izquierdo de Kinebot',
	'Left shoulder posture from kinebot': 'Postura del hombro izquierdo de kinebot',
	'Right shoulder posture from Kinebot': 'Postura del hombro derecho de Kinebot',
	'Right shoulder posture from kinebot': 'Postura del hombro derecho de kinebot',

	'Failed to upload the file': 'Error al cargar el archivo',

	// Two-factor authentication
	// API Errors
	'Two-factor authentication not found': 'Autenticación de dos factores no encontrada',
	'Already created two-factor authentication': 'Autenticación de dos factores ya creada',
	'Invalid token': 'Simbolo no valido',
	'Token already validated': 'Token ya validado',
	'Token not yet validated': 'Token aún no validado',
	'Token expired': 'Ficha caducada',
	'Too early to resend email': 'Demasiado pronto para reenviar el correo electrónico',
	'Something went wrong checking token': 'Algo salió mal al verificar el token',
	'Authentication type same as default': 'Tipo de autenticación igual que el predeterminado',
	'Authentication type through app already enabled': 'Tipo de autenticación a través de la aplicación ya habilitada',
	'Two-factor authentication not enabled': 'Autenticación de dos factores no habilitada',
	'Too many tries!': '¡Demasiados intentos!',
	'Too many calls! Wait a moment to try again.': '¡Demasiadas llamadas! Espere un momento para volver a intentarlo.',
	'You are not authorized to access this resource': 'No está autorizado para acceder a este recurso',

	// Hooks success message
	'Two-factor authentication generated successfully': 'Autenticación de dos factores generada con éxito',
	'Two-factor authentication validated successfully': 'Autenticación de dos factores validada con éxito',
	'Two-factor authentication method changed successfully':
		'El método de autenticación de dos factores cambió con éxito',
	'Token sent successfully': 'Token enviado con éxito',
	'Token resent successfully': 'Token reenviado con éxito',
	'Authenticator app enabled successfully': 'Aplicación de autenticación habilitada con éxito',
	'Two-factor authentication deleted successfully': 'La autenticación de dos factores se eliminó con éxito',
	'Two-factor authentication reset successfully': 'Restablecimiento de autenticación de dos factores con éxito',
	'Forced two-factor authentication enabled successfully':
		'Autenticación de dos factores forzada habilitada con éxito',
	'Forced two-factor authentication disabled successfully':
		'Autenticación de dos factores forzada deshabilitada con éxito',

	// My Account
	'Force two-factor authentication to all users': 'Forzar autenticación de dos factores a todos los usuarios',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'Cuando se activa, todos los usuarios se verán obligados a activar la autenticación de dos factores al iniciar sesión.',
	'Two-factor authentication': 'Autenticación de dos factores',
	'When activated, you will have to type a validation code every time you login.':
		'Cuando esté activado, deberá escribir un código de validación cada vez que inicie sesión.',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'Puede optar por recibir su código por correo electrónico o conectar su aplicación de autenticación con un QRCode.',

	// Actions
	'Reset 2FA': 'Restablecer 2FA',
	'Deactivate 2FA': 'Desactivar 2FA',
	'You are about to reset your authentication method.': 'Está a punto de restablecer su método de autenticación.',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'Seleccione un nuevo tipo de autenticación deseado e inserte uno de sus tokens de recuperación para realizar esta operación.',
	'Authentication method': 'Método de autentificación',
	'Recovery token': 'Token de recuperación',
	'Authenticator app': 'Aplicación de autenticación',
	'You are about to deactivate your 2FA.': 'Está a punto de desactivar su 2FA.',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'Si confirma esta acción, se eliminará su autenticación de dos factores. ¿Está seguro?',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'¡Aviso! Si cierra ahora, se eliminará el token que generó. ¿Quieres continuar?',
	'Generate Two-factor authentication': 'Generar autenticación de dos factores',
	'Select a method to generate your two-factor authentication':
		'Seleccione un método para generar su autenticación de dos factores',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login. This code changes every few seconds.':
		'Con este método, escaneará un QRCode para poder usar su aplicación de autenticación favorita (como Google Authenticator o Microsoft Authenticator). Luego, todo lo que tiene que hacer es escribir el código que se muestra en su aplicación cuando inicia sesión. Este código cambia cada pocos segundos.',
	'You are about to generate your 2FA with your authenticator app.':
		'Está a punto de generar su 2FA con su aplicación de autenticación.',
	'Be sure to have your mobile phone with you ready to scan your code.':
		'Asegúrese de tener su teléfono móvil con usted listo para escanear su código.',
	'Validation code': 'Código de validación',
	'Enter the 6 digit code.': 'Ingrese el código de 6 dígitos.',
	'Recovery tokens': 'Fichas de recuperación',

	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'Aquí están tus códigos de recuperación, que se solicitarán en caso de que necesites recuperar tu cuenta. Ten en cuenta que no estarán accesibles de nuevo después de este momento, por lo que es crucial que los guardes de manera segura.',

	"Can't close yet. Read the instructions": 'No se puede cerrar todavía. Lee las instrucciones.',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'Su empresa ha pedido a todos los usuarios que mantengan habilitada la autenticación de dos factores por razones de seguridad. Se le pedirá que la active nuevamente en su próximo inicio de sesión. ¿Desea desactivarla de todos modos?',

	//Login
	'For protection reasons, we need that you validate your 2FA':
		'Por razones de protección, necesitamos que valides tu 2FA',
	'Your company is requesting that all users must have two-factor authentication enabled. Continue to activate yours and login.':
		'Su empresa solicita que todos los usuarios tengan habilitada la autenticación de dos factores. Continúe activando el suyo e inicie sesión.',

	Change: 'Cambiar',
	Security: 'Seguridad',
	'Account security settings': 'Configuración de seguridad de la cuenta',
	'Account information summary': 'Resumen de información de la cuenta',
	'Password must be at least 8 characters long': 'La contraseña debe tener al menos 8 caracteres',
	'The password must have a maximum of 20 characters': 'La contraseña debe tener como máximo 20 caracteres',
	'The password field is required': 'Se requiere el campo de contraseña',
	'Password field cannot be empty': 'El campo de contraseña no puede estar vacío',
	'The password must contain capital letters': 'La contraseña debe contener letras mayúsculas',
	'The password must contain lowercase letters': 'La contraseña debe contener letras minúsculas',
	'Password must contain numbers': 'La contraseña debe contener números',
	'The password must contain at least one special character':
		'La contraseña debe contener al menos un carácter especial',

	// Risks Manager
	Set: 'Definir',
	'Severity not set': 'Severidad no definida',
	'Manage risks': 'Gestionar riesgos',
	'Manage risk categories': 'Gestionar categorías de riesgo',
	'Risk category': 'Categoría de riesgo',
	'Risk damage': 'Daño',
	'Risk category not found': 'Categoría de riesgo no encontrada',
	'Risk description not found': 'Descripción de riesgo no encontrada',
	'Risk damage not found': 'Daño no encontrado',
	'Select a risk damage': 'Seleccionar un daño',
	'Current severity': 'Severidad actual',
	'Severity set successfully': 'Severidad definida con éxito',
	'Create new risk category': 'Crear nueva categoría de riesgo',
	'Update category name': 'Actualizar nombre de categoría',
	'Delete category': 'Eliminar categoría',
	'Category deleted successfully': 'Categoría eliminada con éxito',
	'Category updated successfully': 'Categoría actualizada con éxito',
	'Damage created successfully': 'Daño creado con éxito',
	'Category created successfully': 'Categoría creada con éxito',
	'Risk description created successfully': 'Descripción creada con éxito',
	'Data created successfully': 'Datos creados con éxito',
	'Data updated successfully': 'Datos actualizados con éxito',
	'Data deleted successfully': 'Datos eliminados con éxito',
	'Create new company': 'Crear nueva empresa',
	'Company updated successfully': 'Empresa actualizada con éxito',
	'Company deleted successfully': 'Empresa eliminada con éxito',
	'All risk descriptions related to it will be deleted. Do you wish to proceed?':
		'Todas las descripciones de riesgo relacionadas con esto serán eliminadas. ¿Desea continuar?',
	'Manage risk description': 'Gestionar descripciones de riesgo',
	'Select a risk description': 'Seleccionar una descripción de riesgo',
	'Want to delete this description?': '¿Desea eliminar esta descripción?',
	'Want to delete this damage?': '¿Desea eliminar este daño?',

	// B.E.R.A. Report
	Files: 'Archivos',
	'Custom report': 'Reporte personalizado',
	'Custom reports': 'Reportes personalizados',
	'Custom reports files': 'Archivos del reportes personalizados',
	'Basic Ergonomics Risk Assessment (B.E.R.A.)': 'Evaluación Básica de Riesgos Ergonómicos (B.E.R.A.)',
	'General informations': 'Informaciones Generales',
	'Insert below the informations for the cycle': 'Inserte a continuación las informaciones para el ciclo',
	Organization: 'Organización',
	'Operator evaluated': 'Operador evaluado',
	'Enter a report name': 'Ingrese un nombre de informe',
	'Enter a evaluator': 'Ingrese un evaluador',
	'Work center': 'Centro de trabajo',
	Evaluator: 'Evaluador',
	'Select below the video files referring to the tasks that make up the cycle':
		'Seleccione los archivos de video que se refieren a las tareas que componen el ciclo',
	Task: 'Tarea',
	'Insert below the informations related to the selected video':
		'Inserte a continuación las informaciones relacionadas con el video seleccionado',
	'Task list ID (TLID)': 'ID de lista de tareas (TLID)',
	'Task time': 'Tiempo de tarea',
	'Has known injury?': '¿Ha tenido lesiones conocidas?',
	'There is no presence of "Too High" movements': 'No hay presencia de movimientos "Muy Alto"',
	'Too High': 'Muy Alto',
	'Job element': 'Elemento de trabajo',
	'Insert job element': 'Insertar elemento de trabajo',
	'Total task duration': 'Duración total de la tarea',
	'Select the task': 'Seleccione la tarea',
	'Insert a task duration': 'Inserte la duración de una tarea',
	'Elbow to Shoulder - 1075 to 1425 mm (42.2 to 56 in)': 'Codo a Hombro - 1075 a 1425 mm (42.2 a 56 pulgadas)',
	'Shoulder to top of head  > 60 deg shoulder flex - 1425 to 1730 mm (56 to 68 in)':
		'Hombro a parte superior de la cabeza > 60 grados de flexión del hombro - 1425 a 1730 mm (56 a 68 pulgadas)',
	'Overhead work - >  1730 mm (> 68 in)': 'Trabalho acima da cabeça - > 1730 mm (> 68 pol.)',
	'1 per task': '1 por tarea',
	'1 per minute': '1 por minuto',
	'2 per minute': '2 por minuto',
	'>2 per minute': '>2 por minuto',
	'<10% of Task': '<10% de la tarea',
	'<25% of Task': '<25% de la tarea',
	'<33% of Task': '<33% de la tarea',
	'<50% of Task': '<50% de la tarea',
	'>50% of Task': '>50% de la tarea',
	'Too Low': 'Muy Bajo',
	'There is no presence of "Too Low" movements': 'No hay presencia de movimientos "Muy Bajo"',
	'Elbow to standing grip - 1075 to 800 mm (42.2 to 31.3 in)':
		'Codo al agarre de pie - 1075 a 800 mm (42.2 a 31.3 pulgadas)',
	'Grip to knee - 800 to 550 mm (31.3 to 21.8 in)': 'Agarre a la rodilla - 800 a 550 mm (31.3 a 21.8 pulgadas)',
	'Below knee - < 550 mm (<21.8 in)': 'Debajo de la rodilla - < 550 mm (<21.8 pulgadas)',
	'Well Below Knee <255 mm (<10 in)': 'Muy por debajo de la rodilla <255 mm (<10 pulgadas)',
	'Too Far': 'Muy Lejos',
	'There is no presence of "Too Far" movements': 'No hay presencia de movimientos "Muy Lejos"',
	'<300 mm (<12 inches) in front of body': '<300 mm (<12 pulgadas) frente al cuerpo',
	'300<460 mm (12<18 inches) in front of body': '300<460 mm (12<18 pulgadas) frente al cuerpo',
	'460<610 mm (18<24 inches) in front of body': '460<610 mm (18<24 pulgadas) frente al cuerpo',
	'610<915 mm (24<36 inches) in front of body': '610<915 mm (24<36 pulgadas) frente al cuerpo',
	'>915 mm (>36 inches) in front of body': '>915 mm (>36 pulgadas) frente al cuerpo',
	'Select the stress level': 'Seleccione el nivel de estrés',
	'Select the frequency': 'Seleccione la frecuencia',
	'Select the total task duration': 'Seleccione la duración total de la tarea',
	'Too Many': 'Muchas veces',
	'There is no presence of "Too Many" movements': 'No hay presencia de movimientos "Muchas veces"',
	'Striking parts': 'Ajuste de piezas',
	'Hand activities': 'Actividades manuales',
	'Small hammer (<.2 kg, <.3 lb)': 'Martillo pequeño (<.2 kg, <.3 lb)',
	'Mallet Use (< 1 kg, < 2.2 lb)': 'Uso de maza (< 1 kg, < 2.2 lb)',
	'Using hand as a hammer': 'Usar mano como martillo',
	'Hammer Use (< 1 kg, < 2.2 lb)': 'Uso de martillo (< 1 kg, < 2.2 lb)',
	'Sledge Hammer Use (> 2 kg, >4.5 lb)': 'Uso de martillo grande (> 2 kg, >4.5 lb)',
	'Light tap 1/cycle': 'Golpe ligero 1/ciclo',
	'<10 per hour': '<10 por hora',
	'>10 per hour': '>10 por hora',
	'>20 per hour': '>20 por hora',
	'>30 per hour': '>30 por hora',
	'Populate using tool': 'Realizar usando herramienta',
	'Job Strain Index <3': 'Índice de Tensión Laboral <3',
	'Job Strain Index 3-5': 'Índice de Tensión Laboral 3-5',
	'Job Strain Index 5-7': 'Índice de Tensión Laboral 5-7',
	'Job Strain Index 7-12': 'Índice de Tensión Laboral 7-12',
	'Job Strain Index >12': 'Índice de Tensión Laboral >12',
	'Select at least one option.': 'Seleccione al menos una opción.',
	'Too Much': 'Muy pesado',
	'There is no presence of "Too Much" movements': 'No hay presencia de movimientos "Muy pesado"',
	Lifting: 'Levantamiento',
	'Push/Pull One Hand': 'Empujar/Tirar con una mano',
	'Push/Pull Two Hand': 'Empujar/Tirar con ambas manos',
	'Push/Pull Cart': 'Empujar/Tirar de un carrito',
	'Tool Weight <2.3 kg (<5 lb)': 'Peso de la herramienta <2.3 kg (<5 lb)',
	'Tool Weight >4.5 kg (>10 lb)': 'Peso de la herramienta >4.5 kg (>10 lb)',
	'Tool Weight >7 kg (>15 lb)': 'Peso de la herramienta >7 kg (>15 lb)',
	'Tool Weight >9 kg (>20 lb)': 'Peso de la herramienta >9 kg (>20 lb)',
	'Tool Weight >11.3 kg (>25 lb)': 'Peso de la herramienta >11.3 kg (>25 lb)',
	'Two hand force <6 kg (<13 lb)': 'Fuerza con ambas manos <6 kg (<13 lb)',
	'Two hand force >6 kg (>13 lb)': 'Fuerza con ambas manos >6 kg (>13 lb)',
	'Two hand force >10 kg (>22 lb)': 'Fuerza con ambas manos >10 kg (>22 lb)',
	'Two hand force >15 kg (>33 lb)': 'Fuerza con ambas manos >15 kg (>33 lb)',
	'Two hand force >18 kg (>40 lb)': 'Fuerza con ambas manos >18 kg (>40 lb)',
	'Cart Push/Pull Initial <23 kg (< 50lb)': 'Empujar/Tirar del carrito inicial <23 kg (< 50 lb)',
	'Cart Push/Pull Initial >23 kg (> 50lb)': 'Empujar/Tirar del carrito inicial >23 kg (> 50 lb)',
	'Cart Push/Pull Initial >29.5 kg (> 65lb)': 'Empujar/Tirar del carrito inicial >29.5 kg (> 65 lb)',
	'Cart Push/Pull Initial >34 kg (>75 lb)': 'Empujar/Tirar del carrito inicial >34 kg (>75 lb)',
	'No noticeable vibration': 'No hay vibración perceptible',
	'Slight hand vibration': 'Ligera vibración en la mano',
	'Noticeable vibration of hand': 'Vibración perceptible en la mano',
	'Vibration in arm': 'Vibración en el brazo',
	'Too Long': 'Muy Largo',
	'There is no presence of "Too Long" movements': 'No hay presencia de movimientos "Muy Largos"',
	'Inadequate Clearance': 'Espacio insuficiente',
	'Contact Stress': 'Estrés de contacto',
	'Limited Hand Clearance': 'Espacio limitado para la mano',
	'Limited Whole Body Clearance': 'Espacio limitado para todo el cuerpo',
	'Limited Finger Clearance': 'Espacio limitado para los dedos',
	'Limited Arm Clearance': 'Espacio limitado para el brazo',
	'Limited Hand Clearance with Part': 'Espacio limitado para la mano con pieza',
	'Contact Stress <20 sec': 'Estrés de contacto <20 seg',
	'Contact Stress >20 sec': 'Estrés de contacto >20 seg',
	Severity: 'Severidad',
	Vulnerability: 'Probabilidad',
	'Risk Priority Number (RPN)': 'Número de Prioridad de Riesgo (NPR)',
	'Collection Date': 'Fecha de recolección',
	'Known injuries': 'Lesiones conocidas',
	'Has no injury history': 'No tiene historial de lesiones',
	'Has known injury': 'Tiene lesiones conocidas',
	'Last update': 'Última actualización',
	'Show reports': 'Mostrar informes',
	'Hide reports': 'Esconder',
	'Create B.E.R.A. report': 'Crear informe de B.E.R.A.',
	'Total evaluated time': 'Tiempo total evaluado',
	'Weighted RSI': 'RSI Ponderado',
	'Weighted RPN': 'RPN Ponderado',
	'Tasks analyzed': 'Tareas analizadas',
	'Cycle summary': 'Resumen del ciclo',
	'Risk Factors': 'Factores de riesgo',
	'Highest RPN': 'NPR más alto',
	'RPN Score': 'RPN',
	Position: 'Posición',
	'Task RPN': 'RPN de la tarea',
	'Greater than': 'Mayor que',
	'Greater than or equal': 'Mayor o igual',
	'Less than': 'Menos que',
	'Less than or equal': 'Menor o igual',
	Between: 'Entre',
	Maximum: 'Máximo',
	Minimum: 'Mínimo',
	'Search by file name...': 'Buscar por nombre de archivo...',
	'Select at least one file': 'Seleccione al menos un archivo',
	Seconds: 'Segundos',
	'Finish B.E.R.A.': 'Finalizar B.E.R.A.',
	'Consolidate report': 'Consolidar el informe',
	'Report updated successfully': 'Informe actualizado correctamente',
	'B.E.R.A. deleted successfully': 'B.E.R.A. eliminado con éxito',
	'B.E.R.A. report deleted successfully': 'Relatório B.E.R.A. eliminado con éxito',
	Uncertain: 'Incierto',
	'Some risk': 'Algo de riesgo',
	Extreme: 'Extremo',

	// S.E.R.A. Report
	'Safety Ergonomic Risk Assessment (S.E.R.A.)': 'Evaluación de Riesgos Ergonómicos de Seguridad (S.E.R.A.)',
	'Ergonomic - Biomechanical': 'Ergonómico - Biomecánico',
	'Ergonomic - Furniture and equipment': 'Ergonómico - Mobiliario y equipamiento',
	'Ergonomic - Organizational': 'Ergonómico - Organizacional',
	'Ergonomic - Environmental': 'Ergonómico - Ambiental',
	'Ergonomic - Psychosocial and cognitive': 'Ergonómico - Psicosocial y cognitivo',
	'Working in awkward or uncomfortable postures for long periods':
		'Trabajar en posturas incómodas o poco ergonómicas durante largos períodos',
	'Sitting posture for long periods': 'Postura sentada durante largos períodos',
	'Standing posture for long periods': 'Postura de pie durante largos períodos',
	'Frequent commuting on foot during the workday': 'Desplazamiento frecuente a pie durante la jornada laboral',
	'Work with intense physical exertion': 'Trabajo con esfuerzo físico intenso',
	'Lifting and manual transport of loads or volumes': 'Levantamiento y transporte manual de cargas o volúmenes',
	'Frequent pulling/pushing of loads or packages': 'Tirar/empujar cargas o paquetes con frecuencia',
	'Frequent execution of repetitive movements': 'Ejecución frecuente de movimientos repetitivos',
	'Handling tools and/or heavy objects for long periods':
		'Manipulación de herramientas y/o objetos pesados durante largos períodos',
	'Adoption of unnecessary and inappropriate postures and movements.':
		'Adopción de posturas y movimientos innecesarios e inapropiados.',
	'Mental/physical exhaustion and psychiatric disorders': 'Agotamiento mental/físico y trastornos psiquiátricos',
	'Discomfort in upper/lower limbs and spine':
		'Incomodidad en extremidades superiores/inferiores y columna vertebral',
	'Accidents, low performance': 'Accidentes, bajo rendimiento',
	'Accidents, physical or mental exhaustion': 'Accidentes, agotamiento físico o mental',
	'Physical discomforts': 'Molestias físicas',
	'Musculoskeletal disorders in the upper limbs': 'Trastornos musculoesqueléticos en las extremidades superiores',
	'Exposure to localized vibrations (hand-arm) below action level':
		'Exposición a vibraciones localizadas (mano-brazo) por debajo del nivel de acción',
	'Exposure to localized vibrations (hand-arm) at action level':
		'Exposición a vibraciones localizadas (mano-brazo) en el nivel de acción',
	'Exposure to whole-body vibration below action level':
		'Exposición a vibración de cuerpo entero por debajo del nivel de acción',
	'Exposure to localized vibrations (hand-arm) above the tolerance limit':
		'Exposición a vibraciones localizadas (mano-brazo) 	por encima del límite de tolerancia',
	'Exposure to whole-body vibration above the tolerance limit':
		'Exposición a vibración de cuerpo entero por encima del límite de tolerancia',
	'Exposure to whole-body vibration at action level': 'Exposición a vibración de cuerpo entero en el nivel de acción',
	'Stumbles, falls': 'Tropezones, caídas',
	'Reflections, glare': 'Reflejos, deslumbramiento',
	'Visualization impairment': 'Impedimento de la visualización',
	'Low back pain': 'Dolor lumbar',
	'Irritation of structures (tendons, ligaments and nerves) causing musculoskeletal disorders':
		'Irritación de estructuras (tendones, ligamentos y nervios) que causa trastornos musculoesqueléticos',
	'Excessive caloric expenditure, development of musculoskeletal disorders':
		'Gasto calórico excesivo, desarrollo de trastornos musculoesqueléticos',
	'Fatigue, risk of falls': 'Fatiga, riesgo de caídas',
	'Fatigue in the legs and development of vascular disorders':
		'Fatiga en las piernas y desarrollo de trastornos vasculares',
	'Fatigue in lower limbs': 'Fatiga en las extremidades inferiores',
	'Mental exhaustion and psychiatric disorders': 'Agotamiento mental y trastornos psiquiátricos',
	'Requiring frequent use of force, pressure, gripping, flexion, extension, or twisting of body segments':
		'Requiere el uso frecuente de fuerza, presión, agarre, flexión, extensión o torsión de segmentos corporales',
	'Compression of body parts by hard or cornered surfaces':
		'Compresión de partes del cuerpo por superficies duras o con esquinas',
	'Requiring frequent spinal flexions': 'Requiere flexiones frecuentes de la columna vertebral',
	'Frequent use of pedals': 'Uso frecuente de pedales',
	'Frequent use of levers': 'Uso frecuente de palancas',
	'Demand for frequent lifting of upper limbs': 'Requiere levantar frecuentemente los miembros superiores',
	'Handling or moving loads and volumes without a handle or with a “poor handle”':
		'Manipulación o movimiento de cargas y volúmenes sin asa o con una "asa deficiente"',
	'Whole body vibration exposure': 'Exposición a vibraciones de cuerpo completo',
	'Exposure to localized vibrations (hand-arm)': 'Exposición a vibraciones localizadas (mano-brazo)',
	'Frequent use of stairs': 'Uso frecuente de escaleras',
	'Makeshift workstation': 'Puesto de trabajo improvisado',
	'Unplanned/Unadapted workstation for sitting position':
		'Puesto de trabajo no planificado/no adaptado para posición sentada',
	'Inappropriate seat': 'Asiento inadecuado',
	'Improper or missing seat backrest': 'Respaldo del asiento inadecuado o ausente',
	'Furniture or equipment without space to move body segments':
		'Muebles o equipos sin espacio para mover los segmentos corporales',
	'Work performed without predefined rest breaks': 'Trabajo realizado sin pausas predefinidas para descanso',
	'Need to maintain intense work rhythms': 'Necesidad de mantener ritmos de trabajo intensos',
	'Work with need for shift variation': 'Trabajo con necesidad de variación de turnos',
	Monotony: 'Monotonía',
	'Night work': 'Trabajo nocturno',
	'Insufficient training to perform the task': 'Formación insuficiente para realizar la tarea',
	'Work with strict use of production targets': 'Trabajo con uso estricto de objetivos de producción',
	'Work paid by production': 'Trabajo remunerado por producción',
	'Work cadence imposed by a piece of equipment': 'Cadencia de trabajo impuesta por una pieza de equipo',
	'Imbalance between work time and rest time': 'Desbalance entre tiempo de trabajo y tiempo de descanso',
	'Working conditions with sound pressure levels outside comfort parameters':
		'Condiciones de trabajo con niveles de presión sonora fuera de los parámetros de comodidad',
	'Working conditions with effective temperature index outside the comfort parameters':
		'Condiciones de trabajo con índice de temperatura efectiva fuera de los parámetros de comodidad',
	'Working conditions with air speed outside comfort parameters':
		'Condiciones de trabajo con velocidad del aire fuera de los parámetros de comodidad',
	'Working conditions with air humidity outside comfort parameters':
		'Condiciones de trabajo con humedad del aire fuera de los parámetros de comodidad',
	'Working conditions with inadequate daytime lighting': 'Condiciones de trabajo con iluminación diurna inadecuada',
	'Working conditions with inadequate night lighting': 'Condiciones de trabajo con iluminación nocturna inadecuada',
	'Presence of reflections on screens, panels, glass, monitors or any surface':
		'Presencia de reflejos en pantallas, paneles, vidrio, monitores o cualquier superficie',
	'Slippery and/or uneven floor': 'Suelo resbaladizo y/o irregular',
	'Excessive stressful situations': 'Situaciones de estrés excesivo',
	'Situations of mental work overload': 'Situaciones de sobrecarga de trabajo mental',
	'Requiring a high level of concentration, attention and memory':
		'Requiere un alto nivel de concentración, atención y memoria',
	'Working in conditions of difficult communication': 'Trabajar en condiciones de comunicación difícil',
	'Excessive hierarchical conflicts at work': 'Conflictos jerárquicos excesivos en el trabajo',
	'Excessive emotional/affective demands at work': 'Demandas emocionales/afectivas excesivas en el trabajo',
	'Harassment of any nature at work': 'Acoso de cualquier naturaleza en el trabajo',
	'Work with divergent demands (divergent orders, mutually incompatible goals, demand for quality X quantity, among others)':
		'Trabajar con demandas divergentes (órdenes divergentes, metas mutuamente incompatibles, demanda de calidad X cantidad, entre otros)',
	'Job dissatisfaction': 'Insatisfacción laboral',
	'Lack of autonomy at work': 'Falta de autonomía en el trabajo',
	'Accidents and physical discomforts': 'Accidentes y molestias físicas',
	'Low performance': 'Bajo rendimiento',
	'Physical or mental exhaustion': 'Agotamiento físico o mental',
	'Adoption of inappropriate postures and movements': 'Adopción de posturas y movimientos inapropiados',
	'Alteration in the circadian cycle, sleep disorders': 'Alteración en el ciclo circadiano, trastornos del sueño',
	'Low visibility': 'Baja visibilidad',
	'Compression of structures (tissues, tendons, ligaments and nerves) causing musculoskeletal disorders':
		'Compresión de estructuras (tejidos, tendones, ligamentos y nervios) que causan trastornos musculoesqueléticos',
	Discomfort: 'Incomodidad',
	'Discomfort in lower limbs (legs and feet)': 'Incomodidad en las extremidades inferiores (piernas y pies)',
	'Discomfort in upper limbs (arms and hands)': 'Incomodidad en las extremidades superiores (brazos y manos)',
	'Discomfort in upper limbs and spine': 'Incomodidad en las extremidades superiores y la columna vertebral',
	'Discomfort in the spine': 'Incomodidad en la columna vertebral',
	'Discomfort in the lower limbs and spine': 'Incomodidad en las extremidades inferiores y la columna vertebral',
	'Development of musculoskeletal disorders': 'Desarrollo de trastornos musculoesqueléticos',
	'Difficulty concentrating, irritability': 'Dificultad para concentrarse, irritabilidad',
	'Balance disorders, headache': 'Trastornos del equilibrio, dolor de cabeza',
	'Musculoskeletal disorders in the upper limbs (Repetitive Strain Injury)':
		'Trastornos musculoesqueléticos en las extremidades superiores (Lesión por Esfuerzo Repetitivo)',
	'Musculoskeletal disorders in the spine': 'Trastornos musculoesqueléticos en la columna vertebral',
	'Musculoskeletal disorders': 'Trastornos musculoesqueléticos',
	'Muscle fatigue and development of musculoskeletal disorders':
		'Fatiga muscular y desarrollo de trastornos musculoesqueléticos',
	'Mental exhaustion': 'Agotamiento mental',
	'Requirement to perform multiple tasks, with high cognitive demand':
		'Requerimiento de realizar múltiples tareas, con alta demanda cognitiva',
	'Trabajo con demandas divergentes (órdenes divergentes, metas mutuamente incompatibles, demanda de calidad X cantidad, entre otras)':
		'Trabajo con demandas divergentes (órdenes divergentes, metas mutuamente incompatibles, demanda de calidad X cantidad, entre otras)',
	'Trabajo realizado sin pausas predefinidas para descanso':
		'Trabajo realizado sin pausas predefinidas para descanso',
	"Equipment or furniture not adapted to the worker's anthropometry":
		'Equipos o mobiliario no adaptados a la antropometría del trabajador',
	'Work with the need to reach objects, documents, controls or any point beyond the ideal reach zones for the anthropometric characteristics of the worker':
		'Trabajo con la necesidad de alcanzar objetos, documentos, controles o cualquier punto más allá de las zonas de alcance ideales para las características antropométricas del trabajador',
	'Equipment and/or machines without regulation means or without conditions of use':
		'Equipos y/o máquinas sin medios de regulación o sin condiciones de uso',
	'Furniture without regulation means of adjustment': 'Muebles sin medios de regulación de ajuste',
	'Intensive work with a keyboard or other input devices':
		'Trabajo intensivo con un teclado u otros dispositivos de entrada',
	'Requiring frequent lifting of upper limbs':
		'Requiere levantamiento frecuente de miembros superiores por encima del hombro',
	'1% to 10% of the cycle': '1% al 10% del ciclo',
	'11% to 20% of the cycle': '11% al 20% del ciclo',
	'21% to 30% of the cycle': '21% al 30% del ciclo',
	'31% to 50% of the cycle': '31% al 50% del ciclo',
	'51% to 75% of the cycle': '51% al 75% del ciclo',
	'76% to 100% of the cycle': '76% al 100% del ciclo',
	'[1] 1% to 10% of the cycle': '[1] 1% al 10% del ciclo',
	'[2] 11% to 20% of the cycle': '[2] 11% al 20% del ciclo',
	'[3] 21% to 30% of the cycle': '[3] 21% al 30% del ciclo',
	'[4] 31% to 50% of the cycle': '[4] 31% al 50% del ciclo',
	'[5] 51% to 75% of the cycle': '[5] 51% al 75% del ciclo',
	'[6] 76% to 100% of the cycle': '[6] 76% al 100% del ciclo',
	'Muscle fatigue/Discomfort': 'Fatiga muscular/Desconforto',
	'First aid': 'Primeros auxilios',
	'Recordable injury': 'Historial de lesiones',
	'Recordable with surgery or restrictions': 'Historial con cirugía o restricciones',
	'Lost time - Missed work due to injury and/or hospitalization':
		'Tiempo perdido - Ausencia laboral debido a lesión y/o hospitalización',
	'Fadiga muscular/desconforto': 'Fatiga muscular/incomodidad',
	'Primeiro socorro': 'Primeros auxilios',
	'Histórico de lesão': 'Historial de lesiones',
	'Histórico com cirurgia ou restrições': 'Historial con cirugía o restricciones',
	Fatality: 'Fatalidad',
	'[1] Muscle fatigue/Discomfort': '[1] Fatiga muscular/Desconfort',
	'[2] First aid': '[2] Primeros auxilios',
	'[3] Recordable injury': '[3] Historial de lesiones',
	'[4] Recordable with surgery or restrictions': '[4] Historial con cirugía o restricciones',
	'[5] Lost time - Missed work due to injury and/or hospitalization':
		'[5] Tiempo perdido - Ausencia laboral debido a lesión y/o hospitalización',
	'[6] Fatality': '[6] Fatalidad',
	'Unlikely (never happened and there is no possibility)': 'Poco probable (nunca ha sucedido y no hay posibilidad)',
	'Remote (there is a possibility of this occurring once during the useful life of the equipment)':
		'Remoto (existe la posibilidad de que esto ocurra una vez durante la vida útil del equipo)',
	'Occasional (there is a possibility of this occurring more than once during the useful life of the equipment)':
		'Ocasional (existe la posibilidad de que esto ocurra más de una vez durante la vida útil del equipo)',
	'Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year)':
		'Probable (ya hay un registro y existe la posibilidad de que ocurra de 1 a 12 veces al año)',
	'Frequent (there is already a registration and there is the possibility of occurring more than once a month)':
		'Frecuente (ya hay un registro y existe la posibilidad de que ocurra más de una vez al mes)',
	'Certainly (there is already a record and there is the possibility of occurring daily)':
		'Seguro (ya hay un registro y existe la posibilidad de que ocurra a diario)',
	'[1] Unlikely (never happened and there is no possibility)':
		'[1] Poco probable (nunca ha sucedido y no hay posibilidad)',
	'[2] Remote (there is a possibility of this occurring once during the useful life of the equipment)':
		'[2] Remoto (existe la posibilidad de que esto ocurra una vez durante la vida útil del equipo)',
	'[3] Occasional (there is a possibility of this occurring more than once during the useful life of the equipment)':
		'[3] Ocasional (existe la posibilidad de que esto ocurra más de una vez durante la vida útil del equipo)',
	'[4] Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year)':
		'[4] Probable (ya hay un registro y existe la posibilidad de que ocurra de 1 a 12 veces al año)',
	'[5] Frequent (there is already a registration and there is the possibility of occurring more than once a month)':
		'[5] Frecuente (ya hay un registro y existe la posibilidad de que ocurra más de una vez al mes)',
	'[6] Certainly (there is already a record and there is the possibility of occurring daily)':
		'[6] Seguro (ya hay un registro y existe la posibilidad de que ocurra a diario)',
	'[1] Unlikely': '[1] Poco probable',
	'[2] Remote': '[2] Remoto',
	'[3] Occasional': '[3] Ocasional',
	'[4] Likely': '[4] Probable',
	'[5] Frequent': '[5] Frecuente',
	'[6] Certainly': '[6] Seguro',
	'Reports status': 'Estado de informes',
	Uncompleted: 'No completado',
	'Show only uncompleted reports': 'Mostrar solo informes no completados',
	'Show only completed reports': 'Mostrar solo informes completados',
	'Show only reviewed reports': 'Mostrar solo informes revisados',
	'Created date': 'Fecha de creación',
	'Reviewed date': 'Fecha de revisión',
	'Task review': 'Revisión de tareas',
	'Select tasks below to review': 'Seleccione las tareas siguientes para revisarlas',
	'Review each task below': 'Revisar cada tarea a continuación',
	'Add report': 'Agregar informe',
	Specifications: 'Especificaciones',
	Specification: 'Especificación',
	'Existing prevention measures': 'Medidas de prevención existentes',
	Category: 'Categoría',
	'Select the category': 'Seleccionar la categoría',
	'Risk description': 'Descripción del riesgo',
	'Total risk descriptions': 'Descripciones de riesgos totales',
	'Select the description': 'Seleccionar la descripción',
	Damage: 'Daño',
	'Select the damage': 'Seleccionar el daño',
	'Select the evaluator': 'Seleccionar el evaluador',
	'Select the exposure': 'Seleccionar la exposición',
	'Select the vulnerability': 'Seleccionar la vulnerabilidad',
	'Select the severity': 'Seleccionar la severidad',
	'Select a severity': 'Seleccionar la severidad',
	'How many times is the operator exposed to risk in the work cycle?':
		'¿Cuántas veces está expuesto el operador al riesgo en el ciclo de trabajo?',
	'What is the probability of an incident happening?': '¿Cuál es la probabilidad de que ocurra un incidente?',
	'How severe could the injury be?': '¿Qué tan grave podría ser la lesión?',
	Edit: 'Editar',
	'Create review': 'Crear revisión',
	'Cycle results': 'Resultados del ciclo',
	Version: 'Versión',
	Review: 'Revisión',
	'Result S.E.R.A.': 'Resultado S.E.R.A.',
	'Original version date': 'Fecha de la versión original',
	'Last review': 'Última revisión',
	'Analyzed reports': 'Informes analizados',
	'Reviewed reports': 'Informes revisados',
	'Original version sum of RPN': 'Suma de RPN de la versión original',
	'Sum of RPN': 'Suma de RPN',
	'Average RPN': 'RPN promedio',
	'Analyzed tasks': 'Tareas analizadas',
	'Reviewed at': 'Revisado en',
	'Review date': 'Fecha de revisión',
	'RPN and Priority': 'RPN y Prioridad',
	Priority: 'Prioridad',
	'Create S.E.R.A. report': 'Crear informe S.E.R.A.',
	'Go back to files selection': 'Volver a la selección de archivos',
	'Action plans list': 'Lista de planes de acción',
	'S.E.R.A. action plans': 'Planes de acción S.E.R.A.',
	'Add action plan': 'Agregar plan de acción',
	'Action plan': 'Plan de acción',
	'General history': 'Historial general',
	'Tasks summary': 'Resumen de tareas',
	'Reviews history': 'Historial de revisiones',
	'Changed field': 'Campo cambiado',
	'Original value': 'Valor original',
	'Reviewed value': 'Valor revisado',
	Reviewer: 'Crítico',
	Reviewed: 'Revisado',
	Hide: 'Ocultar',
	Show: 'Mostrar',
	'Unlikely (never happened and there is no possibility) [1]':
		'Poco probable (nunca ha sucedido y no hay posibilidad) [1]',
	'Remote (there is a possibility of this occurring once during the useful life of the equipment) [2]':
		'Remoto (existe la posibilidad de que esto ocurra una vez durante la vida útil del equipo) [2]',
	'Occasional (there is a possibility of this occurring more than once during the useful life of the equipment) [3]':
		'Ocasional (existe la posibilidad de que esto ocurra más de una vez durante la vida útil del equipo) [3]',
	'Likely (there is already a record and there is the possibility of occurring 1 to 12 times a year) [4]':
		'Probable (ya hay un registro y existe la posibilidad de que ocurra de 1 a 12 veces al año) [4]',
	'Frequent (there is already a registration and there is the possibility of occurring more than once a month) [5]':
		'Frecuente (ya hay un registro y existe la posibilidad de que ocurra más de una vez al mes) [5]',
	'Certainly (there is already a record and there is the possibility of occurring daily) [6]':
		'Seguro (ya hay un registro y existe la posibilidad de que ocurra a diario) [6]',
	Choose: 'Elegir',
	'Next file': 'Siguiente archivo',
	'No fields were changed': 'No se cambiaron campos',
	'S.E.R.A. deleted successfully': 'S.E.R.A. eliminado con éxito',

	// Liberty Mutual
	'Go back to report list': 'Volver a la lista de informes',
	'Material Handling': 'Manipulación de Material',
	'Material handling': 'Manipulación de material',
	'Invalid request data': 'Datos de solicitud no válidos',
	'Liberty Mutual report created successfully!': '¡Informe Liberty Mutual creado exitosamente!',
	'Liberty Mutual report updated successfully!': '¡Informe Liberty Mutual actualizado exitosamente!',
	'Liberty Mutual task not found': 'Tarea de Liberty Mutual no encontrada',
	'Return to task list': 'Volver a la lista de tareas',
	'End hand height lower than start hand height. Should it be Lower task?':
		"Altura final de la mano inferior a la altura inicial de la mano. ¿Debería ser tarea 'Abaixar'?",
	'End hand height higher than start hand height. Should it be Lift task?':
		"Altura final de la mano superior a la altura inicial de la mano. ¿Debe ser tarea 'Levantar'?",
	'System of units not found': 'Sistema de unidades no encontrado',
	'Cannot be lower than start height': 'No puede ser inferior a la altura inicial',
	'Cannot be higher than start height': 'No puede ser superior a la altura inicial',
	Movements: 'Movimientos',
	'Individually evaluate the movements present in the task':
		'Evaluar individualmente los movimientos presentes en la tarea.',
	'Liberty mutual report created successfully!': '¡Informe Liberty Mutual creado exitosamente!',
	'Liberty mutual report updated successfully!': '¡Informe Liberty Mutual actualizado exitosamente!',
	'Liberty mutual task not found': 'Tarea de Liberty Mutual no encontrada',
	Lift: 'Levantar',
	Lifts: 'Levantamientos',
	lift: 'levantamiento',
	lifts: 'levantamientos',
	Lower: 'Bajar',
	Lowers: 'Bajamientos',
	lowers: 'bajamientos',
	lower: 'bajamiento',
	Push: 'Empujar',
	Pushes: 'Empujones',
	pushes: 'empujones',
	push: 'empujón',
	Pull: 'Tirar',
	Pulls: 'Tiradas',
	pulls: 'tiradas',
	pull: 'tirada',
	Carry: 'Cargar',
	Carries: 'Cargamentos',
	carries: 'cargamentos',
	carry: 'cargamento',
	'System of unit': 'Sistema de unidad',
	'Select the system of unit': 'Seleccione um sistema de unidad',
	'Select a system of unit': 'Seleccione um sistema de unidad',
	'Select the movement of the task being evaluated': 'Seleccione el movimiento de la tarea que se está evaluando',
	'Insert the fields data for the selected task': 'Inserte los datos de los campos para la tarea seleccionada',
	'Start hand height': 'Altura inicial de la mano',
	'End hand height': 'Altura final de la mano',
	'Start hand distance': 'Distancia inicial de la mano',
	'End hand distance': 'Distancia final de la mano',
	'Initial force': 'Fuerza inicial',
	'Sustained force': 'Fuerza sostenida',
	'Horizontal distance': 'Distancia horizontal',
	'Vertical hand height': 'Altura vertical de la mano',
	'Hand coupling': 'Acoplamiento de la mano',
	'Object weight': 'Peso del objeto',
	Weight: 'Peso',
	'Select an hand coupling': 'Seleccione un acoplamiento de mano',
	'Insert a start height': 'Inserte una altura inicial',
	'Insert an end height': 'Inserte una altura final',
	'Insert a start distance': 'Inserte una distancia inicial',
	'Insert an end distance': 'Inserte una distancia final',
	'Insert an object weight': 'Inserte un peso de objeto',
	'Insert a frequency value': 'Inserte un valor de frecuencia',
	'Select a frequency interval': 'Seleccione un intervalo de frecuencia',
	'Insert an initial force': 'Inserte una fuerza inicial',
	'Insert a sustained force': 'Inserte una fuerza sostenida',
	Initial: 'Inicial',
	Sustain: 'Sostenida',
	'Insert an horizontal distance': 'Inserte una distancia horizontal',
	'Insert a vertical hand height': 'Inserte una altura vertical de la mano',
	'Select a task': 'Seleccione una tarea',
	'per hour': 'por hora',
	hour: 'hora',
	'per minute': 'por minuto',
	per: 'por',
	'Video duration': 'Duración del video',
	'Assessment date': 'Fecha de evaluación',
	'Activity is safe for': 'Actividad es segura para',
	'Initial state': 'Estado inicial',
	'End state': 'Estado final',
	Displacement: 'Desplazamiento',
	Metric: 'Métrico',
	Imperial: 'Imperial',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'Los datos ingresados para la altura inicial de la mano y la altura final de la mano indican que el tipo de tarea es Levantar, no Bajar',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'Los datos ingresados para la altura inicial de la mano y la altura final de la mano indican que el tipo de tarea es Bajar, no Levantar',

	// API error messages
	'Internal Server Error': 'Error interno del servidor',
	'You are unauthorized to access the requested resource': 'No está autorizado a acceder al recurso solicitado',
	'Check the header parameters entered': 'Compruebe los parámetros de cabecera introducidos',
	'Range risk not found': 'Rango de riesgo no encontrado',
	'Failed to fetch risk tracks': 'No se han podido recuperar las pistas de riesgo',
	'Task not found': 'Tarea no encontrada',
	'Task already exists': 'La tarea ya existe',
	'Cycle not found': 'Ciclo no encontrado',
	'Cycle already exists': 'El ciclo ya existe',
	'Stress level not found': 'Nivel de estrés no encontrado',
	'Frequency not found': 'Frecuencia no encontrada',
	'Total task duration not found': 'Duración total de la tarea no encontrada',
	'Evaluator not found': 'Evaluador no encontrado',
	'Exposure not found': 'Exposición no encontrada',
	'Vulnerability not found': 'Vulnerabilidad no encontrada',
	'Severity not found': 'Severidad no encontrada',
	'Severity already set to this risk damage': 'Severidad ya establecida para este daño',
	'Custom report step key not found': 'No se ha encontrado la clave del paso del informe personalizado',
	'Custom report step not found': 'Paso de informe personalizado no encontrado',
	'Custom report not found': 'Informe personalizado no encontrado',
	'Bera step key result not found': 'Bera paso clave resultado no encontrado',
	'Bera step key result already exists': 'Bera paso clave resultado ya existe',
	'Bera weighted average not found': 'No se ha encontrado la media ponderada de Bera',
	'Bera weighted average already exists': 'La media ponderada de Bera ya existe',
	'Bera report not found': 'Informe Bera no encontrado',
	'Bera report already exists': 'El informe de Bera ya existe',
	'Bera job summary not found': 'Bera job summary no encontrado',
	'Bera job summary result not found': 'No se ha encontrado el resultado del resumen de empleo de Bera',
	'Bera job summary already exists': 'Ya existe el resumen de empleo de Bera',
	'Sera report not found': 'Informe Sera no encontrado',
	'Sera report already exists': 'El informe Sera ya existe',
	'Sera summary not found': 'No se ha encontrado el resumen de Sera',
	'Sera summary already exists': 'El resumen de Sera ya existe',
	'Sera summary review not found': 'No se ha encontrado el resumen de revisión de Sera',
	'Sera summary review already exists': 'Sera summary review ya existe',
	'Sector not found': 'Sector no encontrado',
	'Report data not found': 'No se han encontrado los datos del informe',
	'File not found': 'Archivo no encontrado',
	'Failed to read file': 'Error al leer el archivo',
	'Failed to save file': 'Error al guardar archivo',
	'Master user not found': 'Usuario maestro no encontrado',
	'Potential customer not found': 'Cliente potencial no encontrado',
	'Voucher not found': 'Vale no encontrado',
	'Invalid voucher': 'Vale no válido',
	'Invalid recurrence': 'Recurrencia no válida',
	'Plan not found': 'Plan no encontrado',
	'Plan already canceled': 'Plan ya cancelado',
	'Invalid subscription type': 'Tipo de suscripción no válido',
	'It is not possible to downgrade the annual plan': 'No es posible bajar de categoría el plan anual',
	'Failed to decode payload': 'Error al descodificar la carga útil',
	'Plan expiration date not found': 'Fecha de caducidad del plan no encontrada',
	'Document already registered': 'Documento ya registrado',
	'Failed to create user plan': 'Error al crear plan de usuario',
	'Maximum registered users': 'Número máximo de usuarios registrados',
	'Failed to create user': 'Error al crear usuario',
	'User already registered': 'Usuario ya registrado',
	'Organization not found': 'Empresa no encontrada',
	'Company not found': 'Planta industriale no encontrada',
	'Failed to create company': 'Error al crear empresa',
	'Failed to create a user permission': 'Error al crear un permiso de usuario',
	'Failed to send email': 'Error al enviar correo electrónico',
	'Failed to create storage bucket': 'Error al crear un bucket de almacenamiento',
	'Failed to upload file to storage': 'Error al cargar archivo en el almacenamiento',
	'Failed to download data': 'Error al descargar datos',
	'Failed to delete file': 'Fallo al eliminar archivo',
	'Fail get information storage': 'Fallo al obtener información de almacenamiento',
	'Failed to store data': 'Fallo al almacenar datos',
	'Reply queue communication failure': 'Fallo en la comunicación de la cola de respuesta',
	'Selected report not found': 'Informe seleccionado no encontradoencontrado',
	'Unauthorized requisition': 'Solicitud no autorizada',
	'Authorization header is missing': 'Falta la cabecera de autorización',
	'Failed to query the database': 'Error al consultar la base de datos',
	'Calculation per second not found': 'Cálculo por segundo no encontrado',
	'Business information not found': 'Información comercial no encontrada',
	'Worker self evaluation not found': 'No se ha encontrado la autoevaluación del trabajador',
	'Action plan not found': 'No se ha encontrado el plan de acción',
	'Failed to create an history': 'Error al crear un historial',
	'Already created action plan': 'Plan de acción ya creado',
	'Action plan task not found': 'No se ha encontrado la tarea del plan de acción',
	'Action plan history not found': 'No se ha encontrado el historial del plan de acción',
	'Preliminary analysis not found': 'Análisis preliminar no encontrado',
	'Default step not found': 'No se ha encontrado el paso por defecto',
	'Analysis step not found': 'No se ha encontrado el paso de análisis',
	'Probabilty analysis not found': 'Análisis de probabilidad no encontrado',
	'Consequence analysis not found': 'Análisis de consecuencias no encontrado',
	'Exhibition analysis not found': 'Análisis de exposición no encontrado',
	'Organization still has registered companies': 'La organización aún tiene empresas registradas',
	'Failed to create an organization': 'Error al crear una organización',
	'Workstation not found': 'Puesto de trabajo no encontrada',
	'Custom report result not found': 'Resultado de informe personalizado no encontrado',
	'Already created custom report result': 'Resultado de informe personalizado ya creado',
	'Custom report step key result not found': 'Resultado de clave de paso de informe personalizado no encontrado',
	'Already created custom report step key result': 'Resultado de clave de paso de informe personalizado ya creado',
	'Failed to create custom report step key result':
		'Error al crear el resultado de la clave de paso de informe personalizado',
	'Failed to update custom report step key result':
		'Error al actualizar el resultado de la clave de paso de informe personalizado',
	'Custom report step key additional item result not found':
		'Resultado de elemento adicional de clave de paso de informe personalizado no encontrado',
	'Failed to create custom report step key additional item result':
		'Error al crear el resultado de elemento adicional de clave de paso de informe personalizado',
	'Failed to update custom report step key additional item result':
		'Error al actualizar el resultado de elemento adicional de clave de paso de informe personalizado',
	'Work condition result not found': 'Resultado de condición de trabajo no encontrado',
	'Already created work condition result': 'Resultado de condición de trabajo ya creado',
	'Characteristic result not found': 'Resultado de característica no encontrado',
	'Already created characteristic result': 'Resultado de característica ya creado',
	'Custom report sub step key not found': 'Subclave de paso de informe personalizado no encontrada',
	'Corrupted video': 'Vídeo dañado',
	'Failed to read sent file': 'Error al leer el archivo enviado',
	'Failed to create download url': 'Error al crear URL de descarga',
	'File already queued': 'Archivo ya en cola',
	'File already processed': 'Archivo ya procesado',
	'Already registered user': 'Usuario ya registrado',
	'Failed to create customer plan': 'Error al crear el plan del cliente',
	'Failed to create customer transaction': 'Error al crear la transacción del cliente',
	'Customer transaction not found': 'Transacción del cliente no encontrada',
	'Customer transaction already completed': 'Transacción del cliente ya completada',
	'Product not found': 'Producto no encontrado',
	'Failed to create customer credits': 'Error al crear los créditos del cliente',
	'Processed minutes limit reached': 'Límite de minutos procesados alcanzado',
	'Invalid event': 'Evento no válido',
	'Not authorized': 'No autorizado',
	'Customer not found': 'Cliente no encontrado',
	'Unregistered customer': 'Cliente no registrado',
	'Already registered customer': 'Cliente ya registrado',
	'Failed to create customer': 'Error al crear el cliente',
	'Failed to update customer': 'Error al actualizar el cliente',
	'Report not found': 'Informe no encontrado',
	'Already created report': 'Informe ya creado',
	'Failed to create report': 'Error al crear el informe',
	'Failed to create report pdf': 'Error al crear el PDF del informe',
	'Failed to update report': 'Error al actualizar el informe',
	'Custom report additional item option not found':
		'Opción de elemento adicional de informe personalizado no encontrada',
	'Error calculating custom report additional item result':
		'Error al calcular el resultado de elemento adicional de informe personalizado',
	'Line not found': 'Línea no encontrada',
	'Failed to create file signature': 'Error al crear la firma del archivo',

	// Force Two-Factor
	'For security reasons, we need you to enable your two-factor validation':
		'Por razones de seguridad, necesitamos que habilites tu validación de dos factores',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'Su empresa solicita que todos los usuarios tengan habilitada la autenticación de dos factores. Procede a activar el tuyo e inicie sesión.',
	Return: 'Volver',

	// Integrations
	'Continue with Microsoft SSO': 'Continuar con Microsoft SSO',
	Integrations: 'Integrações',
	'Built-in user groups': 'Grupos de usuarios integrados',
	'Connect your Microsoft Azure AD users': 'Conecte a sus usuarios de Microsoft Azure AD',
	'Invitation sent': 'Invitación enviada',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - Usuarios',
	'Failed to load user groups': 'No se pudieron cargar los grupos de usuarios',
	'User removed successfully!': '¡El usuario se eliminó correctamente!',
	'Failed to remove user': 'No se pudo eliminar la usuario',
	'Do you want to remove the user': '¿Quieres eliminar a la usuaria?',
	'Failed to update user': 'Error al actualizar el usuario',
	'Failed to load information': 'No se pudo cargar la información',
	'Investigate and implement change': 'Investigar y aplicar cambios',
	'Implement changes': 'Implementar mudanças',
	Attention: 'Atención',

	'To Do': 'Pendientes',
	'TO DO': 'PENDIENTES',
	Doing: 'En curso',
	DOING: 'EN CURSO',
	Done: 'Terminadas',
	DONE: 'TERMINADAS',
	Carriage: 'Carro',
	Barrow: 'Carretilla de mano',
	Conveyor: 'Cinta transportadora',
	Crane: 'Grúa',

	//EWA-JDS-D86
	'Ergonomic workplace analysis': 'Análisis ergonómico preliminar',
	'Select the right model for your analysis': 'Seleccione el modelo adecuado para su análisis',
	'Brazilian standard': 'Norma brasileña',
	'Brazilian standard and John Deere guidelines': 'Norma brasileña y directrices de John Deere',
	Unit: 'Unidad',
	'Evaluator name': 'Revisor',
	Interviewer: 'Entrevistador',
	'Interviewee name': 'Entrevistado',
	'Select the restrictions perceived in the evaluated working condition':
		'Seleccione las restricciones percibidas en las condiciones de trabajo evaluadas',
	'Summary description of the site': 'Descripción resumida del emplazamiento',
	'Simplified description of the planned task': 'Descripción simplificada de la tarea prevista',
	'Particularities / Production / Working regime': 'Particularidades / Producción / Régimen laboral',
	'Select the Work schedule hours': 'Seleccione su horario de trabajo',
	'Select the Sector': 'Seleccione el sector',
	'Select the Unit': 'Seleccione la unidad',
	'Select the Line': 'Seleccione la línea',
	'Select the Collection date': 'Seleccione la fecha de recogida',
	'Select the Evaluator name': 'Seleccione el nombre del evaluador',
	'Workday and concentration': 'Jornada y concentración',
	'Risk Index:': 'Índice de Riesgo:',
	'Check the tool for conducting risk assessments': 'Compruebe la herramienta para realizar evaluaciones de riesgos',
	'Wrench length': 'Longitud de la llave (mm)',
	'Sum of RPNs': 'Suma de RPNs',
	'Higher RPNs': 'RPNs más altos',
	'Found at:': 'Encontrado en:',
	'Interviewer name': 'Nombre del entrevistador',
	'The movement is considered safe for': 'El movimiento se considera seguro para',
	'of man': 'de hombres',
	'of woman': 'de mujeres',
	Movement: 'Movimiento',
	'Movement by score (Kinebot/JDS)': 'Movimiento por puntaje (Kinebot/JDS)',
	'Tool type': 'Tipo de herramienta',
	'Movement type': 'Tipo de movimiento',
	Dimension: 'Dimensión',
	'Range zone': 'Zona de alcance',
	'Command range': 'Alcance de los comandos',
	'Target condition': 'Condición objetivo',
	'Conditionally acceptable': 'Condicionalmente aceptable',
	'Actions must be implemented': 'Las acciones deben implementarse',
	'Impact frequency': 'Frecuencia de impacto',
	'Evaluation necessary': 'Evaluación necesaria',
	'Evaluation not necessary': 'Evaluación no necesaria',
	'Select the items you want to present in your document':
		'Seleccione los elementos que desea presentar en su documento',
	'Criteria not evaluated': 'Criterios no evaluados',
	'Generate document': 'Generar documento',
	'Analysis not consolidated!': '¡Análisis no consolidado!',
	'If you continue, the filled-in data will be saved but not taken into account in your dashboard':
		'Si continúa, los datos completados se guardarán pero no se tendrán en cuenta en su panel de control',
	Proceed: 'Continuar',
	'Material Handling (Liberty Mutual)': 'Manipulación de material (Liberty Mutual)',
	Tools: 'Herramientas',
	Workday: 'Jornada laboral',
	'Influence of workspace': 'Influencia del espacio de trabajo',
	'Influence of environment': 'Influencia del entorno',
	"The risk assessment recommendation is based on the indicators in the JDS-92 guidelines. The color pattern follows the same pattern present in the document's tables.":
		'La recomendación de evaluación del riesgo se basa en los indicadores de las directrices JDS-92. El patrón de colores sigue el mismo patrón que las tablas del documento.',
	"The risk assessment recommendation is based on the indicators in the JDS-86 guidelines. The color pattern follows the same pattern present in the document's tables.":
		'La recomendación de evaluación del riesgo se basa en los indicadores de las directrices JDS-86. El patrón de colores sigue el mismo patrón que las tablas del documento.',
	'Not evaluated': 'No evaluado',
	'Use of one-handed manual wrench': 'Uso de llave manual con una sola mano',
	'Use of one-handed manual wrench with backup wrench': 'Uso de una llave manual de una mano con llave de apoyo',
	'Use of two-handed manual wrench': 'Uso de una llave manual de dos manos',
	'Using power tools': 'Uso de herramientas eléctricas',
	'Performs work that requires frequent finger, hand or arm movements':
		'Realiza trabajos que requieren frecuentes movimientos de los dedos, la mano o el brazo',
	'Using an impact device': 'Utiliza un dispositivo de impacto',
	'Viewing area': 'Área de visión',
	'Postural demands (JDS-D86)': 'Exigencias posturales (JDS-D86)',
	'Workstation involves seated work (fixed height)': 'Puesto de trabajo en el que se trabaja sentado (altura fija)',
	'Workstation involves seated work (adjustable station)':
		'Puesto de trabajo que implique trabajar sentado (altura regulable)',
	'Distances from controls or work surface are inadequate':
		'Las distancias a los mandos o a la superficie de trabajo son inadecuadas',
	'Workspace dimensions are too narrow': 'Las dimensiones del espacio de trabajo son demasiado estrechas',
	'The dimensions and clearances of the controls or work surface with the hands are inadequate':
		'Las dimensiones y distancias de los mandos o de la superficie de trabajo con las manos son inadecuadas',
	'Grip and hand strength': 'Fuerza de agarre y mano',
	'Standing arm strength forces': 'Fuerza de brazos de pie',
	'Hand-arm vibration exposure for 8 hours': 'Exposición durante 8 horas a vibraciones mano-brazo',
	'Hand-arm vibration exposure for': 'Exposición a vibraciones mano-brazo',
	'Contact with very hot or very cold surfaces': 'Contacto con superficies muy calientes o muy frías',
	'Exposure to hot or cold surfaces': 'Exposición a superficies calientes o frías',
	'Hammer Use (<.25 kg, <.5 lb)': 'Uso de martillo (<,25 kg, <,5 lb)',
	'Heavy mallet or hammer use (>1 kg, >2.2 lb)': 'Uso de mazo o martillo pesado (>1 kg, >2,2 lb)',
	'Using hand for striking part': 'Uso de la mano para golpear la pieza',
	'Visual tasks >=10º above the horizontal line of the eye':
		'Tareas visuales ≥10º por encima de la línea horizontal del ojo',
	'Visual tasks 0º to 10º above the horizontal line of the eye':
		'Tareas visuales de 0º a 10º por encima de la línea horizontal del ojo',
	'Visual tasks 0º to 45º below the horizontal line of the eye':
		'Tareas visuales de 0º a 45º por debajo de la línea horizontal del ojo".',
	'Visual tasks 45º to 60º below the horizontal line of the eye':
		'Tareas visuales 45º a 60º por debajo de la línea horizontal del ojo',
	'Visual tasks >=60º below the horizontal line of the eye':
		'Tareas visuales ≥60º por debajo de la línea horizontal del ojo".',
	'Workstation height': 'Altura del puesto de trabajo',
	'Precision task height': 'Altura de las tareas de precisión',
	'Writing and light assembly task height': 'Altura de las tareas de escritura y montaje ligero',
	'Horizontal knee (depth) clearance': 'Distancia horizontal (profundidad) de las rodillas',
	'Horizontal knee width clearance': 'Distancia horizontal a la anchura de las rodillas',
	Horizontally: 'Horizontalmente',
	'Vertically for standing workstations': 'Verticalmente para puestos de trabajo de pie',
	'Vertically for seated workstations': 'Verticalmente para puestos de trabajo sentados',
	'Whole Body': 'Todo el cuerpo',
	'Head (least favorable position)': 'Cabeza (posición menos favorable)',
	Leg: 'Pierna',
	Foot: 'Pie',
	Arm: 'Brazo',
	'Hand, Wrist, Fist': 'Mano, muñeca, puño',
	Finger: 'Dedo',
	'One finger': 'Un dedo',
	'Two finger': 'Dos dedos',
	'Empty hand, flat': 'Mano vacía, plana',
	'Empty hand, thumb tucked': 'Mano vacía, pulgar metido',
	'Clenched hand, fist': 'Mano cerrada, puño',
	'Hand plus 25 mm (1 in) object': 'Mano más 25 mm (1 pulg.) de objeto".',
	'Hand plus X mm (in) object to wrist': 'Mano más X mm de objeto hasta la muñeca',
	'Arm to elbow': 'Brazo hasta el codo',
	'Arm to Shoulder': 'Brazo hasta el hombro',
	'Both Arms to Shoulder': 'Ambos brazos hasta el hombro',
	'Power grip force': 'Fuerza de agarre',
	'Power grip press': 'Fuerza de agarre',
	'Pinch grip': 'Agarre de pellizco',
	'Lateral pinch (key grip)': 'Agarre lateral (agarre de llave)',
	'Tip pinch grip': 'Agarre de punta',
	'Hook grip': 'Agarre de gancho',
	'Push with index finger': 'Empuje con el dedo índice',
	'Push with thumb': 'Empuje con el pulgar',
	'Wrist flexion or extension torque': 'Torsión de flexión o extensión de la muñeca',
	'Ulnar/Radial deviation': 'Desviación cubital/radial',
	Screwdriving: 'Atornillado',
	'Push-Pull 1 hand (knee to shoulder)': 'Empuje-Tire 1 mano (de rodilla a hombro)',
	'Push-Pull 1 hand (below knee or above shoulder)':
		'Empuje-Tire 1 mano (por debajo de la rodilla o por encima del hombro)',
	'Up-Down 1 hand (knee to shoulder)': 'Arriba-Abajo 1 mano (rodilla-hombro)',
	'Up-Down 1 hand (below knee or above shoulder)':
		'Arriba-Abajo 1 mano (por debajo de la rodilla o por encima del hombro)',
	'Push-Pull 2 hands (knee to shoulder)': 'Empuje-Tire 2 manos (rodilla-hombro)',
	'Push-Pull 2 hands (below knee or above shoulder)':
		'Empuje-Tire 2 manos (por debajo de la rodilla o por encima del hombro)',
	'Up-Down 2 hands (knee to shoulder)': 'Arriba-Abajo 2 manos (rodilla a hombro)',
	'Up-Down 2 hands (below knee or above shoulder)':
		'Up-Down 2 manos (por debajo de la rodilla o por encima del hombro)',
	'Lateral force 2 hands (between knee and shoulder)': 'Fuerza lateral 2 manos (entre rodilla y hombro)',
	'Lateral force 2 hands (above shoulder or below knee) ':
		'Fuerza lateral 2 manos (por encima del hombro o por debajo de la rodilla)',
	'Vertical movement': 'Movimiento vertical',
	'Lateral and longitudinal movement': 'Movimiento lateral y longitudinal',
	'Hand-arm Vibration A(eq) for 8-hr exposure': 'Vibración mano-brazo A(eq) para una exposición de 8 horas".',
	'Without gloves': 'Sin guantes',
	'Assembly gloves': 'Guantes de montaje',
	'Welding gloves': 'Guantes de soldadura',
	'Viewing area in relation to the horizontal line at eye level':
		'Área de visión en relación con la línea horizontal a la altura de los ojos',
	Height: 'Altura',
	Width: 'Anchura',
	Diameter: 'Diámetro',
	'Object dimension': 'Dimensión del objeto',
	'Reach zone': 'Zona de alcance',
	'Body part': 'Parte del cuerpo',
	'Minimum gap': 'Hueco mínimo',
	Criterion: 'Criterio',
	"Some required step wasn't filled": 'No se cumplimentó algún paso requerido',
	Unlikely: 'Poco probable',
	Remote: 'Remoto',
	Occasional: 'Ocasional',
	Likely: 'Probable',
	Frequent: 'Frecuente',
	Certainly: 'Seguro',
	'Muscle fatigue/discomfort or near-miss accident': 'Fatiga muscular/Desconfort ou o casi accidente',
	'No absence': 'Ninguna ausencia',
	'No absence with surgery or restriction': 'Ninguna ausencia con cirugía o restricción',
	'Absence - loss of work capacity and/or hospitalization':
		'Ausencia - pérdida de capacidad laboral y/o hospitalización',
	'>20 strikes per hour': '>20 golpes por hora',
	'<20 strikes per hour': '<20 golpes por hora',
	'>40 strikes per hour': '>40 golpes por hora',
	'1 strike per minute': '1 golpe por minuto',
	'<10 strikes per hour': '<10 golpes por hora',
	'2 strikes per minute': '2 golpes por minuto',
	'< 2 light taps for alignment': '< 2 golpes ligeros para alineación',
	'The action of pulling/pushing loads or volumes with great effort':
		'La acción de tirar/empujar cargas o volúmenes con gran esfuerzo',
	'Vision-guided tasks outside the primary viewing area (0° to 45°)':
		'Tareas guiadas por la visión fuera del área de visión primaria (0° a 45°)',
	'Standing for long periods': 'Estar de pie durante largos periodos',
	'Sitting for long periods (fixed height)': 'Sentado durante largos periodos (altura fija)',
	'Sitting for long periods (adjustable station)': 'Sentado durante largos periodos (puesto regulable)',
	'Exposure to whole-body vibration': 'Exposición a vibraciones de todo el cuerpo',
	'Musculoskeletal strain on wrists and elbows': 'Tensión musculoesquelética en muñecas y codos',
	'Development of musculoskeletal disorders and/or eye fatigue':
		'Desarrollo de trastornos musculoesqueléticos y/o fatiga ocular',

	// JDS D86
	'Ergonomic evaluation': 'Evaluación ergonómica',
	'Frequent motions': 'Movimientos frecuentes',
	'Excessive reaches': 'Alcances excesivos',
	'Lifting of heavy components and tools': 'Elevación de componentes pesados y herramientas',
	'Handling of bulky parts': 'Manipulación de partes voluminosas',
	'Heavy exertions to install parts': 'Esfuerzos pesados para instalar partes',
	'High forces to pull or push carts, baskets and containers':
		'Altas fuerzas para arrastrar o empujar carros, cestos y contenedores',
	'Contact stress': 'Estrés de contacto',
	'Sharp edges are present': 'Hay bordes afilados',
	'The noise level is very high': 'El nivel de ruido es muy alto',
	'Poor access or inadequate clearances': 'Acceso deficiente o huecos insuficientes',
	'Awkward or static postures': 'Posturas incómodas o estáticas',
	'Staying in a static posture for a long time': 'Mantenerse en una postura estática durante mucho tiempo',
	'Video selection': 'Selección de video',
	'Search for a file': 'Buscar un fichero',
	Revaluations: 'Revaluaciones',
	'Last revaluation': 'Última revaluación',
	'New revaluation': 'Nueva revaluación',
	Revaluation: 'Revaluación',
	'General History': 'Historial general',
	Crouching: 'Agachado',
	Kneeling: 'Ajoelhado',
	Standing: 'En pie',
	'Time per episode': 'Tiempo por episodio',
	'Frequency per hour': 'Frecuencia por hora',
	'Total time (in one day)': 'Tiempo total (en un día)',
	'Are you sure you want to delete the last revaluation': '¿Está seguro de que desea eliminar la última revaluación?',
	'Are you sure you want to delete the review': '¿Está seguro de que desea eliminar la revisión?',
	'Delete report': 'Eliminar informe',
	'Delete the last revaluation': 'Eliminar la última revaluación',
	'JDS D86 last revaluation deleted successfully': 'JDS D86 última revaluación eliminada correctamente',
	'Delete review': 'Eliminar revisión',
	'You can delete the entire report or just the last revaluation':
		'Puede eliminar todo el informe o solo la última revaluación',
	'JDS D86 deleted successfully': 'JDS D86 eliminado correctamente',
	'Select the video file below for the task you want to analyze':
		'Seleccione el archivo de video correspondiente a la tarea que desea analizar',
	Evaluation: 'Evaluación',
	'Generating a document': 'Generando un documento',
	'The created document is being generated and will be downloaded':
		'El documento creado se está generando y será descargado',
	'Noise level': 'Nivel de ruido',
	'Select the Report name': 'Seleccione el nombre del informe',

	//D92
	'Lifting tasks': 'Levantamiento',
	'Push, pull and carry': 'Empujar, arrastrar y cargar ',
	'Handles work objects above the shoulder or below the knee':
		'Realiza manejo de objetos de trabajo por encima del hombro o por debajo de la rodilla.',
	'Shoulder moment calculator': 'Calculadora de momento para el hombro',
	'Manual lifting (NIOSH)': 'Levantamiento de cargas (NIOSH)',
	'Manual handling (KIM MHO)': 'Manejo manual (KIM MHO)',
	'Lever Arm': 'Brazo de palanca',
	'Type of Task': 'Tipo de tarea',
	'Material handling (Liberty Mutual)': 'Manipulación de material (Liberty Mutual)',
	'Use of manually operated carts': 'Uso de carrinhos movimentados manualmente',
	'Is the manual force to lift the car out of inertia less than 22kg?':
		'¿La fuerza manual para sacar el carro de la inercia es menor de 22 kg?',
	'Is the sustained hand force less than 11.5kg?': '¿La fuerza manual sostenida es menor de 11,5 kg?',
	'Is the diameter to fit the fingers larger than 30mm and smaller than 50mm?':
		'¿El diámetro para encajar los dedos es mayor de 30 mm y menor de 50 mm?',
	'Is the vertical handle between 900mm and 1225mm?': '¿El asa vertical está entre 900 mm y 1225 mm?',
	'Is the width from one handle to the other larger than 400mm and smaller than 600mm?':
		'¿El ancho de un agarre al otro es mayor de 400 mm y menor de 600 mm?',
	'Is the caster in good condition?': '¿La rueda giratoria está en buenas condiciones?',
	'Is the road in good condition?': '¿El recorrido realizado está en buenas condiciones?',
	'Is the layout in good condition for moving the car, with enough space for maneuvering?':
		'¿El diseño está en buenas condiciones para la movilización del carro con suficiente espacio para maniobras?',
	'Is the floor in good condition?': '¿El piso está en buenas condiciones?',
	'Handling load': 'Manipulación de carga',
	'Horizontal push or pull': 'Empujar o arrastrar horizontal',
	'Push or pull downward': 'Empujar o arrastrar hacia abajo',
	'Distance from the acromion to the center of the load (greatest distance)':
		'Distancia desde el acromion hasta el centro de carga (mayor distancia)',
	'Repetitions in a working day': 'Repeticiones en un día de trabajo',
	'Apply when moving a part with a mass greater than 4.5 kg with one hand':
		'Aplicar cuando hay movimiento de una pieza con una masa mayor a 4,5 kg con una mano.',
	'Apply when moving a piece with a mass greater than 9kg with two hands in up to two steps':
		'Aplicar cuando hay movimiento de una pieza con una masa mayor a 9 kg con dos manos en hasta dos pasos.',
	'Apply when there is movement in a critical scenario (e.g. bottom of a container)':
		'Aplicar cuando hay movimiento en un escenario crítico (por ejemplo, fondo de un contenedor).',
	'per work day': 'por dia',
	'Probability of Shoulder Outcome': 'Probabilidad de consecuencia para el hombro',
	'The task should serve 75% of the female population': 'A tarefa deve ser adequada para 75% da população feminina.',

	//  Back compressive force estimation
	'Back compressive force estimation': 'Estimación de compresión lumbar',
	'Person weight': 'Peso de la persona',
	"Person's weight": 'Peso de la persona',
	'Insert the person weight': 'Inserte el peso de la persona',
	"Person's height": 'Altura de la persona',
	'Person height': 'Altura de la persona',
	'Insert the person height': 'Inserte la altura de la persona',
	'Trunk angle to Horizontal': 'Ángulo del tronco respecto a horizontal',
	'Trunk angle to horizontal': 'Ángulo del tronco respecto a horizontal',
	'Insert a trunk angle': 'Inserte el Ángulo del tronco',
	'Load in hands': 'Carga que se está manipulando ',
	'Insert the load in hands': 'Inserte la carga que se está manipulando',
	'Horizontal distance from hands to low back': 'Distancia horizontal de las manos a la región lumbar',
	'Insert a horizontal distance': 'Insert la d istancia horizontal de las manos a la región lumbar',
	'Total compressive force': 'Fuerza de compresión total',
	'Back compressive force estimation report updated successfully':
		'Informe de estimación de la fuerza de compresión trasera actualizado con éxito',
	'Creation date': 'Fecha de Cración',
	"Enter the operator's activity-related data below": 'Inserta abajo los datos relacionados al vídeo seleccionado',
	Dimensions: 'Dimensiones',
	'Condition found': 'Condición encontrada',
	'Select the video files for the operations that make up the workstation below':
		'Seleccione a continuación el archivo de vídeo referente a las tareas que desea analizar.',
	'Are you sure you want to delete the evaluation': '¿Está seguro de que desea eliminar a evaluación',
	'Document generated!': 'Documento generado!',
	'Activity created successfully': 'Actividad creada con éxito',
	'Select EWA': 'Selección de AEP',
	'Unable to load data': 'No se pueden cargar los datos',

	// New Dashboard
	'No data available.': 'No hay datos.',
	'This page was not found.': 'No se ha encontrado esta página.',
	'Try Again': 'Intentar de Nuevo',
	'Start date must be before end date': 'Fecha inicial debe ser antes de la final',
	'End date must be after start date': 'Fecha final debe ser después de la inicial'
};

export default dictionary;
