import React from 'react';
import { Moment } from 'moment';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { StyledDatePicker } from '../styles';

interface EndDateProps {
	formName: string[];
	disabled: boolean;
}

export function EndDate({ formName, disabled }: Readonly<EndDateProps>) {
	return (
		<Row>
			<Col span={24}>
				<Form.Item
					style={{ margin: 0 }}
					labelCol={{ span: 24 }}
					label={I18n.get('End date')}
					name={[...formName, 'end_date']}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value: Moment) {
								const startDate = getFieldValue([...formName, 'start_date']);

								if (value && value.isBefore(startDate)) {
									return Promise.reject(I18n.get('End date must be after start date'));
								}

								return Promise.resolve();
							}
						})
					]}
				>
					<StyledDatePicker
						disabled={disabled}
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
