import React from 'react';
import { Moment } from 'moment';
import { Col, Form, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import { StyledDatePicker } from '../styles';

interface StartDateProps {
	formName: string[];
	disabled: boolean;
}

export function StartDate({ formName, disabled }: Readonly<StartDateProps>) {
	return (
		<Row>
			<Col span={24}>
				<Form.Item
					style={{ margin: 0 }}
					labelCol={{ span: 24 }}
					label={I18n.get('Start date')}
					name={[...formName, 'start_date']}
					rules={[
						({ getFieldValue }) => ({
							validator(_, value: Moment) {
								const endDate = getFieldValue([...formName, 'end_date']);

								if (value && value.isAfter(endDate)) {
									return Promise.reject(I18n.get('Start date must be before end date'));
								}

								return Promise.resolve();
							}
						})
					]}
				>
					<StyledDatePicker
						disabled={disabled}
						disabledDate={(current) => current && current.valueOf() >= Date.now()}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
}
